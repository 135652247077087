import React, { useEffect } from "react"

import { useLocation } from "react-router-dom"

import Flex from "components/flex"

import AccountDeleted from "./account_deleted"
import CallToAction from "./cta"
import Demo from "./demo"
import Details from "./details"
import UniqueSellingProposition from "./usp"

const BACKGROUND_LAYER_BASE_STYLE = {
  objectFit: "cover",
  width: "100%",
  height: "100%",
  position: "fixed",
  top: 0,
  left: 0,
}

export default function LandingPage() {
  const { search } = useLocation()
  const accountDeleted = new URLSearchParams(search).get("account_deleted")

  useEffect(() => {
    if (accountDeleted) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  })

  return (
    <div className="text-light">
      <Flex column justify="center" style={{ textAlign: "center" }}>
        {accountDeleted && <AccountDeleted />}
        <UniqueSellingProposition />
        <Demo />
        <CallToAction />
        <Details />
        <CallToAction />
      </Flex>
      <video
        src="slow-mo-gym.mp4"
        alt="An empty gym"
        muted
        autoPlay
        style={{ ...BACKGROUND_LAYER_BASE_STYLE, zIndex: -2 }}
      />
      <div
        style={{
          ...BACKGROUND_LAYER_BASE_STYLE,
          background: "rgba(0, 0, 0, 0.8)",
          zIndex: -1,
        }}
      />
    </div>
  )
}
