import React from "react"

import { Link } from "react-router-dom"

import Button from "components/button"

export default function Unauthenticated() {
  return (
    <Button as={Link} to="/login">
      Login
    </Button>
  )
}
