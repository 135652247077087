import React from "react"

import { Route as ReactRouterRoute, Redirect } from "react-router-dom"

import useAuth from "hooks/auth"

export default function Route({
  page,
  login = false,
  gated = false,
  admin = false,
  path,
  ...rest
}) {
  const auth = useAuth()

  if (auth.loggedIn === undefined) {
    return null
  }
  if ((login || path === "/") && auth.loggedIn) {
    return <Redirect to={{ pathname: "/diet" }} />
  }
  if (gated && !auth.loggedIn) {
    return <Redirect to={{ pathname: "/login" }} />
  }
  if (admin && !auth.user.is_admin) {
    return <Redirect to={{ pathname: "/" }} />
  }

  return <ReactRouterRoute {...rest} component={page} />
}
