import React, { useEffect, useState } from "react"

import { useInView } from "react-intersection-observer"

function getInitialClasses({
  enterLeft,
  enterRight,
  enterTop,
  exitTop,
  fadeIn,
  pulse,
  splat,
  stamp,
  wiggle,
}) {
  const classes = ["animation-container"]
  if (enterLeft) {
    classes.push("animate-initial-enter-left")
  }
  if (enterRight) {
    classes.push("animate-initial-enter-right")
  }
  if (enterTop) {
    classes.push("animate-initial-enter-top")
  }
  if (exitTop) {
    classes.push("animate-initial-exit-top")
  }
  if (fadeIn) {
    classes.push("animate-initial-fade-in")
  }
  if (pulse) {
    classes.push("animate-initial-pulse")
  }
  if (splat) {
    classes.push("animate-initial-splat")
  }
  if (stamp) {
    classes.push("animate-initial-stamp")
  }
  if (wiggle) {
    classes.push("animate-initial-wiggle")
  }

  return classes.join(" ")
}

function getAnimationClasses({
  enterLeft,
  enterRight,
  enterTop,
  exitTop,
  fadeIn,
  pulse,
  splat,
  stamp,
  wiggle,
}) {
  const classes = []
  if (enterLeft) {
    classes.push("animate-enter-left")
  }
  if (enterRight) {
    classes.push("animate-enter-right")
  }
  if (enterTop) {
    classes.push("animate-enter-top")
  }
  if (exitTop) {
    classes.push("animate-exit-top")
  }
  if (fadeIn) {
    classes.push("animate-fade-in")
  }
  if (pulse) {
    classes.push("animate-pulse")
  }
  if (splat) {
    classes.push("animate-splat")
  }
  if (stamp) {
    classes.push("animate-stamp")
  }
  if (wiggle) {
    classes.push("animate-wiggle")
  }

  return classes.join(" ")
}

export default function Animate({
  children,
  enterLeft,
  enterRight,
  enterTop,
  exitTop,
  fadeIn,
  pulse,
  splat,
  stamp,
  wiggle,
  style,
  delay = 0,
  duration = 280, // default is average human reaction time
  onceInView = null,
}) {
  const { ref, inView } = useInView(onceInView === null ? { skip: true } : onceInView)

  const initialClasses = getInitialClasses({
    enterLeft,
    enterRight,
    enterTop,
    exitTop,
    fadeIn,
    pulse,
    splat,
    stamp,
    wiggle,
  })
  const animationClasses = getAnimationClasses({
    enterLeft,
    enterRight,
    enterTop,
    exitTop,
    fadeIn,
    pulse,
    splat,
    stamp,
    wiggle,
  })

  const [classes, setClasses] = useState(initialClasses)

  useEffect(() => {
    if (onceInView !== null && !inView) {
      return
    }

    if (delay !== 0) {
      setTimeout(() => setClasses(`${initialClasses} ${animationClasses}`), delay)
    } else {
      setClasses(`${initialClasses} ${animationClasses}`)
    }
  }, [
    animationClasses,
    delay,
    enterLeft,
    enterRight,
    enterTop,
    exitTop,
    fadeIn,
    inView,
    initialClasses,
    onceInView,
    pulse,
    splat,
    stamp,
    wiggle,
  ])

  return (
    <div ref={ref}>
      <div className={classes} style={{ animationDuration: `${duration}ms`, ...style }}>
        {children}
      </div>
    </div>
  )
}
