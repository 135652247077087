import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Animate from "components/animate"
import { dangerColor, successColor } from "index.scss"

function getFailureReason(metrics) {
  if (metrics.calorie_target_failed && metrics.calorie_target_room_to_run < 0) {
    return "TOO MUCH FOOD!"
  }
  if (metrics.calorie_target_failed && metrics.calorie_target_defecit > 0) {
    return "NOT ENOUGH FOOD!"
  }
  if (metrics.carb_target_failed && metrics.carb_target_room_to_run < 0) {
    return "TOO MANY CARBS!"
  }
  if (metrics.carb_target_failed && metrics.carb_target_defecit > 0) {
    return "NOT ENOUGH CARBS!"
  }
  if (metrics.fat_target_failed && metrics.fat_target_room_to_run < 0) {
    return "TOO MUCH FAT!"
  }
  if (metrics.fat_target_failed && metrics.fat_target_defecit > 0) {
    return "NOT ENOUGH FAT!"
  }
  if (metrics.protein_target_failed && metrics.protein_target_room_to_run < 0) {
    return "TOO MUCH PROTEIN!"
  }
  if (metrics.protein_target_failed && metrics.protein_target_defecit > 0) {
    return "NOT ENOUGH PROTEIN!"
  }

  return "MISSED GOALS!"
}

function BaseStamp({ children, style, variant, animationKey }) {
  let variantColor = "black"
  if (variant === "danger") {
    variantColor = dangerColor
  } else if (variant === "success") {
    variantColor = successColor
  }

  return (
    <div
      className={`text-${variant}`}
      style={{
        position: "absolute",
        right: "-3px",
        top: "-14px",
        zIndex: 5,
        ...style,
      }}
    >
      <Animate stamp key={animationKey} duration={100}>
        <div
          className="stamp"
          style={{
            fontSize: "1.5rem",
            borderColor: variantColor,
            transform: "rotate(3deg)",
          }}
        >
          <b>{children}</b>
        </div>
      </Animate>
    </div>
  )
}

export default function Stamp({ metrics, streak, loadingStreak }) {
  if (!metrics.diet_on_target && !metrics.diet_failed) {
    return null
  }

  if (metrics.diet_failed) {
    const reason = getFailureReason(metrics)
    return (
      <BaseStamp animationKey={`diet-failed-${reason}`} variant="danger">
        <b>{reason}</b>
      </BaseStamp>
    )
  }

  if (loadingStreak || !streak) {
    return null
  }

  return (
    <BaseStamp animationKey="diet-succeeded" variant="success">
      <FontAwesomeIcon icon="check-circle" /> {streak} DAY STREAK!
    </BaseStamp>
  )
}
