import React from "react"

import Flex from "components/flex"

import CalculatorButton from "./button"

export default function QuickAdjust({ serving, adjust }) {
  return (
    <React.Fragment>
      <div style={{ marginBottom: "0.5rem" }}>
        <b>Quick Adjust</b>
      </div>
      <Flex gap="0.5rem" wrap style={{ marginBottom: "0.5rem" }}>
        <CalculatorButton onClick={() => adjust("multiply", 0.5)}>
          x0.5
        </CalculatorButton>
        <CalculatorButton serving={serving} onClick={() => adjust("multiply", 1.5)}>
          x1.5
        </CalculatorButton>
        <CalculatorButton onClick={() => adjust("multiply", 2)}>x2</CalculatorButton>
        <CalculatorButton onClick={() => adjust("add", 10)}>+10</CalculatorButton>
        <CalculatorButton onClick={() => adjust("add", 100)}>+100</CalculatorButton>
      </Flex>
    </React.Fragment>
  )
}
