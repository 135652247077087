import React, { useState, useRef, useEffect } from "react"

import * as chrono from "chrono-node"
import BootstrapForm from "react-bootstrap/Form"

import Loading from "components/loading"
import Day from "day"

import { CalendarHint, Highlight, OverlayContainer } from "./overlays"

export default function EditableDate({
  initialValue,
  onCommit: onCommit_,
  loading,
  style,
  editCta = "Click to add",
}) {
  const input = useRef()
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if (input.current && editing) {
      input.current.focus()
    }
  }, [editing, input])

  function onEdit() {
    setEditing(true)
  }

  function onCommit(value) {
    setEditing(false)
    if (value !== initialValue) {
      onCommit_(value)
    }
  }

  if (editing) {
    return (
      <EditDate
        ref={input}
        initialValue={initialValue}
        onCommit={onCommit}
        style={style}
      />
    )
  }

  return (
    <OverlayContainer>
      <CalendarHint />
      <span
        className="form-control editable-inactive editable"
        style={style}
        onClick={onEdit}
        onFocus={onEdit}
        onKeyDown={(e) => e.key === "Enter" && onEdit()}
        tabIndex={0}
        role="textbox"
      >
        <Loading loading={loading} inline>
          <span style={{ visibility: loading ? "hidden" : "inherit" }}>
            {initialValue ? Day.fromISOString(initialValue).pretty() : editCta}
          </span>
        </Loading>
      </span>
    </OverlayContainer>
  )
}

export const EditDate = React.forwardRef(({ initialValue, onCommit, style }, ref) => {
  const [value, setValue] = useState(initialValue || "")
  const results = chrono.parse(value, new Date())

  const initialValueDay = initialValue && Day.fromISOString(initialValue)

  let newDay = initialValueDay
  if (results.length) {
    newDay = Day.fromDate(results[0].start.date())
  }

  return (
    <OverlayContainer>
      <CalendarHint active />
      <Highlight text={value} substring={results[0] && results[0].text} style={style} />
      <BootstrapForm.Control
        className="text-primary editable"
        ref={ref}
        style={style}
        placeholder={initialValueDay ? initialValueDay.pretty() : null}
        onFocus={(e) => e.target.select()}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          // Passing initialValue ensures we leave the editing state without
          // making a change when newDay is falsy.
          onCommit(newDay ? newDay.toISOString() : initialValue)
        }}
        onKeyPress={(e) => e.key === "Enter" && onCommit(newDay.toISOString())}
        value={value}
      />
      <div className="text-primary">
        {![null, undefined].includes(newDay) &&
        ([null, undefined].includes(initialValueDay) ||
          !newDay.isSameDay(initialValueDay))
          ? newDay.pretty()
          : "Text will highlight when a date is recognized"}
      </div>
    </OverlayContainer>
  )
})
