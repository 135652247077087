import React, { useRef, useState } from "react"

import { throttle } from "lodash"
import Dropdown from "react-bootstrap/Dropdown"
import InputGroup from "react-bootstrap/InputGroup"

import { api } from "api"
import Loading from "components/loading"

import Consequence from "./consequence"
import Error from "./error"
import Menu from "./menu"
import getPlaceholder from "./placeholder"

const SEARCH_THROTTLE_MS = 100

async function _search({
  query,
  setLoading,
  setResults,
  abortController,
  setAbortController,
  setError,
}) {
  setLoading(true)

  if (abortController) {
    abortController.abort()
  }

  const newAbortController = new AbortController()
  setAbortController(newAbortController)

  try {
    const res = await api.get(
      "/api/v1/search",
      { q: query },
      { signal: newAbortController.signal }
    )

    setResults(res.data)
    setError(null)
  } catch (error) {
    if (error.name !== "AbortError") {
      setError(error)
    }
  } finally {
    setLoading(false)
  }
}

const search = throttle(_search, SEARCH_THROTTLE_MS)

function FoodSearch({
  defaultMenu: DefaultMenu = () => null,
  searchResult: SearchResult = () => null,
  searchFooter: SearchFooter = () => null,
}) {
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState("")
  const [showMenu, setShowMenu] = useState(false)
  const [results, setResults] = useState([])
  const [error, setError] = useState(null)
  const [abortController, setAbortController] = useState(null)
  const searchRef = useRef()

  function openMenu() {
    setShowMenu(true)
  }

  function closeMenu() {
    setShowMenu(false)
  }

  function onSearch(value) {
    setShowMenu(true)
    setQuery(value)

    search({
      query: value,
      setResults,
      setLoading,
      abortController,
      setAbortController,
      setError,
    })
  }

  function onBlur(event) {
    if (!searchRef.current.contains(event.relatedTarget)) {
      closeMenu()
    }
  }

  function onFocus() {
    openMenu()
  }

  return (
    <div
      ref={searchRef}
      onBlur={onBlur}
      onFocus={onFocus}
      style={{ marginBottom: "1rem", position: "relative" }}
    >
      <InputGroup>
        <input
          className="form-control"
          type="search"
          placeholder={getPlaceholder()}
          onChange={(e) => onSearch(e.target.value)}
          value={query}
        />
        <InputGroup.Text>
          <Error error={error} />
        </InputGroup.Text>
      </InputGroup>
      <Menu show={showMenu}>
        <Loading loading={loading} debounce>
          {query ? (
            <React.Fragment>
              {results.map((result) => (
                <Dropdown.Item as="span" key={result.idx}>
                  <SearchResult
                    result={result}
                    openMenu={openMenu}
                    closeMenu={closeMenu}
                  />
                  <Consequence result={result} />
                </Dropdown.Item>
              ))}
              {results.length ? <Dropdown.Divider /> : null}
              <SearchFooter openMenu={openMenu} closeMenu={closeMenu} />
            </React.Fragment>
          ) : (
            <DefaultMenu openMenu={openMenu} closeMenu={closeMenu} />
          )}
        </Loading>
      </Menu>
    </div>
  )
}

export default FoodSearch
