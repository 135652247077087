import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Collapse from "react-bootstrap/Collapse"

import Button from "components/button"
import Flex from "components/flex"

const _TOGGLE_WIDTH_REM = 2
const _TOGGLE_MARGIN_LEFT_REM = 0.5

const TOGGLE_MARGIN_LEFT = `${_TOGGLE_MARGIN_LEFT_REM}rem`
const TOGGLE_WIDTH = `${_TOGGLE_WIDTH_REM}rem`
const TOGGLE_TOTAL_WIDTH = `${_TOGGLE_MARGIN_LEFT_REM + _TOGGLE_WIDTH_REM}rem`

function ExpandHeader({ children }) {
  return <div>{children}</div>
}

function ExpandBody({ children }) {
  return <div style={{ marginTop: "0.25rem" }}>{children}</div>
}

function getExpandChildren(children) {
  const headers = []
  const bodies = []

  React.Children.forEach(children, (child) => {
    if ((child.type || {}).name === ExpandHeader.name) {
      headers.push(child)
    } else if ((child.type || {}).name === ExpandBody.name) {
      bodies.push(child)
    } else {
      throw new Error("Children of Expand must be either Expand.Header or Expand.Body")
    }
  })

  if (bodies.length !== 1) {
    throw new Error(
      `Expand requires exactly one Expand.Body as a child, got ${bodies.length} ` +
        `instead`
    )
  }

  if (headers.length !== 1) {
    throw new Error(
      `Expand requires exactly one Expand.Header as a child, got ${headers.length} ` +
        `instead`
    )
  }

  return { body: bodies[0], header: headers[0] }
}

function ExpandToggle({ id, expanded, toggle, disabled }) {
  return (
    <Button
      variant="light"
      disabled={disabled}
      aria-expanded={!disabled && expanded ? "true" : "false"}
      aria-controls={id}
      style={{
        width: TOGGLE_WIDTH,
        textAlign: "center",
        marginLeft: TOGGLE_MARGIN_LEFT,
        marginRight: 0,
        paddingLeft: 0,
        paddingRight: 0,
      }}
      onClick={(e) => {
        e.stopPropagation() // don't close menus when toggling expand
        toggle()
      }}
    >
      <FontAwesomeIcon
        icon="caret-left"
        style={{
          transition: "transform 0.1s",
          ...(expanded ? { transform: "rotate(-90deg)" } : {}),
          ...(disabled ? { color: "rgba(0, 0, 0, 0.2)" } : {}),
        }}
      />
    </Button>
  )
}

function Expand({ id, children, hasExtraContent }) {
  const [expanded, setExpanded] = useState(false)
  const toggle = () => setExpanded(!expanded)

  const { header, body } = getExpandChildren(children)

  return (
    <div id={id}>
      <Flex align="baseline" justify="space-between">
        <Flex.Child grow={1}>{header}</Flex.Child>
        <ExpandToggle
          id={id}
          expanded={expanded}
          toggle={toggle}
          disabled={!hasExtraContent}
        />
      </Flex>
      <Collapse in={expanded}>
        {/* Redundant div is here for animation smoothing */}
        <div>
          <div style={{ marginRight: TOGGLE_TOTAL_WIDTH }}>{body}</div>
        </div>
      </Collapse>
    </div>
  )
}

Expand.Header = ExpandHeader
Expand.Body = ExpandBody

export default Expand
