import React from "react"

import format from "format"

export default function NutritionHint({ nutrition }) {
  let precision = 0
  if (nutrition.calories < 250) {
    precision = 1
  }

  return (
    <React.Fragment>
      Calories <b>{format.number(nutrition.calories)}</b>, Fat{" "}
      <b>{format.macronutrientMass(nutrition.fat, { precision })}</b>, Carbs{" "}
      <b>{format.macronutrientMass(nutrition.carbs, { precision })}</b>, Protein{" "}
      <b>{format.macronutrientMass(nutrition.protein, { precision })}</b>
    </React.Fragment>
  )
}
