import React, { useState } from "react"

import Form from "react-bootstrap/Form"

import Flex from "components/flex"

import CalculatorButton from "./button"

export default function Add({ serving, adjust }) {
  const [value, setValue] = useState(0)

  return (
    <Flex justify="space-between">
      <Flex align="baseline" gap="0.5rem">
        <b>Add</b>
        <Form.Control
          type="number"
          size="sm"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ width: "4rem" }}
        />
        {serving.units}s
      </Flex>
      <CalculatorButton onClick={() => adjust("add", value)}>Add</CalculatorButton>
    </Flex>
  )
}
