import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Alert from "react-bootstrap/Alert"
import Modal from "react-bootstrap/Modal"
import * as yup from "yup"

import { api } from "api"
import Form from "components/form"
import Day from "day"
import { notify } from "notifications"

const DAYS_BEFORE_BIOMETRICS_BECOME_STALE = 7

function isStale(bodyLog) {
  if (!bodyLog) {
    return true
  }

  return (
    Day.fromISOString(bodyLog.measured_on).toOffset() <
    -1 * DAYS_BEFORE_BIOMETRICS_BECOME_STALE
  )
}

export default function BiometricPoll({
  missingMetrics,
  bodyFat,
  refreshBodyFat,
  loadingBodyFat,
  weight,
  refreshWeight,
  loadingWeight,
  refreshMetrics,
}) {
  const [dismissed, setDismissed] = useState(false)

  // make sure we've fetched these records properly
  if (loadingBodyFat || loadingWeight || dismissed) {
    return null
  }

  const userEstimatesBodyFat = !bodyFat && !missingMetrics.includes("bmr")

  const shouldPollBodyFat = isStale(bodyFat) && !userEstimatesBodyFat
  const shouldPollWeight = isStale(weight)

  if (!shouldPollWeight && !shouldPollBodyFat) {
    return null
  }

  async function submit(values) {
    const update = {
      ...(shouldPollWeight ? { weight: values.weight } : {}),
      ...(shouldPollBodyFat ? { body_fat: values.bodyFat / 100 } : {}),
    }

    let patchRecord = null
    const today = Day.today()
    if (bodyFat && Day.fromISOString(bodyFat.measured_on).isSameDay(today)) {
      patchRecord = bodyFat
    } else if (weight && Day.fromISOString(weight.measured_on).isSameDay(today)) {
      patchRecord = weight
    }
    try {
      if (!patchRecord) {
        await api.post("/api/v1/body_logs", update)
      } else {
        await api.patch(`/api/v1/body_logs/${patchRecord.idx}`, update)
      }

      if (shouldPollWeight && shouldPollBodyFat) {
        notify.success("Updated weight and body fat!")
      } else if (shouldPollWeight) {
        notify.success("Updated weight!")
      } else if (shouldPollBodyFat) {
        notify.success("Updated body fat!")
      }
    } catch (error) {
      notify.error(error)
    } finally {
      setDismissed(true)
      await Promise.all([
        ...(shouldPollWeight ? [refreshWeight()] : []),
        ...(shouldPollBodyFat ? [refreshBodyFat()] : []),
        refreshMetrics(),
      ])
    }
  }

  return (
    <Form
      initialValues={{
        ...(shouldPollBodyFat ? { bodyFat: "" } : {}),
        ...(shouldPollWeight ? { weight: "" } : {}),
      }}
      validationSchema={{
        ...(shouldPollBodyFat
          ? {
              bodyFat: yup
                .number()
                .moreThan(0, "Body fat must be greater than zero")
                .lessThan(100, "Body fat must be less than 100%")
                .required("Please add your body fat percentage"),
            }
          : {}),
        ...(shouldPollWeight
          ? {
              weight: yup
                .number()
                .moreThan(0, "Weight must be greater than zero")
                .required("Please add your weight"),
            }
          : {}),
      }}
      onSubmit={submit}
    >
      {(formik) => (
        <Modal show={!dismissed} onHide={() => setDismissed(true)}>
          <Form.Body formik={formik}>
            <Modal.Header closeButton>
              <Modal.Title>Log your progress</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Alert variant="warning">
                <FontAwesomeIcon icon="exclamation-circle" /> Not keeping this
                information up to date can mess up your macro ranges and make your diet
                ineffective
              </Alert>
              {shouldPollWeight && (
                <Form.Number
                  id="biometric-poll-weight"
                  name="weight"
                  label="What did you weigh today?"
                  formik={formik}
                  append="lbs."
                />
              )}
              {shouldPollBodyFat && (
                <Form.Number
                  id="biometric-poll-body-fat"
                  name="bodyFat"
                  label="What was your body fat % today?"
                  formik={formik}
                  append="%"
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Form.Button formik={formik}>Submit</Form.Button>
            </Modal.Footer>
          </Form.Body>
        </Modal>
      )}
    </Form>
  )
}
