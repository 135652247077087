import React, { useEffect, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation, Link, useParams } from "react-router-dom"

import { api } from "api"
import Flex from "components/flex"
import Loading from "components/loading"
import PageTitle from "components/page_title"
import { notify } from "notifications"
import FoodBadges from "shared/food_badges"

import CustomControls from "./custom_controls"
import Nutrition from "./nutrition"
import Seasonality from "./seasonality"

async function getFood(id, search_, setFood, setLoading) {
  const search = new URLSearchParams(search_)

  try {
    setLoading(true)
    setFood(
      (
        await api.get(`/api/v1/foods/${id}`, {
          ...(search.get("amount") ? { amount: search.get("amount") } : {}),
          ...(search.get("measure_idx")
            ? { measure_idx: search.get("measure_idx") }
            : {}),
        })
      ).data
    )
  } catch (error) {
    notify.error(error)
  } finally {
    setLoading(false)
  }
}

export default function FoodPage() {
  const { id } = useParams()

  const { search } = useLocation()

  const [loading, setLoading] = useState(false)

  const [food, setFood] = useState({})

  useEffect(() => {
    async function wrapper() {
      await getFood(id, search, setFood, setLoading)
    }

    wrapper()
  }, [id, search])

  return (
    <Loading loading={loading} debounce>
      <Flex justify="space-between" align="baseline">
        <PageTitle icon="cookie-bite">
          {food.name || "..."}{" "}
          {food.alternate_name && (
            <small>
              <span className="text-muted">({food.alternate_name})</span>
            </small>
          )}
        </PageTitle>
        {food.recipe_idx && (
          <Link to={`/recipes/${food.recipe_idx}`}>
            Go to recipe page <FontAwesomeIcon icon="arrow-right" />
          </Link>
        )}
      </Flex>
      {food.description && <p>{food.description}</p>}
      {food.brand && <span>Brand: {food.brand}</span>}

      <Flex column gap="0.5rem">
        <FoodBadges food={food} />
        <Nutrition food={food} />
        <Seasonality food={food} />
        <CustomControls food={food} />
      </Flex>
    </Loading>
  )
}
