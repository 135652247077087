import React, { useState } from "react"

import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import * as yup from "yup"

import { api } from "api"
import Animate from "components/animate"
import Form from "components/form"
import { notify } from "notifications"

export default function CallToAction() {
  const [submitted, setSubmitted] = useState(false)

  return (
    <div style={{ marginBottom: "4rem" }}>
      {submitted ? <Submitted /> : <Signup setSubmitted={setSubmitted} />}
    </div>
  )
}

function Signup({ setSubmitted }) {
  const [loading, setLoading] = useState(false)

  async function signup({ email }) {
    try {
      setLoading(true)
      await api.post("/api/v1/users", { email })
      setSubmitted(true)
    } catch (error) {
      notify.error(error)
      setSubmitted(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Animate
      pulse
      onceInView={{ triggerOnce: true, threshold: 1 }}
      delay={1000}
      duration={400}
    >
      <Card bg="dark">
        <Card.Body>
          <Card.Title>Enter your email to get started</Card.Title>
          <Form
            initialValues={{ email: "" }}
            validationSchema={{
              email: yup.string().required("Please enter a valid email"),
            }}
            onSubmit={signup}
          >
            {(formik) => (
              <Form.Body formik={formik}>
                <Row>
                  <Form.Email
                    id="signupEmail"
                    name="email"
                    formik={formik}
                    placeholder="your.email@example.com"
                  />
                </Row>
                <Row>
                  <Form.Button block loading={loading} formik={formik}>
                    Create an account
                  </Form.Button>
                </Row>
                <Row>
                  <small>
                    No payment method required, create an account, add your goals, and
                    get to work in under 5 minutes.
                  </small>
                </Row>
              </Form.Body>
            )}
          </Form>
        </Card.Body>
      </Card>
    </Animate>
  )
}

function Submitted() {
  return (
    <Animate pulse duration={500}>
      <Card bg="dark" style={{ textAlign: "center" }}>
        <Card.Body>
          <Card.Title>Check your email</Card.Title>
          We&apos;ve created your account and sent you a link to login and get started.
        </Card.Body>
      </Card>
    </Animate>
  )
}
