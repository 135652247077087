import React from "react"

import format from "format"

// This component is intended to communicate the nature and magnitude of the change
// created by selecting a search result. In practice, this should include things like
// an amount of calories and a macronutrient balance.
export default function Consequence({ result }) {
  if (
    !(
      result.nutrition__calories ||
      result.nutrition__fat ||
      result.nutrition__carbs ||
      result.nutrition__protein
    )
  ) {
    return null
  }

  // See if we can find a nice measure to provide a better label for the nutrition hint
  const niceMeasure = (result.measures || []).find(
    (measure) =>
      measure.amount === "1 dimensionless" || measure.amount === result.amount
  )

  let amountLabel = result.amount
  if (niceMeasure) {
    amountLabel = niceMeasure.name
  }

  return (
    <div className="text-muted">
      <small>
        {format.number(result.nutrition__calories)} calories,{" "}
        {format.macronutrientMass(result.nutrition__fat)} fat,{" "}
        {format.macronutrientMass(result.nutrition__carbs)} carbs,{" "}
        {format.macronutrientMass(result.nutrition__protein)} protein per {amountLabel}
      </small>
    </div>
  )
}
