import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Animate from "components/animate"

export default function UniqueSellingProposition() {
  return (
    <React.Fragment>
      <h1 style={{ margin: 0, padding: 0 }}>
        <Animate enterLeft duration={400}>
          <b style={{ fontSize: "8rem" }}>GUTTS</b>
        </Animate>
      </h1>
      <div style={{ fontSize: "2rem" }}>
        <Animate enterRight duration={400}>
          The Goal-Based Nutrition Tracker
        </Animate>
      </div>
      <Animate
        fadeIn
        duration={1000}
        delay={1600}
        style={{ margin: "6rem 0", fontSize: "2rem" }}
      >
        Tell us what you want your diet to do, we give you the diet that does it.
      </Animate>
      <Animate fadeIn duration={400} delay={3400}>
        <div style={{ fontSize: "2rem", marginBottom: "18rem" }}>
          <FontAwesomeIcon icon="arrow-down" size="2x" />
        </div>
      </Animate>
    </React.Fragment>
  )
}
