import { useLocation } from "react-router-dom"

import Day from "day"

import Chips from "./chips"
import DateRange, { DATE_RANGES } from "./date_range"

export function useDateRange(field) {
  const { search: search_ } = useLocation()
  const search = new URLSearchParams(search_)
  const dateRange = search.get(field) || Object.keys(DATE_RANGES)[0]

  return {
    start: Day.fromOffset(DATE_RANGES[dateRange][0]),
    end: Day.fromOffset(DATE_RANGES[dateRange][1]),
  }
}

function QueryControl() {
  throw new Error("Abstract component!")
}

QueryControl.Chips = Chips
QueryControl.DateRange = DateRange

export default QueryControl
