import React from "react"

import Flex from "components/flex"

import Epitaph from "./epitaph"

export default function Details() {
  return (
    <Epitaph>
      <Flex column gap="1rem">
        <div>
          <h2>Our Mission</h2>
          The health and wellness industry exists to profit, and often times what&apos;s
          good for business isn&apos;t good for consumers. GUTTS is the nutrition
          tracker that puts your goals first. This means we:
          <ul>
            <li>Make logging what you eat as easy as possible,</li>
            <li>Give you a clear idea of when you&apos;re on track,</li>
            <li>Help you make the right adjustments when you&apos;re not.</li>
          </ul>
          We don&apos;t:
          <ul>
            <li>Sell your data to third parties,</li>
            <li>Manipulate our product to enrich advertisers or corporate sponsors,</li>
            <li>Profit from anything other than your direct support.</li>
          </ul>
          We&apos;re a small and committed team; if things aren&apos;t working out,
          there&apos;s always a real person on hand to make sure we&apos;re meeting your
          needs.
        </div>
        <div>
          <h2>Pricing</h2>
          GUTTS is free to try for a month, no credit card required, then costs just $3
          a month. We charge a reasonable price directly so we&apos;re only obligated to
          you, not advertisers or 3rd parties.
        </div>
        <div>
          <h2>Additional Features</h2>
          Here&apos;s a short, but non-exhaustive list of features we support.
          <ul>
            <li>Keto diets</li>
            <li>Dirty bulking</li>
            <li>Healthy, sustainable cuts</li>
            <li>Body recomp</li>
            <li>Recipes</li>
            <li>Mobile app</li>
            <li>Analytics for dweebs</li>
            <li>And more...</li>
          </ul>
        </div>
      </Flex>
    </Epitaph>
  )
}
