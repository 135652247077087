import React from "react"

import Alert from "react-bootstrap/Alert"

import Animate from "components/animate"

export default function AccountDeleted() {
  return (
    <Animate fadeIn onceInView={{ triggerOnce: true, threshold: 0.5 }} delay={200}>
      <Alert>
        <Alert.Heading>Account Deleted</Alert.Heading>
        <p>
          We&apos;re sad to see you go! Your data has been deleted from our core
          database. Please give us 30 days to delete any remaining backups. Thank you
          for using GUTTS, we hope we see you again soon!
        </p>
      </Alert>
    </Animate>
  )
}
