import React from "react"

import _ from "lodash"
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import { usePage } from "api"
import Empty from "components/empty"
import Flex from "components/flex"
import Loading from "components/loading"
import Metric from "components/metric"
import { METRICS } from "constant"

function formatDate(dt) {
  return new Date(dt).toLocaleDateString()
}

export default function Graph({ dateRange: { start, end }, metrics }) {
  // Weird: hook is called usePage, but this is an endpoint where we
  // expect an infinitely large page. Hmm.... Should probably actually
  // be paginated
  const graphData = usePage("/api/v1/metrics", {
    start,
    end,
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    metric: metrics,
  })

  const units = _.uniq(metrics.map((metric) => METRICS[metric].unit.name))

  if (graphData.page.length === 0) {
    return (
      <Empty icon="utensils" show={graphData !== null && graphData.page.length === 0}>
        No data
      </Empty>
    )
  }

  return (
    <Flex justify="center">
      <Loading loading={graphData.loading} debounce>
        <ResponsiveContainer width="100%" height={400} debounce={350}>
          <LineChart data={graphData.page}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" tickFormatter={formatDate} />
            {units.map((unit, i) => (
              <YAxis key={unit} yAxisId={unit} orientation={i ? "right" : "left"} />
            ))}
            <Tooltip
              labelFormatter={formatDate}
              formatter={(value, metric) => [
                <Metric value={value} unit={METRICS[metric].unit.name} />,
                METRICS[metric].label,
              ]}
            />
            {metrics.map((metric) => (
              <Line
                key={metric}
                dataKey={metric}
                type="monotone"
                stroke={METRICS[metric].color}
                yAxisId={METRICS[metric].unit.name}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Loading>
    </Flex>
  )
}
