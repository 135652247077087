import React from "react"

import Button from "components/button"

import getProps from "./props"

const ServingButton = React.forwardRef(
  ({ food, measures, searchResult, postOption, ingredient, serving, ...rest }, ref) => {
    const { icon, label } = getProps({
      food,
      measures,
      searchResult,
      postOption,
      ingredient,
      serving,
    })
    return (
      <Button {...{ ...rest, icon, iconSide: "left" }} ref={ref}>
        {label}
      </Button>
    )
  }
)

export default ServingButton
