const FOOD_SEARCH_HINTS = [
  "1 cup whole milk",
  "2 burgers",
  "30g peanuts",
  "1 head broccoli",
  "75g asparagus",
  "1 lb. chicken thighs",
  "1 medium avocado",
  "Halo Top ice cream",
  "3 jumbo egg yolks",
  "2 slices meat lover's pizza",
  "1 package spaghetti",
  "3 bananas",
  "1 small bell pepper",
  "1 tbsp vegetable oil",
  "2 tbsp butter",
  "4 slices bread",
  "8 oz ribeye steak",
  "curly fries",
  "3 chicken breasts",
  "3 large eggs",
].map((text) => `Try "${text}"`)

const FOOD_SEARCH_HINTS_LENGTH = FOOD_SEARCH_HINTS.length

export default function getPlaceholder() {
  return FOOD_SEARCH_HINTS[(new Date().getDate() * 7177) % FOOD_SEARCH_HINTS_LENGTH]
}
