import React, { useEffect, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { sortBy } from "lodash"
import Alert from "react-bootstrap/Alert"

import { api } from "api"
import Animate from "components/animate"
import Button from "components/button"
import Flex from "components/flex"
import Loading from "components/loading"
import useAuth from "hooks/auth"
import { notify } from "notifications"

async function getPrices({ setPrices, setLoading, user }) {
  if (!user.idx) {
    return
  }

  let res
  try {
    setLoading(true)
    res = await api.get("/api/v1/vendors/stripe/prices")
  } catch (error) {
    notify.error(error)
  } finally {
    setLoading(false)
  }

  if (!res) {
    return
  }

  // Get the yearly price first
  setPrices(sortBy(res.data, (price) => price.period !== "year"))
}

function Options() {
  const auth = useAuth()
  const [prices, setPrices] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function wrapper() {
      await getPrices({ user: auth.user, setPrices, setLoading })
    }

    wrapper()
  }, [auth.user])

  return (
    <Loading loading={loading}>
      <div style={{ minHeight: "1rem", textAlign: "center" }}>
        {!!prices.length && <PriceButton primary price={prices[0]} />}
        {prices.length > 1 && (
          <React.Fragment>
            <div className="text-muted" style={{ margin: "0.5rem 0" }}>
              <small>or</small>
            </div>
            {prices.slice(1).map((price) => (
              <PriceButton price={price} key={price.id} />
            ))}
          </React.Fragment>
        )}
      </div>
    </Loading>
  )
}

function Epitaph() {
  return (
    <React.Fragment>
      <hr />

      <div style={{ textAlign: "justify", lineHeight: "1.1rem" }}>
        <small className="text-muted">
          Our business is built on your success, charging directly keeps it that way. We
          don&apos;t run ads, sell your data, or otherwise profit from anything short of
          your belief GUTTS makes your diet work.
        </small>
      </div>
    </React.Fragment>
  )
}

function Paywall({ subscribeTo }) {
  const auth = useAuth()

  if (auth.user.is_subscribed) {
    return null
  }

  let heading = "Subscription Expired"
  if (!auth.user.stripe_customer_id) {
    heading = "Free Trial Ended"
  }

  return (
    <Alert variant="warning" style={{ textAlign: "center" }}>
      <Alert.Heading>
        <Flex justify="space-between">
          <FontAwesomeIcon icon="lock" />
          <div>{heading}</div>
          <FontAwesomeIcon icon="lock" />
        </Flex>
      </Alert.Heading>

      {subscribeTo && <p>Please subscribe to {subscribeTo}</p>}

      <Options />

      <Epitaph />
    </Alert>
  )
}

function PriceButton({ price, primary }) {
  const [waitingForStripe, setWaitingForStripe] = useState(false)

  async function subscribe() {
    setWaitingForStripe(true)
    try {
      const res = await api.post("/api/v1/vendors/stripe/checkout", {
        price_id: price.id,
      })

      const stripe = window.Stripe(res.data.stripe_publishable_key)
      await stripe.redirectToCheckout({ sessionId: res.data.session_id })
    } catch (error) {
      notify.error(error)
    } finally {
      setWaitingForStripe(false)
    }
  }

  let priceLabel = `${price.unit_amount / 100} ${price.currency}`
  if (price.currency === "usd") {
    priceLabel = `$${price.unit_amount / 100}`
  }

  return (
    <Animate wiggle={primary} delay={500}>
      <Button
        onClick={subscribe}
        loading={waitingForStripe}
        variant={primary ? "primary" : "secondary"}
        size={primary ? "lg" : "sm"}
        icon={primary ? "check-circle" : undefined}
        iconSide="left"
        block
      >
        {price.period === "year"
          ? `Subscribe for a Year (${priceLabel})`
          : `Subscribe for ${priceLabel} per ${price.period}`}
      </Button>
    </Animate>
  )
}

Paywall.Options = Options
Paywall.Epitaph = Epitaph

export default Paywall
