import React from "react"

import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import { Link } from "react-router-dom"
import * as yup from "yup"

import Flex from "components/flex"
import Form from "components/form"
import useAuth from "hooks/auth"

export default function LoginPage() {
  const auth = useAuth()
  return (
    <Card>
      <Card.Header>
        <Card.Title>Login</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form
          initialValues={{ email: "", password: "" }}
          validationSchema={{
            email: yup.string().required("Email required"),
            password: yup.string().required("Password required"),
          }}
          onSubmit={({ email, password }) => auth.login(email, password)}
        >
          {(formik) => (
            <Form.Body formik={formik}>
              <Row>
                <Form.Email
                  id="loginEmail"
                  name="email"
                  label="Email"
                  formik={formik}
                />
              </Row>
              <Row>
                <Form.Password
                  id="loginPassword"
                  name="password"
                  label="Password"
                  formik={formik}
                />
              </Row>
              <Row>
                <Flex align="baseline" justify="space-between">
                  <Form.Button formik={formik}>Login</Form.Button>
                  <Link to="/request_password">Forgot password?</Link>
                </Flex>
              </Row>
            </Form.Body>
          )}
        </Form>
      </Card.Body>
    </Card>
  )
}
