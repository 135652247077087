import React from "react"

import { capitalize } from "lodash"

import Button from "components/button"
import Flex from "components/flex"
import Day from "day"

import { useLastEvent } from "./event_context"

export default function DateControl({ dayOffset, setDayOffset }) {
  const day = Day.fromOffset(dayOffset)

  const lastEvent = useLastEvent()

  function changeOffset(newOffset) {
    setDayOffset(newOffset)
    if (dayOffset < newOffset) {
      lastEvent.setDateChangeForward()
    } else if (dayOffset > newOffset) {
      lastEvent.setDateChangeBack()
    } else {
      lastEvent.setNone()
    }
  }

  return (
    <Flex justify="space-between" align="baseline" margin="1rem 0">
      <div style={{ width: "9rem" }}>
        <Button
          variant="light"
          onClick={() => changeOffset(dayOffset - 1)}
          icon="arrow-left"
          iconSide="left"
        >
          {capitalize(day.previous().pretty())}
        </Button>
      </div>

      {Math.abs(dayOffset) > 1 && (
        <div style={{ width: "10rem" }}>
          <Button
            variant="light"
            onClick={() => changeOffset(0)}
            iconSide={dayOffset > 0 ? "left" : "right"}
            icon={dayOffset > 0 ? "angle-double-left" : "angle-double-right"}
          >
            Back to Today
          </Button>
        </div>
      )}

      <div style={{ width: "9rem", textAlign: "right" }}>
        <Button
          variant="light"
          onClick={() => changeOffset(dayOffset + 1)}
          icon="arrow-right"
        >
          {capitalize(day.next().pretty())}
        </Button>
      </div>
    </Flex>
  )
}
