import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import BootstrapTooltip from "react-bootstrap/Tooltip"

function Tooltip({ children, id, placement, tooltip }) {
  return (
    <OverlayTrigger
      placement={placement || "auto"}
      overlay={<BootstrapTooltip id={id}>{tooltip}</BootstrapTooltip>}
    >
      {children}
    </OverlayTrigger>
  )
}

const Icon = React.forwardRef(({ icon, variant, size, ...rest }, ref) => (
  <span ref={ref} {...rest}>
    <FontAwesomeIcon icon={icon} className={`text-${variant}`} size={size} />
  </span>
))

export function IconTooltip({ id, icon, children, placement, variant, size }) {
  return (
    <Tooltip id={id} placement={placement} tooltip={children}>
      <Icon icon={icon} variant={variant} size={size} />
    </Tooltip>
  )
}

function InfoTooltip(props) {
  return <IconTooltip {...props} icon="info-circle" variant="muted" />
}

Tooltip.Info = InfoTooltip

export default Tooltip
