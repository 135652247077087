import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function PageTitle({ icon, children }) {
  if (!icon) {
    return <h1>{children}</h1>
  }

  return (
    <h1>
      <FontAwesomeIcon icon={icon} /> {children}
    </h1>
  )
}
