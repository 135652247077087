import React from "react"

import Card from "react-bootstrap/Card"

import Loading from "components/loading"
import Paywall from "components/paywall"
import useAuth from "hooks/auth"

import BodyLog from "./body_log"
import CalorieMeter from "./calorie_meter"
import CarbMeter from "./carb_meter"
import FatMeter from "./fat_meter"
import Grid from "./grid"
import ProteinMeter from "./protein_meter"
import Stamp from "./stamp"

export default function Goals({
  dayOffset,
  metrics,
  streak,
  weight,
  bodyFat,
  loadingMetrics,
  loadingStreak,
  loadingWeight,
  loadingBodyFat,
}) {
  const auth = useAuth()
  if (!auth.user.is_subscribed) {
    return <Paywall subscribeTo="keep tracking your goals" />
  }

  const macroMeterScale =
    (Math.max(
      metrics.maximum_fat_target,
      metrics.maximum_carb_target,
      metrics.maximum_protein_target
    ) || 0) + 50

  return (
    <Card style={{ marginBottom: "1rem" }}>
      <Card.Body>
        <Loading loading={loadingMetrics} debounce>
          <Stamp metrics={metrics} streak={streak} loadingStreak={loadingStreak} />
          <Grid>
            <Grid.Header dayOffset={dayOffset} />
            <CalorieMeter metrics={metrics} />
            <FatMeter metrics={metrics} scale={macroMeterScale} />
            <CarbMeter metrics={metrics} scale={macroMeterScale} />
            <ProteinMeter metrics={metrics} scale={macroMeterScale} />
          </Grid>
        </Loading>
      </Card.Body>
      <Card.Footer>
        <BodyLog
          weight={weight}
          bodyFat={bodyFat}
          loadingWeight={loadingWeight}
          loadingBodyFat={loadingBodyFat}
        />
      </Card.Footer>
    </Card>
  )
}
