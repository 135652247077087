import React, { useState } from "react"

import Card from "react-bootstrap/Card"

import { api } from "api"
import Button from "components/button"
import Editable from "components/editable"
import Flex from "components/flex"
import NutritionHint from "components/nutrition_hint"
import format from "format"
import { notify } from "notifications"

export default function IngredientCard({ ingredient, refreshData }) {
  const [deleting, setDeleting] = useState(false)
  const [patching, setPatching] = useState(false)

  async function deleteIngredient() {
    setDeleting(true)
    try {
      await api.delete(`/api/v1/ingredients/${ingredient.idx}`)
    } catch (error) {
      notify.error(error)
    } finally {
      setDeleting(false)
      await refreshData()
    }
  }

  async function patchIngredient(magnitude) {
    setPatching(true)
    try {
      await api.patch(`/api/v1/ingredients/${ingredient.idx}`, { magnitude })
    } catch (error) {
      notify.error(error)
    } finally {
      setPatching(false)
      await refreshData()
    }
  }

  return (
    <Card key={ingredient.idx} style={{ marginTop: "1rem" }}>
      <Card.Body>
        <Flex justify="space-between" align="baseline">
          <Flex align="center" gap="0.5rem">
            <Editable.Number
              initialValue={ingredient.magnitude}
              loading={patching}
              onCommit={(magnitude) => patchIngredient(magnitude)}
              units={format.foodAmount(ingredient.units)}
              style={{ width: "8rem" }}
            />
            {ingredient.name}
          </Flex>
          <Button
            onClick={() => deleteIngredient(ingredient.idx)}
            variant="outline-danger"
            style={{ marginLeft: "0.5rem" }}
            icon="trash-alt"
            loading={deleting}
          />
        </Flex>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted">
          <NutritionHint nutrition={ingredient.nutrition} />
        </small>
      </Card.Footer>
    </Card>
  )
}
