import React, { useContext, useState } from "react"

import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import * as yup from "yup"

import { api } from "api"
import Button from "components/button"
import Form from "components/form"
import { notify } from "notifications"

const CustomIngredientModalContext = React.createContext()

export function CustomIngredientModalProvider({ children, recipeIdx, refreshData }) {
  const [showModal, setShowModal] = useState(false)

  async function onSubmit(values) {
    try {
      await api.post("/api/v1/ingredients", {
        recipe_idx: recipeIdx,
        name: values.name,
        nutrition: {
          calories: values.calories,
          protein: values.protein,
          carbs: values.carbs,
          sugar: values.sugar,
          fiber: values.fiber,
          fat: values.fat,
          saturated_fat: values.saturatedFat,
          trans_fat: values.transFat,
        },
      })

      setShowModal(false)
    } catch (error) {
      notify.error(error)
    } finally {
      await refreshData()
    }
  }

  return (
    <CustomIngredientModalContext.Provider
      value={{
        open: () => setShowModal(true),
        close: () => setShowModal(false),
      }}
    >
      {children}
      <Form
        initialValues={{
          name: "",
          calories: 0,
          protein: 0,
          carbs: 0,
          sugar: 0,
          fiber: 0,
          fat: 0,
          saturatedFat: 0,
          transFat: 0,
        }}
        validationSchema={{
          name: yup.string().required(),
          calories: yup.number().required(),
          protein: yup.number().required(),
          carbs: yup.number().required(),
          sugar: yup.number().required(),
          fiber: yup.number().required(),
          fat: yup.number().required(),
          saturatedFat: yup.number().required(),
          transFat: yup.number().required(),
        }}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add custom ingredient</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Body formik={formik}>
                <Row>
                  <Form.Text
                    id="custom-ingredient-name"
                    name="name"
                    label="What did you eat?"
                    placeholder='E.g. "whole milk"'
                    formik={formik}
                  />
                </Row>
                <Row>
                  <Form.Number
                    id="custom-ingredient-calories"
                    name="calories"
                    label="Calories"
                    formik={formik}
                    append="cal"
                  />
                </Row>
                <Row>
                  <Form.Number
                    id="custom-ingredient-carbs"
                    name="carbs"
                    label="Total carbs"
                    formik={formik}
                    append="g"
                  />
                  <Form.Number
                    id="custom-ingredient-sugar"
                    name="sugar"
                    label="Sugar"
                    formik={formik}
                    append="g"
                  />
                  <Form.Number
                    id="custom-ingredient-fiber"
                    name="fiber"
                    label="Fiber"
                    formik={formik}
                    append="g"
                  />
                </Row>
                <Row>
                  <Form.Number
                    id="custom-ingredient-fat"
                    name="fat"
                    label="Total fat"
                    formik={formik}
                    append="g"
                  />
                  <Form.Number
                    id="custom-ingredient-sfat"
                    name="saturatedFat"
                    label="Saturated fat"
                    formik={formik}
                    append="g"
                  />
                  <Form.Number
                    id="custom-ingredient-tfat"
                    name="transFat"
                    label="Trans fat"
                    formik={formik}
                    append="g"
                  />
                </Row>
                <Row>
                  <Form.Number
                    id="custom-ingredient-protein"
                    name="protein"
                    label="Protein"
                    formik={formik}
                    append="g"
                  />
                </Row>
                <Row>
                  <Form.Button formik={formik} block>
                    Submit
                  </Form.Button>
                </Row>
              </Form.Body>
            </Modal.Body>
          </Modal>
        )}
      </Form>
    </CustomIngredientModalContext.Provider>
  )
}

function useCustomIngredientModal() {
  return useContext(CustomIngredientModalContext)
}

export function CustomIngredientModalButton() {
  const customIngredientModal = useCustomIngredientModal()

  // onMouseDown event keeps menus from blurring in Safari
  return (
    <Button
      block
      onClick={customIngredientModal.open}
      onMouseDown={(event) => event.preventDefault()}
    >
      Add a custom ingredient
    </Button>
  )
}
