import React, { useEffect, useState } from "react"

import { api } from "api"
import Loading from "components/loading"
import { notify } from "notifications"

export default function BillingPage() {
  const [loading, setLoading] = useState(false)

  async function redirectToBillingPortal() {
    setLoading(true)
    try {
      const res = await api.post("/api/v1/vendors/stripe/portal")
      window.location.href = res.data.url
    } catch (error) {
      notify.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    async function wrapper() {
      await redirectToBillingPortal()
    }

    wrapper()
  }, [])

  return (
    <div style={{ textAlign: "center" }}>
      <h1>Redirecting you to the billing portal</h1>
      <Loading loading={loading} />
    </div>
  )
}
