export const AMDRs = {
  CARBS: {
    min: 0.45,
    max: 0.65,
  },
  PROTEIN: {
    min: 0.1,
    max: 0.35,
  },
  FAT: {
    min: 0.2,
    max: 0.35,
  },
}

export const YES_NO_OPTIONS = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
]

export const NOT_SET_OPTION = { label: "Choose an option", value: "" }

export const PAL_OPTIONS = [
  {
    label: "Almost no exercise",
    value: 1.2,
  },
  {
    label: "Exercise 1-2 times / week",
    value: 1.38,
  },
  {
    label: "Exercise 3-5 times / week",
    value: 1.55,
  },
  {
    label: "Exercise 6-7 times / week",
    value: 1.73,
  },
  {
    label: "I'm profesionally in shape",
    value: 1.9,
  },
]

export const GENDER_OPTIONS = [
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
  { label: "Prefer not to say", value: "PREFER_NOT_TO_SAY" },
]

// Labels answer the question: how fast do you want your weight to change?
export const CALORIC_PACE_OPTIONS = [
  { label: "Slow and steady", value: 250 },
  { label: "At a healthy and reasonable pace", value: 500 },
  { label: "Extremely fast", value: 800 },
]

export const UNIT_PROPERTIES = {
  CALORIE: {
    name: "CALORIE",
    abbr: "cal",
  },
  PERCENTAGE_POINT: {
    name: "PERCENTAGE_POINT",
    abbr: "%",
  },
  POUND: {
    name: "POUND",
    abbr: "lb",
  },
  YEAR: {
    name: "YEAR",
    abbr: "yr",
  },
  GRAM: {
    name: "GRAM",
    abbr: "g",
  },
  MILLIGRAM: {
    name: "MILLIGRAM",
    abbr: "mg",
  },
}

const COLORS = {
  magenta: "#d33682",
  red: "#dc322f",
  orange: "#cb4b16",
  yellow: "#b58900",
  green: "#859900",
  blue: "#268bd2",
  cyan: "#2aa198",
  violet: "#6c71c4",
}

export const METRICS = {
  calories: {
    label: "Calories",
    unit: UNIT_PROPERTIES.CALORIE,
    color: COLORS.blue,
    key: "calories",
  },
  protein: {
    label: "Protein",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.orange,
    key: "protein",
  },
  carbs: {
    label: "Carbs",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.yellow,
    key: "carbs",
  },
  sugar: {
    label: "Sugar",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.yellow,
    key: "sugar",
  },
  fiber: {
    label: "Fiber",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.yellow,
    key: "fiber",
  },
  fat: { label: "Fat", unit: UNIT_PROPERTIES.GRAM, color: COLORS.red, key: "fat" },
  trans_fat: {
    label: "Trans fat",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.red,
    key: "trans_fat",
  },
  saturated_fat: {
    label: "Saturated fat",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.red,
    key: "saturated_fat",
  },
  sodium: {
    label: "Sodium",
    unit: UNIT_PROPERTIES.MILLIGRAM,
    color: COLORS.cyan,
    key: "sodium",
  },
  cholesterol: {
    label: "Cholesterol",
    unit: UNIT_PROPERTIES.MILLIGRAM,
    color: COLORS.cyan,
    key: "cholesterol",
  },
  weight: {
    label: "Weight",
    unit: UNIT_PROPERTIES.POUND,
    color: COLORS.green,
    key: "weight",
  },
  body_fat: {
    label: "Body fat %",
    unit: UNIT_PROPERTIES.PERCENTAGE_POINT,
    color: COLORS.green,
    key: "body_fat",
  },
  tdee: {
    label: "TDEE",
    unit: UNIT_PROPERTIES.CALORIE,
    color: COLORS.magenta,
    key: "tdee",
  },
  ideal_protein_intake: {
    label: "Ideal protein intake",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.magenta,
    key: "ideal_protein_intake",
  },
  bmr: {
    label: "Basal metabolic rate",
    unit: UNIT_PROPERTIES.CALORIE,
    color: COLORS.magenta,
    key: "bmr",
  },
  katch_mcardle_bmr: {
    label: "Katch McArdle BMR",
    unit: UNIT_PROPERTIES.CALORIE,
    color: COLORS.magenta,
    key: "katch_mcardle_bmr",
  },
  mifflin_st_jeor_bmr: {
    label: "Mifflin St. Jeor BMR",
    unit: UNIT_PROPERTIES.CALORIE,
    color: COLORS.magenta,
    key: "mifflin_st_jeor_bmr",
  },
  age: { label: "Age", unit: UNIT_PROPERTIES.YEAR, color: COLORS.violet, key: "age" },
  carb_energy_ratio: {
    label: "Carb energy ratio",
    unit: UNIT_PROPERTIES.PERCENTAGE_POINT,
    color: COLORS.magenta,
    key: "carb_energy_ratio",
  },
  protein_energy_ratio: {
    label: "Protein energy ratio",
    unit: UNIT_PROPERTIES.PERCENTAGE_POINT,
    color: COLORS.magenta,
    key: "protein_energy_ratio",
  },
  fat_energy_ratio: {
    label: "Fat energy ratio",
    unit: UNIT_PROPERTIES.PERCENTAGE_POINT,
    color: COLORS.magenta,
    key: "fat_energy_ratio",
  },
  minimum_calorie_target: {
    label: "Minimum Calorie Target",
    unit: UNIT_PROPERTIES.CALORIE,
    color: COLORS.magenta,
    key: "minimum_calorie_target",
  },
  calorie_target: {
    label: "Calorie Target",
    unit: UNIT_PROPERTIES.CALORIE,
    color: COLORS.magenta,
    key: "calorie_target",
  },
  maximum_calorie_target: {
    label: "Maximum Calorie Target",
    unit: UNIT_PROPERTIES.CALORIE,
    color: COLORS.magenta,
    key: "maximum_calorie_target",
  },
  minimum_protein_target: {
    label: "Minimum Protein Target",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.magenta,
    key: "minimum_protein_target",
  },
  maximum_protein_target: {
    label: "Maximum Protein Target",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.magenta,
    key: "maximum_protein_target",
  },
  minimum_carb_target: {
    label: "Minimum Carb Target",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.magenta,
    key: "minimum_carb_target",
  },
  maximum_carb_target: {
    label: "Maximum Carb Target",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.magenta,
    key: "maximum_carb_target",
  },
  minimum_fat_target: {
    label: "Minimum Fat Target",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.magenta,
    key: "minimum_fat_target",
  },
  maximum_fat_target: {
    label: "Maximum Fat Target",
    unit: UNIT_PROPERTIES.GRAM,
    color: COLORS.magenta,
    key: "maximum_fat_target",
  },
}
