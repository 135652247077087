import React from "react"

import Loading from "components/loading"
import format from "format"

function Section({ children, label, sub = false }) {
  return (
    <div style={{ borderBottom: "0.01rem solid", marginLeft: `${sub * 1}rem` }}>
      {sub ? label : <b>{label}</b>} {children}
    </div>
  )
}

function Calories({ value }) {
  return <React.Fragment>{format.calories(value)}</React.Fragment>
}

function Macronutrient({ value }) {
  return (
    <React.Fragment>{format.macronutrientMass(value, { precision: 1 })}</React.Fragment>
  )
}

function Micronutrient({ value }) {
  return <React.Fragment>{format.micronutrientMass(value)}</React.Fragment>
}

export default function NutritionFacts({ children, amount, nutrition, loading }) {
  return (
    <div style={{ border: "2px solid", padding: "8px", minWidth: "14rem" }}>
      <Loading loading={loading} debounce>
        <b>Nutrition Facts</b>
        <div style={{ borderTop: "0.8rem solid", borderBottom: "0.8rem solid" }}>
          <div>{children || <small>Amount per {amount} serving</small>}</div>
          <Section label="Calories">
            <Calories value={nutrition.calories} />
          </Section>

          <div style={{ borderTop: "0.25rem solid" }} />

          <Section label="Total Fat">
            <Macronutrient value={nutrition.fat} />
          </Section>
          <Section sub label="Saturated Fat">
            <Macronutrient value={nutrition.saturated_fat} />
          </Section>
          <Section sub label="Trans Fat">
            <Macronutrient value={nutrition.trans_fat} />
          </Section>

          <Section label="Cholesterol">
            <Micronutrient value={nutrition.cholesterol} />
          </Section>
          <Section label="Sodium">
            <Micronutrient value={nutrition.sodium} />
          </Section>

          <Section label="Total Carbohydrates">
            <Macronutrient value={nutrition.carbs} />
          </Section>
          <Section sub label="Sugar">
            <Macronutrient value={nutrition.sugar} />
          </Section>
          <Section sub label="Fiber">
            <Macronutrient value={nutrition.fiber} />
          </Section>

          <Section label="Protein">
            <Macronutrient value={nutrition.protein} />
          </Section>
        </div>
      </Loading>
    </div>
  )
}
