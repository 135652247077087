import React from "react"

import FoodSearch from "shared/food_search"

import { CustomIngredientModalProvider } from "./custom_ingredient"
import DefaultMenu from "./default_menu"
import Footer from "./footer"
import Result from "./result"

export default function IngredientSearch({ recipeIdx, refreshData }) {
  return (
    <CustomIngredientModalProvider recipeIdx={recipeIdx} refreshData={refreshData}>
      <FoodSearch
        defaultMenu={({ closeMenu }) => (
          <DefaultMenu
            closeMenu={closeMenu}
            recipeIdx={recipeIdx}
            refreshData={refreshData}
          />
        )}
        searchResult={({ closeMenu, result }) => (
          <Result
            closeMenu={closeMenu}
            food={result}
            recipeIdx={recipeIdx}
            refreshData={refreshData}
          />
        )}
        searchFooter={() => <Footer />}
      />
    </CustomIngredientModalProvider>
  )
}
