import React from "react"

import Flex from "components/flex"
import Loading from "components/loading"
import format from "format"

function Biometric({ label, children }) {
  return (
    <Flex align="baseline" gap="0.5rem">
      <small className="text-muted">{label}</small>
      <b>{children}</b>
    </Flex>
  )
}

export default function Summary({ weight, bodyFat, loading, userEstimatesBodyFat }) {
  return (
    <div style={{ marginBottom: "1rem" }}>
      <Loading loading={loading}>
        <Flex gap="1rem">
          <Biometric label="Weight">
            {format.weight(weight, { precision: 1 })}
          </Biometric>
          {!userEstimatesBodyFat && (
            <Biometric label="Body Fat">
              {format.percent(bodyFat, { precision: 1 })}
            </Biometric>
          )}
        </Flex>
      </Loading>
    </div>
  )
}
