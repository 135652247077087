import React from "react"

export default function DisclaimersPage() {
  return (
    <div>
      <h1>Data Accuracy</h1>
      <p>
        While we strive to obtain accurate nutrition information, we cannot guarantee or
        ensure the accuracy, completeness or timeliness of any nutrition information. We
        recommend that you do not solely rely on the information presented on this
        website and that you always read labels, warnings, and directions and other
        information provided with foods and food products before using or consuming
        them. For additional information about foods and / or related products, please
        contact the parties responsible for making and distributing those foods and / or
        related products.
      </p>

      <h1>Other liabilities</h1>
      <p>
        The team behind G.U.T.T.S. Worldwide LLC and its proprietors assume no liability
        for inaccuracies or misstatements about foods and / or food products in this
        product or anywhere else. Information and statements regarding dietary
        supplements have not been evaluated by the Food and Drug Administration and are
        not intended to diagnose, treat, cure or prevent any disease or health
        condition. Content on this website is for reference purposes only and is not
        intended to substitute for advice given by a physician, pharmacist or other
        licensed healthcare professional. You should not use this information as
        self-diagnosis or for treating a health problem or disease. Contact your
        health-care provider immediately if you suspect that you have a medical problem.
      </p>
    </div>
  )
}
