import React from "react"

import { Link } from "react-router-dom"

import Flex from "components/flex"
import Loading from "components/loading"
import format from "format"

function Biometric({ label, value }) {
  return (
    <small className="text-muted">
      {label}: <b>{value}</b>
    </small>
  )
}

export default function BodyLog({
  weight: weight_,
  bodyFat: bodyFat_,
  loadingWeight,
  loadingBodyFat,
}) {
  const weight = weight_ && weight_.weight
  const bodyFat = bodyFat_ && bodyFat_.body_fat

  return (
    <Flex justify="space-between" align="baseline">
      <Loading inline loading={loadingWeight || loadingBodyFat}>
        <Flex gap="1rem">
          <Biometric label="Weight" value={format.weight(weight, { precision: 1 })} />
          {![null, undefined].includes(bodyFat) && (
            <Biometric
              label="Body Fat"
              value={format.percent(bodyFat, { precision: 1 })}
            />
          )}
        </Flex>
      </Loading>
      <small>
        <Link to="/body">Update...</Link>
      </small>
    </Flex>
  )
}
