import React, { useState } from "react"

import Card from "react-bootstrap/Card"

import { api } from "api"
import Editable from "components/editable"
import Flex from "components/flex"
import NutritionHint from "components/nutrition_hint"
import format from "format"
import { notify } from "notifications"

import { useLastEvent } from "../../event_context"
import Calculator from "./calculator"
import CopyTo from "./copy_to"
import Delete from "./delete"
import MoveTo from "./move_to"

export default function ServingCard({ serving, refreshDietData }) {
  const lastEvent = useLastEvent()
  const [patching, setPatching] = useState(false)

  async function patchServing(magnitude) {
    try {
      setPatching(true)
      await api.patch(`/api/v1/servings/${serving.idx}`, { magnitude })
    } catch (error) {
      notify.error(error)
    } finally {
      setPatching(false)
      lastEvent.setNone()
      await refreshDietData()
    }
  }

  const { nutrition } = serving

  return (
    <Card style={{ marginBottom: "0.66rem" }}>
      <Card.Body style={{ paddingBottom: "0.5rem" }}>
        <Flex align="baseline" gap="1rem">
          <Calculator serving={serving} refreshDietData={refreshDietData} />
          <Editable.Number
            initialValue={serving.magnitude}
            units={format.foodAmount(serving.units)}
            loading={patching}
            onCommit={patchServing}
            style={{ width: "10rem" }}
            precision={3}
          />
          <span>{serving.food}</span>
        </Flex>

        <hr style={{ color: "rgba(0, 0, 0, 0.2)", margin: "0.75rem 0 0.5rem" }} />

        <div>
          <small className="text-muted">
            <Flex justify="space-between" align="baseline">
              <div>
                <NutritionHint nutrition={nutrition} />
              </div>
              <Flex gap="0.33rem" align="center">
                <Delete serving={serving} refreshDietData={refreshDietData} />
                <div
                  style={{
                    paddingRight: "0.4rem",
                    marginRight: "0.4rem",
                    borderRight: "1px dotted white",
                    height: "1rem",
                  }}
                />
                <CopyTo serving={serving} refreshDietData={refreshDietData} />
                <MoveTo serving={serving} refreshDietData={refreshDietData} />
              </Flex>
            </Flex>
          </small>
        </div>
      </Card.Body>
    </Card>
  )
}
