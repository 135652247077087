import React, { useState } from "react"

import { api } from "api"
import CalendarPopover from "components/calendar_popover"
import Day from "day"
import { notify } from "notifications"

import { useLastEvent } from "../../event_context"
import FooterButton from "./footer_button"

export default function MoveTo({ serving, refreshDietData }) {
  const [loading, setLoading] = useState(false)

  const lastEvent = useLastEvent()

  async function moveServing(newDate_) {
    const newDate = Day.fromDate(newDate_)
    try {
      setLoading(true)
      await api.patch(`/api/v1/servings/${serving.idx}`, { eaten_on: newDate })
      notify.success(`Moved serving to ${newDate.pretty()}`)
    } catch (error) {
      notify.error(error)
    } finally {
      lastEvent.setNone()
      setLoading(false)
    }

    await refreshDietData()
  }

  return (
    <CalendarPopover
      title="Move to..."
      value={Day.fromISOString(serving.eaten_on).asDate()}
      onChange={moveServing}
      loading={loading}
    >
      <FooterButton icon="arrow-right">Move</FooterButton>
    </CalendarPopover>
  )
}
