import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Animate from "components/animate"
import Flex from "components/flex"
import Tooltip from "components/tooltip"

export default function Field({ id, label, children, complete, info }) {
  return (
    <div style={{ marginTop: "1rem" }}>
      <Flex
        align="baseline"
        justify="space-between"
        style={{ marginBottom: "0.25rem" }}
      >
        <div style={{ whiteSpace: "normal" }}>
          {label}
          {info && (
            <span style={{ marginLeft: "0.25rem" }}>
              <Tooltip.Info
                id={`info-icon-for-${id}`}
                icon="info-circle"
                variant="muted"
              >
                {info}
              </Tooltip.Info>
            </span>
          )}
        </div>
        <div>
          {complete && (
            <Animate splat>
              <FontAwesomeIcon icon="check-circle" className="text-success" />
            </Animate>
          )}
        </div>
      </Flex>
      <div>{children}</div>
    </div>
  )
}
