import React, { useEffect, useState } from "react"

import ListGroup from "react-bootstrap/ListGroup"
import { useHistory, Link } from "react-router-dom"

import { api } from "api"
import Animate from "components/animate"
import Button from "components/button"
import Empty from "components/empty"
import Flex from "components/flex"
import Loading from "components/loading"
import NutritionHint from "components/nutrition_hint"
import PageTitle from "components/page_title"
import Pagination from "components/pagination"
import Day from "day"
import useAuth from "hooks/auth"
import { notify } from "notifications"
import CustomFoodModal from "shared/custom_food_modal"

async function getFoodsPage({ page, auth, setFoods, setLoading }) {
  if (!auth.user) {
    return
  }

  try {
    setLoading(true)
    setFoods(
      await api.get("/api/v1/foods", {
        page,
        owner_idx: auth.user.idx,
        custom_foods: 1,
      })
    )
  } catch (error) {
    notify.error(error)
  } finally {
    setLoading(false)
  }
}

export default function FoodsPage() {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [foods, setFoods] = useState({})
  const [postingServing, setPostingServing] = useState(false)
  const history = useHistory()

  const auth = useAuth()

  useEffect(() => {
    async function wrapper() {
      await getFoodsPage({ page, auth, setFoods, setLoading })
    }

    wrapper()
  }, [page, auth])

  async function refreshFoods() {
    setPage(1)
    await getFoodsPage({ page: 1, auth, setFoods, setLoading })
  }

  const noFoods = !(foods.data || []).length && !loading

  async function postServing(food) {
    const today = Day.today()
    let posted = false
    try {
      setPostingServing(true)
      await api.post("/api/v1/servings", {
        food_idx: food.idx,
        eaten_on: today,
        amount: 1,
      })

      posted = true
    } catch (error) {
      notify.error(error)
    } finally {
      setPostingServing(false)
    }

    if (posted) {
      notify.success(`Recorded 1 serving ${today.pretty()}!`)
      history.push("/")
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <CustomFoodModal
        show={showModal}
        setShow={setShowModal}
        afterSubmit={refreshFoods}
        postServing={postServing}
        postingServing={postingServing}
      />
      <PageTitle icon="apple-alt">Your Foods</PageTitle>

      <div style={{ position: "absolute", right: 0, top: 0 }}>
        <Animate wiggle={noFoods}>
          <Button icon="plus" onClick={() => setShowModal(true)}>
            Add
          </Button>
        </Animate>
      </div>

      <p>Add foods you can&apos;t find via search here.</p>
      <Loading loading={loading} debounce>
        <ListGroup variant="flush">
          {(foods.data || []).map((food) => (
            <FoodRow key={food.idx} food={food} refreshFoods={refreshFoods} />
          ))}
        </ListGroup>
      </Loading>
      <Empty show={noFoods}>No custom foods, click above to add one</Empty>
      <Pagination cursor={foods.cursor} setPage={setPage} loading={loading} />
    </div>
  )
}

function FoodRow({ food, refreshFoods }) {
  const [deleting, setDeleting] = useState(false)

  async function deleteFood() {
    try {
      setDeleting(true)
      await api.delete(`/api/v1/foods/${food.idx}`)
    } catch (error) {
      notify.error()
    } finally {
      setDeleting(false)
    }

    await refreshFoods()
  }

  return (
    <ListGroup.Item key={food.idx}>
      <Flex justify="space-between" align="center">
        <div>
          <div>
            <Link to={`/foods/${food.idx}`} style={{ whiteSpace: "normal" }}>
              {food.brand && <span>{`${food.brand} `}</span>}
              <b>{food.name}</b>
            </Link>
          </div>
          <small className="text-muted">
            <NutritionHint nutrition={food.nutrition} />
          </small>
        </div>
        <Button
          variant="outline-secondary"
          loading={deleting}
          onClick={deleteFood}
          icon="trash-alt"
        />
      </Flex>
    </ListGroup.Item>
  )
}
