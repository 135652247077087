import { groupBy, sortBy, uniq } from "lodash"

import { api, ApiError, MAX_PAGE_SIZE } from "api"
import Day from "day"
import { notify } from "notifications"

const GOAL_METRICS = [
  "minimum_calorie_target",
  "maximum_calorie_target",
  "minimum_protein_target",
  "maximum_protein_target",
  "minimum_carb_target",
  "maximum_carb_target",
  "minimum_fat_target",
  "maximum_fat_target",
]

const DIET_METRICS = [
  "calories",
  "fat",
  "protein",
  "digestible_carbs",
  "carb_energy_ratio",
  "fat_energy_ratio",
  "protein_energy_ratio",
  "diet_on_target",
  "diet_failed",
  "day_active",
  "protein_target_room_to_run",
  "protein_target_defecit",
  "protein_target_failed",
  "protein_target_actionable",
  "protein_target_almost_over",
  "protein_target_lagging",
  "carb_target_room_to_run",
  "carb_target_defecit",
  "carb_target_failed",
  "carb_target_actionable",
  "carb_target_almost_over",
  "carb_target_lagging",
  "fat_target_room_to_run",
  "fat_target_defecit",
  "fat_target_failed",
  "fat_target_actionable",
  "fat_target_almost_over",
  "fat_target_lagging",
  "calorie_target_room_to_run",
  "calorie_target_defecit",
  "calorie_target_failed",
  "calorie_target_actionable",
  "calorie_target_almost_over",
  "calories_on_target",
  "protein_on_target",
  "carbs_on_target",
  "fat_on_target",
]

async function getGoalMetrics(start, end) {
  try {
    return await api.get("/api/v1/metrics", {
      start,
      end,
      metric: GOAL_METRICS,
    })
  } catch (error) {
    return error
  }
}

async function getDietMetrics(start, end) {
  try {
    return await api.get("/api/v1/metrics", {
      start,
      end,
      metric: DIET_METRICS,
    })
  } catch (error) {
    if (error.type !== "MissingMetricDataError") {
      return error
    }
  }

  return null
}

export async function getMetrics({
  dayOffset,
  setMetrics,
  setLoadingMetrics,
  setMissingMetrics,
}) {
  // We separate metrics here by whether they require serving data or not. Those that
  // do often fail but we can still get targets for the meters.
  const start = Day.fromOffset(dayOffset)
  const end = start.next()

  let resultList = []
  try {
    setLoadingMetrics(true)
    resultList = await Promise.all([
      getDietMetrics(start, end),
      getGoalMetrics(start, end),
    ])
  } catch (error) {
    notify.error(error)
  } finally {
    setLoadingMetrics(false)

    let metrics = {}
    let missingMetrics = []
    resultList.forEach((res) => {
      if (res instanceof ApiError && res.type === "MissingMetricDataError") {
        missingMetrics = uniq([...missingMetrics, ...res.info.missing_data])
      } else if (res instanceof ApiError && res.type === "SubscriptionExpired") {
        // ignore, UX should handle this elsewhere
      } else if (res instanceof ApiError) {
        notify.error(res)
      } else if (res !== null) {
        metrics = { ...metrics, ...res.data[0] }
      }
    })

    setMetrics(metrics)
    setMissingMetrics(missingMetrics)
  }
}

async function getServings({ dayOffset, setServings, setLoadingServings }) {
  const servings = []
  try {
    setLoadingServings(true)

    let nextPage = 1
    while (nextPage) {
      // eslint-disable-next-line no-await-in-loop
      const res = await api.get("/api/v1/servings", {
        start: Day.fromOffset(dayOffset),
        end: Day.fromOffset(dayOffset + 1),
        page_size: MAX_PAGE_SIZE,
        page: nextPage,
        sort: "created_at.desc",
      })

      servings.push(...res.data)
      setServings(servings)
      nextPage = res.cur.next
    }
  } catch (error) {
    notify.error(error)
    setServings([])
  } finally {
    setLoadingServings(false)
  }
}

async function getStreak({ dayOffset, setStreak, setLoadingStreak }) {
  let res
  try {
    setLoadingStreak(true)
    res = await api.get("/api/v1/metric_stats", {
      metric: "diet_on_target",
      start: Day.fromOffset(dayOffset - 31),
      end: Day.fromOffset(dayOffset + 1),
    })
    setStreak(res.data.diet_on_target.streak)
  } catch (error) {
    setStreak(null)
    if (["MissingMetricDataError", "SubscriptionExpired"].includes(error.type)) {
      // these are safe to ignore
    } else {
      notify.error(error)
    }
  } finally {
    setLoadingStreak(false)
  }
}

async function getLatestBiometric({ field, setBiometric, setLoading }) {
  try {
    setLoading(true)
    const res = await api.get("/api/v1/body_logs", {
      require: field,
      page_size: 1,
      sort: "measured_on.desc",
    })
    setBiometric(res.data.length ? res.data[0] : null)
  } catch (error) {
    notify.error(error)
  } finally {
    setLoading(false)
  }
}

export async function getBodyFat({ setBodyFat, setLoadingBodyFat }) {
  return getLatestBiometric({
    field: "body_fat",
    setBiometric: setBodyFat,
    setLoading: setLoadingBodyFat,
  })
}

export async function getWeight({ setWeight, setLoadingWeight }) {
  return getLatestBiometric({
    field: "weight",
    setBiometric: setWeight,
    setLoading: setLoadingWeight,
  })
}

export async function getDietData({
  dayOffset,
  setMetrics,
  setLoadingMetrics,
  setServings,
  setLoadingServings,
  setStreak,
  setLoadingStreak,
  setMissingMetrics,
  setWeight,
  setLoadingWeight,
  setBodyFat,
  setLoadingBodyFat,
}) {
  await Promise.all([
    getServings({ dayOffset, setServings, setLoadingServings }),
    getMetrics({ dayOffset, setMetrics, setLoadingMetrics, setMissingMetrics }),
    getWeight({ setWeight, setLoadingWeight }),
    getBodyFat({ setBodyFat, setLoadingBodyFat }),
  ])
  await getStreak({ dayOffset, setStreak, setLoadingStreak })
}

export async function getServingSuggestions({ setSuggestions, setLoadingSuggestions }) {
  let servings
  try {
    setLoadingSuggestions(true)
    servings = (
      await api.get("/api/v1/servings", {
        start: Day.fromOffset(-30),
        end: Day.tomorrow(),
        sort: "eaten_on.desc,idx",
        page_size: MAX_PAGE_SIZE,
      })
    ).data
  } catch (error) {
    notify.error(error)
  } finally {
    setLoadingSuggestions(false)
  }

  if (!servings) {
    setSuggestions([])
  } else {
    setSuggestions(
      sortBy(
        Object.values(groupBy(servings, (serving) => `${serving.food}`)),
        (group) => -1 * group.length
      ).map((group) => group[0])
    )
  }
}
