import React, { useState } from "react"

import { api } from "api"
import Expand from "components/expand"
import Flex from "components/flex"
import Day from "day"
import useBreakpoint from "hooks/breakpoint"
import { notify } from "notifications"
import FoodLink from "shared/food_link"
import ServingButton from "shared/serving_button"

import { useLastEvent } from "../event_context"

export default function Result({
  result: food,
  refreshDietData,
  dayOffset,
  closeMenu,
}) {
  const maxOptionsToDisplay = 1 + !!useBreakpoint("lg") + !!useBreakpoint("xl")

  const mainOption = food.post_options[0]
  const topLevelOptions = food.post_options.slice(1, maxOptionsToDisplay)
  const expandOptions = food.post_options.slice(maxOptionsToDisplay)

  return (
    <Expand
      id={`food-search-result-expand-${food.idx}`}
      hasExtraContent={!!expandOptions.length}
    >
      <Expand.Header>
        <Flex align="baseline" justify="space-between" gap="0.5rem">
          <FoodLink food={food} />
          <Flex align="baseline" gap="0.5rem">
            {topLevelOptions.map((postOption) => (
              <PostOptionButton
                variant="outline-secondary"
                key={JSON.stringify(postOption)}
                food={food}
                postOption={postOption}
                dayOffset={dayOffset}
                refreshDietData={refreshDietData}
                closeMenu={closeMenu}
              />
            ))}
            <PostOptionButton
              food={food}
              postOption={mainOption}
              dayOffset={dayOffset}
              refreshDietData={refreshDietData}
              closeMenu={closeMenu}
            />
          </Flex>
        </Flex>
      </Expand.Header>

      <Expand.Body>
        <Flex gap="0.5rem" wrap justify="end">
          {expandOptions.map((postOption) => (
            <PostOptionButton
              variant="outline-secondary"
              key={JSON.stringify(postOption)}
              food={food}
              postOption={postOption}
              dayOffset={dayOffset}
              refreshDietData={refreshDietData}
              closeMenu={closeMenu}
            />
          ))}
        </Flex>
      </Expand.Body>
    </Expand>
  )
}

const PostOptionButton = React.forwardRef(
  ({ food, postOption, dayOffset, refreshDietData, closeMenu, ...rest }, ref) => {
    const lastEvent = useLastEvent()
    const [posting, setPosting] = useState(false)

    async function eatFood() {
      let res
      try {
        setPosting(true)
        res = await api.post("/api/v1/servings", {
          amount: postOption.amount,
          ...(postOption.measure_idx !== undefined
            ? { measure_idx: postOption.measure_idx }
            : {}),
          food_idx: food.idx,
          eaten_on: Day.fromOffset(dayOffset),
        })

        closeMenu()
      } catch (error) {
        notify.error(error)
      } finally {
        setPosting(false)
        if (res?.data?.idx) {
          lastEvent.setServingPosted(res.data.idx)
        } else {
          lastEvent.setNone()
        }
        await refreshDietData()
      }
    }

    return (
      <ServingButton
        ref={ref}
        {...rest}
        searchResult={food}
        postOption={postOption}
        onClick={eatFood}
        loading={posting}
      />
    )
  }
)
