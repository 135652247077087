import React from "react"

import ReactDOM from "react-dom"

import "react-calendar/dist/Calendar.css"
import "./index.scss"
import App from "./app"
import registerServiceWorker from "./register_service_worker"

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

registerServiceWorker()
