import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Flex from "components/flex"

function BaseHint({ icon, active }) {
  return (
    <Flex
      column
      justify="center"
      style={{
        position: "absolute",
        right: "3px",
        height: "100%",
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          margin: "1px 0",
          paddingRight: "4px",
          paddingLeft: "2px",
          zIndex: 1,
          background: "white",
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          className="text-primary"
          style={{ opacity: active ? 0.8 : 0.6 }}
        />
      </div>
    </Flex>
  )
}

export function NumberHint({ active }) {
  return <BaseHint icon="sort" active={active} />
}

export function TextHint({ active }) {
  return <BaseHint icon="pencil-alt" active={active} />
}

export function CalendarHint({ active }) {
  return <BaseHint icon="calendar" active={active} />
}

export function UnitOverlay({ text, units, style }) {
  return (
    <span
      className="form-control editable"
      style={{
        ...style,
        position: "absolute",
        borderStyle: "solid",
        borderColor: "transparent",
        background: "transparent",
        boxShadow: "unset",
        height: "100%",
        pointerEvents: "none",
      }}
      aria-hidden="true"
    >
      <span style={{ visibility: "hidden", opacity: 0 }}>{text}</span>{" "}
      <span className="text-primary">{units}</span>
    </span>
  )
}

export function Highlight({ text, substring, style }) {
  const index = text.indexOf(substring)

  if (!substring || index === -1) {
    return null
  }

  return (
    <div
      className="form-control editable"
      style={{
        position: "absolute",
        color: "rgba(0,0,0,0)",
        borderStyle: "solid",
        borderColor: "transparent",
        background: "transparent",
        boxShadow: "unset",
        height: "100%",
        pointerEvents: "none",
        ...style,
      }}
    >
      {text.slice(0, index)}
      <span className="bg-primary" style={{ opacity: 0.2, padding: "0 1px" }}>
        {substring}
      </span>
      {text.slice(index + substring.length, substring.length)}
    </div>
  )
}

export function OverlayContainer({ children }) {
  return <div style={{ position: "relative" }}>{children}</div>
}
