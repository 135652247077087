import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Loading from "components/loading"
import { UNIT_PROPERTIES } from "constant"

export default function Metric({ value, unit, precision, loading, good, bad, diff }) {
  let variant
  if (good === true) {
    variant = "text-success"
  } else if (bad === true) {
    variant = "text-danger"
  }

  let icon
  if (diff && value > 0) {
    icon = "arrow-up"
  } else if (diff && value < 0) {
    icon = "arrow-down"
  }

  let scaledValue = value
  if (unit === UNIT_PROPERTIES.PERCENTAGE_POINT.name) {
    // percents are always between 0 and 1 so we scale them
    scaledValue *= 100
  } else if (unit === UNIT_PROPERTIES.MILLIGRAM.name) {
    // all masses are in g, so setting unit to mg is also a formatting convenience
    scaledValue *= 1000
  }

  return (
    <Loading loading={loading} debounce>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={variant}
          style={{ marginRight: "0.5rem", verticalAlign: "baseline" }}
        />
      )}
      <span className={variant} style={{ whiteSpace: "nowrap" }}>
        <strong style={{ marginRight: "0.125rem", verticalAlign: "baseline" }}>
          {scaledValue ? scaledValue.toFixed(precision || 2) : null}
        </strong>
        <small className="text-muted" style={{ verticalAlign: "sub" }}>
          {value !== undefined && value !== null && UNIT_PROPERTIES[unit].abbr}
        </small>
      </span>
    </Loading>
  )
}
