import React from "react"

import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
import { Redirect, Link, useLocation } from "react-router-dom"

import Flex from "components/flex"
import useBreakpoint from "hooks/breakpoint"

export const DATE_RANGES = {
  "2 weeks": [-14, 1],
  "30 days": [-30, 1],
  "3 months": [-90, 1],
  "6 months": [-180, 1],
  "1 year": [-365, 1],
  all: [-99999, 1],
}

export default function DateRange({ id, field, label }) {
  const location_ = useLocation()
  const smallScreen = useBreakpoint("sm")

  const search = new URLSearchParams(location_.search)
  if (!search.get(field)) {
    search.set(field, "2 weeks")
    return <Redirect to={{ ...location_, search: search.toString() }} />
  }

  const optionProps = Object.keys(DATE_RANGES).map((key) => {
    const targetSearch = new URLSearchParams(location_.search)
    targetSearch.set(field, key)

    return {
      key,
      as: Link,
      active: key === search.get(field),
      to: { ...location_, search: targetSearch.toString() },
    }
  })

  return (
    <Flex align="baseline">
      {label && (
        <b className="text-primary" style={{ marginRight: "1rem" }}>
          {label}
        </b>
      )}
      {!smallScreen ? (
        <NavDropdown title={search.get(field)} id={id}>
          {optionProps.map((option) => (
            <NavDropdown.Item {...option}>{option.key}</NavDropdown.Item>
          ))}
        </NavDropdown>
      ) : (
        <Nav variant="pills" id={id}>
          {optionProps.map((option) => (
            <Nav.Link {...option}>{option.key}</Nav.Link>
          ))}
        </Nav>
      )}
    </Flex>
  )
}
