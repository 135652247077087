import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Alert from "react-bootstrap/Alert"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Collapse from "react-bootstrap/Collapse"
import ProgressBar from "react-bootstrap/ProgressBar"
import Row from "react-bootstrap/Row"

import Animate from "components/animate"
import Button from "components/button"
import Flex from "components/flex"
import Loading from "components/loading"
import Tooltip from "components/tooltip"
import useAuth from "hooks/auth"

import {
  Birthday,
  BodyFat,
  BuildMass,
  CaloricPace,
  Gender,
  GoalWeight,
  Height,
  InduceKetosis,
  PhysicalActivityLevel,
  Weight,
} from "./fields"
import Section from "./section"

export default function Onboarding({
  missingMetrics,
  setUserIsOnboarding,
  refreshMetrics,
  bodyFat,
  refreshBodyFat,
  loadingBodyFat,
  weight,
  refreshWeight,
  loadingWeight,
  loadingMetrics,
}) {
  const auth = useAuth()

  const [cannotMeasureBodyFat, setCannotMeasureBodyFat] = useState(false)

  return (
    <div style={{ position: "relative" }}>
      <h1>Welcome to GUTTS!</h1>
      <Animate wiggle delay={800}>
        <p>
          We need some info to calculate your goals and start tracking your progress.
          <b> Fill out this page as best you can and we&apos;ll be good to go!</b>
        </p>
      </Animate>

      <Progress
        user={auth.user}
        missingMetrics={missingMetrics}
        loadingMetrics={loadingMetrics}
      />

      <Loading loading={auth.user.loading} debounce>
        <Section>
          <Section.Title icon="bullseye">First, what are your goals?</Section.Title>
          <Row>
            <Col lg={6} sm={12}>
              <GoalWeight auth={auth} />
            </Col>
            <Col lg={6} sm={12}>
              <CaloricPace auth={auth} />
            </Col>
            <Col lg={6} sm={12}>
              <BuildMass auth={auth} />
            </Col>
            <Col lg={6} sm={12}>
              <InduceKetosis auth={auth} />
            </Col>
          </Row>
        </Section>

        <Section>
          <Section.Title icon="weight">
            Now let&apos;s get some basic info
          </Section.Title>
          <Row>
            <Col lg={6} sm={12}>
              <Weight
                weight={weight}
                loadingWeight={loadingWeight}
                refreshWeight={refreshWeight}
                refreshMetrics={refreshMetrics}
              />
            </Col>
            <Col lg={6} sm={12}>
              <PhysicalActivityLevel auth={auth} />
            </Col>
            <Col lg={12}>
              <BodyFat
                bodyFat={bodyFat}
                loadingBodyFat={loadingBodyFat}
                refreshBodyFat={refreshBodyFat}
                refreshMetrics={refreshMetrics}
                cannotMeasureBodyFat={cannotMeasureBodyFat}
                setCannotMeasureBodyFat={setCannotMeasureBodyFat}
              />
              <Collapse in={cannotMeasureBodyFat}>
                <div>
                  {/* redundant div is for animation smoothing */}
                  <Alert variant="warning" style={{ marginTop: "1rem" }}>
                    If you can&apos;t measure your body fat regularly with calipers or a
                    body fat scale, we can estimate it from your height, age, and
                    gender.{" "}
                    <b>
                      Leave this field blank and fill in the questions below instead.
                    </b>
                  </Alert>
                </div>
              </Collapse>
            </Col>
          </Row>
        </Section>

        <Section>
          <Section.Title icon="address-card">
            Optional info
            <span style={{ float: "right" }}>
              <Tooltip.Info id="onboarding-optional-info-tooltip">
                These questions are optional but your answers help GUTTS make accurate
                calculations when other data is missing. Your answers will remain
                private and your data will never be sold to a 3rd party.
              </Tooltip.Info>
            </span>
          </Section.Title>
          <Row>
            <Col lg={6} sm={12}>
              <Height auth={auth} refreshMetrics={refreshMetrics} />
            </Col>
            <Col lg={6} sm={12}>
              <Birthday auth={auth} refreshMetrics={refreshMetrics} />
            </Col>
            <Col lg={6} sm={12}>
              <Gender auth={auth} refreshMetrics={refreshMetrics} />
            </Col>
          </Row>
        </Section>

        <CompleteOnboardingButton
          user={auth.user}
          missingMetrics={missingMetrics}
          setUserIsOnboarding={setUserIsOnboarding}
        />
      </Loading>
    </div>
  )
}

function Progress({ user, missingMetrics, loadingMetrics }) {
  if (!Object.keys(user).length) {
    return null
  }

  const progress = Math.max(
    !!user.caloric_pace * 20 +
      !!user.pal * 20 +
      !!user.goal_weight * 20 +
      (!missingMetrics.includes("weight") && !loadingMetrics) * 20 +
      (!missingMetrics.includes("body_fat") && !loadingMetrics) * 20 +
      !user.gender * -2 +
      !user.height * -2 +
      !user.birthday * -2,
    0
  )

  if (progress === 0) {
    return null
  }

  let variant = "danger"
  if (progress > 20) {
    variant = "warning"
  }
  if (progress >= 80) {
    variant = "success"
  }

  const shouldExit = progress > 99.9
  const shouldEnter = !shouldExit

  return (
    <div
      style={{
        position: "sticky",
        top: "4rem",
        left: 0,
        zIndex: 10,
        margin: "1rem 0",
        opacity: 0.9,
      }}
    >
      <Animate enterTop={shouldEnter} exitTop={shouldExit} delay={shouldExit ? 300 : 0}>
        <Card>
          <Card.Body>
            <Flex align="baseline" gap="1rem">
              <div>
                <FontAwesomeIcon icon="tasks" size="lg" />
              </div>
              <ProgressBar
                now={progress}
                label={`${progress}% complete`}
                variant={variant}
                style={{ width: "100%", height: "1.5rem", fontSize: "1rem" }}
              />
            </Flex>
          </Card.Body>
        </Card>
      </Animate>
    </div>
  )
}

function CompleteOnboardingButton({ user, missingMetrics, setUserIsOnboarding }) {
  const disabled =
    [undefined, null].includes(user.pal) ||
    [undefined, null].includes(user.caloric_pace) ||
    !user.goal_weight ||
    missingMetrics.includes("weight") ||
    missingMetrics.includes("body_fat")
  return (
    <React.Fragment>
      <Animate wiggle={!disabled} delay={800}>
        <Button
          disabled={disabled}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" })
            setUserIsOnboarding(false)
          }}
          block
        >
          Get started
        </Button>
      </Animate>
      <div className="text-muted" style={{ marginTop: "0.5rem" }}>
        <small>
          If you make a mistake or need to update your information, you can always do so
          later on the settings page.
        </small>
      </div>
    </React.Fragment>
  )
}
