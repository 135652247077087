import React from "react"

import Figure from "react-bootstrap/Figure"

import Flex from "components/flex"
import PageTitle from "components/page_title"

export default function MobileAppPage() {
  return (
    <React.Fragment>
      <PageTitle icon="mobile">Installing the Mobile App</PageTitle>
      <h2>How to install</h2>
      <p>
        To install GUTTS on your phone or tablet: log into GUTTS on your mobile device,
        click the button next to the GUTTS logo, then click install on the resulting pop
        up.
      </p>
      <Flex justify="center">
        <Figure>
          <Figure.Image
            src="pwa_install_button.png"
            alt="Install button next to the GUTTS logo"
          />
          <Figure.Caption>The install button will look like this</Figure.Caption>
        </Figure>
      </Flex>
      <h2>Why</h2>
      <p>
        The GUTTS mobile app is a progressive web app or (PWA), which means you install
        it directly from your browser instead of going through an app store. This setup
        lets us deliver a secure, high-quality mobile app without lining the pockets of
        rent-seeking corporations. We highly recommend having GUTTS on your portable
        advice to make recording what you eat as convenient as possible.
      </p>
    </React.Fragment>
  )
}
