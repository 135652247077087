import React from "react"

export default function PrivacyPage() {
  return (
    <React.Fragment>
      <h1>Privacy Policy</h1>
      <p>
        G.U.T.T.S. Worldwide LLC is committed to protecting the privacy and accuracy of
        confidential information to the extent possible, subject to provisions of state
        and federal law. Other than as required by laws that guarantee public access to
        certain types of information, or in response to subpoenas or other legal
        instruments that authorize access, personal information is not actively shared.
        In particular, we do not re-distribute or sell personal information collected on
        our web servers.
      </p>

      <h2>Information we collect</h2>
      <p>
        We collect dietary data and body measures including but not limited to what
        users eat and when; body measures such as height, weight, and body fat
        percentage; and any other data relevant to the tracking and analysis of diet and
        physiological metrics. G.U.T.T.S. Worldwide LLC servers collect the following
        analytics: the Internet Protocol (IP) addresses of computers being used, web
        pages requested, referring web page browser used, date, and time. G.U.T.T.S.
        Worldwide LLC does not associate this data to individual user identities.
      </p>

      <h2>Cookies</h2>
      <p>
        G.U.T.T.S. Worldwide LLC site may use &quot;cookies&quot; in order to deliver
        web content specific to individual users&apos; interests or to keep track of
        online purchasing transactions. Sensitive personal information is not stored
        within cookies.
      </p>

      <h2>Webforms</h2>
      <p>
        G.U.T.T.S. Worldwide LLC uses webforms forms on this site. These forms require
        users to provide email addresses. Email addresses are only used to send users
        transactional emails for essential product functions including but not limited
        to password resets, account verification or opt-in email features. Email
        addresses and any contact information otherwise provided will not be sold to
        another party.
      </p>

      <h2>Use of collected information</h2>
      <p>
        Personal information collected by G.U.T.T.S. Worldwide LLC will only be used for
        the operation and maintenance of the product. IP address information, request
        metadata, and browser information will be used for troubleshooting and debugging
        issues. Browser cookies are used to identify users accessing secure content.
      </p>

      <h2>Distribution of collected information</h2>
      <p>
        G.U.T.T.S. Worldwide LLC will not disclose, without your consent, personal
        information collected about you, except for certain explicit circumstances in
        which disclosure is required by law. G.U.T.T.S. Worldwide LLC will not
        distribute or sell personal information to third-party organizations. This
        Privacy Statement does not apply to analytics tools used in the product. Please
        review the privacy policies of these tools before using the product.
      </p>

      <h2>Privacy Statement Revisions</h2>
      <p>
        This Privacy Statement was last revised on March 17th, 2021. We may change this
        Privacy Statement at any time and for any reason. We encourage you to review
        this Privacy Statement each time you visit the web site. If we make a
        significant change to our Privacy Statement, we will post a notice on the
        homepage of our web site for a period of time after the change is made.
      </p>

      <h2>Responsibility for External Sites</h2>
      <p>
        This website may contain links to other web sites. Some of those web sites may
        be operated by third parties. We provide the links for your convenience, but we
        do not review, control, or monitor the privacy practices of web sites operated
        by others. We are not responsible for the performance of web sites operated by
        third parties or for your business dealings with them. Therefore, whenever you
        leave this web site we recommend that you review each web site&apos;s privacy
        practices and make your own conclusions regarding the adequacy of these
        practices.
      </p>

      <h2>How to contact us</h2>
      <p>
        Contact information for the product owner(s) / operator(s) is available in the
        product.
      </p>
    </React.Fragment>
  )
}
