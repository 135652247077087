import React, { useState } from "react"

import { capitalize } from "lodash"
import BootstrapTable from "react-bootstrap/Table"

import { api } from "api"
import Button from "components/button"
import Editable from "components/editable"
import Day from "day"
import { notify } from "notifications"

function Table({ children }) {
  return <BootstrapTable>{children}</BootstrapTable>
}

Table.Header = ({ userEstimatesBodyFat }) => {
  return (
    <thead>
      <tr>
        <th style={{ width: "8rem" }}>Date</th>
        <th style={{ width: "12rem" }}>Weight</th>
        {!userEstimatesBodyFat && <th style={{ width: "12rem" }}>Body Fat</th>}
        <th />
      </tr>
    </thead>
  )
}

Table.Body = ({ bodyLogs, refreshData, userEstimatesBodyFat }) => {
  return (
    <tbody>
      {bodyLogs.page.map((bodyLog) => (
        <Row
          key={bodyLog.idx}
          userEstimatesBodyFat={userEstimatesBodyFat}
          bodyLog={bodyLog}
          refreshData={refreshData}
        />
      ))}
    </tbody>
  )
}

function Weight({ bodyLog, refreshData }) {
  const [patching, setPatching] = useState(false)

  async function patch(weight) {
    try {
      setPatching(true)
      await api.patch(`/api/v1/body_logs/${bodyLog.idx}`, { weight })
    } catch (error) {
      notify.error(error)
    } finally {
      setPatching(false)
      await refreshData()
    }
  }

  return (
    <Editable.Number
      style={{ width: "100%" }}
      editCta="None..."
      initialValue={bodyLog.weight || ""}
      units="lbs."
      loading={patching}
      onCommit={patch}
    />
  )
}

function BodyFat({ bodyLog, refreshData }) {
  const [patching, setPatching] = useState(false)

  async function patch(bodyFat) {
    try {
      setPatching(true)
      await api.patch(`/api/v1/body_logs/${bodyLog.idx}`, {
        body_fat: (bodyFat / 100).toFixed(2),
      })
    } catch (error) {
      notify.error(error)
    } finally {
      setPatching(false)
      await refreshData()
    }
  }

  return (
    <Editable.Number
      style={{ width: "100%" }}
      editCta="None"
      initialValue={
        ![null, undefined, ""].includes(bodyLog.body_fat) ? 100 * bodyLog.body_fat : ""
      }
      units="%"
      loading={patching}
      onCommit={patch}
    />
  )
}

function DateLabel({ bodyLog }) {
  return (
    <small className="text-muted">
      {capitalize(Day.fromISOString(bodyLog.measured_on).pretty())}
    </small>
  )
}

function DeleteLogButton({ bodyLog, refreshData }) {
  const [deleting, setDeleting] = useState(false)
  async function deleteLog() {
    try {
      setDeleting(true)
      await api.delete(`/api/v1/body_logs/${bodyLog.idx}`)
    } catch (error) {
      notify.error(error)
    } finally {
      setDeleting(false)
      await refreshData()
    }
  }

  return (
    <Button
      onClick={deleteLog}
      loading={deleting}
      icon="trash-alt"
      variant="outline-danger"
    />
  )
}

function Row({ bodyLog, refreshData, userEstimatesBodyFat }) {
  return (
    <tr style={{ verticalAlign: "baseline" }}>
      <td>
        <DateLabel bodyLog={bodyLog} />
      </td>
      <td style={{ paddingLeft: 0, verticalAlign: "baseline" }}>
        <Weight bodyLog={bodyLog} refreshData={refreshData} />
      </td>
      {!userEstimatesBodyFat && (
        <td style={{ paddingLeft: 0 }}>
          <BodyFat bodyLog={bodyLog} refreshData={refreshData} />
        </td>
      )}
      <td style={{ textAlign: "right" }}>
        <DeleteLogButton bodyLog={bodyLog} refreshData={refreshData} />
      </td>
    </tr>
  )
}

export default Table
