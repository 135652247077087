import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BootstrapButton from "react-bootstrap/Button"

import Loading from "components/loading"

export default React.forwardRef(
  ({ loading, children, icon, iconSide, block, ...rest }, ref) => {
    if (!["left", "right", undefined].includes(iconSide)) {
      // eslint-disable-next-line no-console
      console.warn(
        "Bad value for iconSide passed to button, expected left, right, or undefined"
      )
    }

    return (
      <BootstrapButton {...rest} ref={ref} style={block ? { width: "100%" } : {}}>
        <Loading loading={loading} inline>
          <span style={loading ? { visibility: "hidden" } : {}}>
            {icon && iconSide === "left" && (
              <FontAwesomeIcon
                icon={icon}
                style={{ marginRight: children ? "0.5rem" : 0 }}
              />
            )}
            {children}
            {icon && (iconSide === "right" || iconSide === undefined) && (
              <FontAwesomeIcon
                icon={icon}
                style={{ marginLeft: children ? "0.5rem" : 0 }}
              />
            )}
          </span>
        </Loading>
      </BootstrapButton>
    )
  }
)
