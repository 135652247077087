import React from "react"

import Form from "react-bootstrap/Form"

export default function Feedback({ formik, name }) {
  return (
    <React.Fragment>
      <Form.Control.Feedback
        type="invalid"
        style={{
          display: formik.touched[name] && formik.errors[name] ? "inherit" : "none",
        }}
      >
        {formik.errors[name]}
      </Form.Control.Feedback>
      <Form.Control.Feedback type="valid" />
    </React.Fragment>
  )
}
