import EditableDate from "./date"
import EditableNumber from "./number"
import EditableText from "./text"

function Editable() {
  throw new Error("Editable is an abstract base component, do not invoke directly.")
}

Editable.Number = EditableNumber
Editable.Date = EditableDate
Editable.Text = EditableText

export default Editable
