export const DEVICE_TYPES = {
  MOBILE: "mobile",
  DESKTOP: "desktop",
}

const MOBILE_DEVICE_FRAGMENTS = [
  "android",
  "webos",
  "iphone",
  "ipad",
  "ipod",
  "blackberry",
  "windows phone",
  "android",
  "ipad",
  "playbook",
  "silk",
]

const NORMALIZED_USER_AGENT = (navigator.userAgent || "").toLowerCase()

export function getDeviceType() {
  if (MOBILE_DEVICE_FRAGMENTS.some((frag) => NORMALIZED_USER_AGENT.includes(frag))) {
    return DEVICE_TYPES.MOBILE
  }

  return DEVICE_TYPES.DESKTOP
}
