import React, { useContext, useState } from "react"

import { api } from "api"
import Button from "components/button"
import Day from "day"
import { notify } from "notifications"
import CustomFoodModal from "shared/custom_food_modal"

import { useLastEvent } from "../event_context"

const CustomFoodModalContext = React.createContext()

export function CustomFoodModalProvider({ children, dayOffset, refreshDietData }) {
  const [show, setShow] = useState(false)
  const [postingServing, setPostingServing] = useState(false)

  const lastEvent = useLastEvent()

  async function postServing(food) {
    let res
    try {
      setPostingServing(true)
      res = await api.post("/api/v1/servings", {
        food_idx: food.idx,
        eaten_on: Day.fromOffset(dayOffset),
        amount: 1,
      })

      setShow(false)
    } catch (error) {
      notify.error(error)
    } finally {
      setPostingServing(false)
      if (res?.data?.idx) {
        lastEvent.setServingPosted(res.data.idx)
      } else {
        lastEvent.setNone()
      }
      await refreshDietData()
    }
  }

  return (
    <CustomFoodModalContext.Provider
      value={{
        open: () => setShow(true),
        close: () => setShow(false),
      }}
    >
      <CustomFoodModal
        show={show}
        setShow={setShow}
        postServing={postServing}
        postingServing={postingServing}
        afterSubmit={refreshDietData}
      />
      {children}
    </CustomFoodModalContext.Provider>
  )
}

function useCustomFoodModal() {
  return useContext(CustomFoodModalContext)
}

export function CustomFoodModalButton() {
  const customFoodModal = useCustomFoodModal()

  // onMouseDown event keeps menus from blurring in Safari
  return (
    <Button
      block
      onClick={customFoodModal.open}
      onMouseDown={(event) => event.preventDefault()}
    >
      Add a Custom Food
    </Button>
  )
}
