import React from "react"

import PageTitle from "components/page_title"
import Paywall from "components/paywall"
import Day from "day"
import useAuth from "hooks/auth"

export default function SubscribePage() {
  const auth = useAuth()

  return (
    <div>
      <PageTitle>Subscribe to GUTTS</PageTitle>
      <p>
        We rely on your direct support because it helps us make the best product
        possible. If GUTTS helps you hit your goals, you subscribe, if it doesn&apos;t,
        you don&apos;t. We don&apos;t want ad revenue to determine the product we build,
        and we don&apos;t want to build another platform that sells you stuff you
        don&apos;t need. If GUTTS works for you, please consider subscribing today. If
        it doesn&apos;t,{" "}
        <a
          href="mailto:john@gutts.io?subject=Feedback"
          target="_blank"
          rel="noopener noreferrer"
        >
          let us know what&apos;s missing,
        </a>{" "}
        we appreciate your feedback!
      </p>

      <p>
        <div>Thanks for checking us out,</div>
        <div>&#8211; The GUTTS team</div>
      </p>

      <p className="text-muted">
        Your free trial expires{" "}
        {Day.fromDate(new Date(auth.user.subscription_expires_at)).pretty()}
      </p>
      <Paywall.Options />
      <Paywall.Epitaph />
    </div>
  )
}
