import React, { useState } from "react"

import CloseButton from "react-bootstrap/CloseButton"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"

import DatePicker from "components/date_picker"
import Flex from "components/flex"

export default function CalendarPopover({
  id,
  title,
  value,
  onChange: onChange_,
  children,
  loading,
}) {
  const [show, setShow] = useState(false)

  async function onChange(...args) {
    await onChange_(...args)

    setShow(false)
  }

  function close() {
    setShow(false)
  }

  return (
    <OverlayTrigger
      placement="auto"
      trigger="click"
      rootClose
      show={show}
      onClick={() => setShow(!show)}
      onToggle={setShow}
      overlay={
        <Popover id={id} style={{ maxWidth: "22rem", fontSize: "1rem" }}>
          <Popover.Header>
            <Flex justify="space-between" align="center">
              <span style={{ fontSize: "1.2rem" }}>{title}</span>
              <CloseButton onClick={close} />
            </Flex>
          </Popover.Header>
          <Popover.Body style={{ padding: "0.25rem" }}>
            <DatePicker value={value} onChange={onChange} loading={loading} />
          </Popover.Body>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  )
}
