import React from "react"

import Row from "react-bootstrap/Row"
import { useLocation } from "react-router-dom"
import * as yup from "yup"

import { api } from "api"
import Form from "components/form"
import { notify } from "notifications"

export default function PasswordRequestPage() {
  const queryParams = new URLSearchParams(useLocation().search)

  async function requestNewPassword({ email }) {
    try {
      await api.get("/api/v1/auth/login")
      await api.post("/api/v1/auth/request", { email })
      notify.success("Password reset requested, check your email")
    } catch (error) {
      notify.error(error)
    }
  }

  return (
    <Form
      initialValues={{ email: queryParams.get("email") || "" }}
      validationSchema={{ email: yup.string().required() }}
      onSubmit={requestNewPassword}
    >
      {(formik) => (
        <Form.Body formik={formik}>
          <Row>
            <Form.Email
              id="password-reset-request-email"
              name="email"
              label="Email"
              formik={formik}
            />
          </Row>
          <Form.Button formik={formik}>Request password reset</Form.Button>
        </Form.Body>
      )}
    </Form>
  )
}
