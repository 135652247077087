import React, { useEffect, useState } from "react"

import { capitalize } from "lodash"

import Animate from "components/animate"
import PageTitle from "components/page_title"
import Day from "day"
import useAuth from "hooks/auth"

import BiometricPoll from "./biometric_poll"
import {
  getDietData,
  getMetrics,
  getServingSuggestions,
  getWeight,
  getBodyFat,
} from "./data"
import DateControl from "./date_control"
import { EventContext, EVENT_TYPES } from "./event_context"
import Goals from "./goals"
import Onboarding from "./onboarding"
import Search from "./search"
import Servings from "./servings"
import TrialExpirationWarning from "./trial_expiration_warning"

function getDateQuestion(day) {
  if (day.asDate().getTime() > Day.today().asDate().getTime()) {
    return `What Will You Eat ${capitalize(day.pretty())}?`
  }

  return `What Did You Eat ${capitalize(day.pretty())}?`
}

export default function DietPage() {
  const auth = useAuth()

  const [lastEvent, setLastEvent] = useState({})

  const [userIsOnboarding, setUserIsOnboarding] = useState(null)

  const [dayOffset, setDayOffset] = useState(0)

  const [servings, setServings] = useState([])
  const [loadingServings, setLoadingServings] = useState(false)

  const [metrics, setMetrics] = useState({})
  const [loadingMetrics, setLoadingMetrics] = useState(false)
  const [missingMetrics, setMissingMetrics] = useState([])

  const [streak, setStreak] = useState(null)
  const [loadingStreak, setLoadingStreak] = useState(false)

  const [suggestions, setSuggestions] = useState([])
  const [loadingSuggestions, setLoadingSuggestions] = useState(false)

  const [bodyFat, setBodyFat] = useState(null)
  const [loadingBodyFat, setLoadingBodyFat] = useState(false)

  const [weight, setWeight] = useState(null)
  const [loadingWeight, setLoadingWeight] = useState(false)

  useEffect(() => {
    // Once a user is identified as onboarding, this logic ensures the page
    // stays in an onboarding state until they explicitly take an action that
    // tells us they're done onboarding.
    if (userIsOnboarding !== null) {
      return
    }

    if (
      [undefined, null].includes(auth.user.pal) ||
      [undefined, null].includes(auth.user.caloric_pace) ||
      !auth.user.goal_weight
    ) {
      setUserIsOnboarding(true)
    }

    if (!Object.keys(metrics).length && !missingMetrics.length) {
      return
    }

    setUserIsOnboarding(
      missingMetrics.includes("weight") || missingMetrics.includes("body_fat")
    )
  }, [
    userIsOnboarding,
    auth.user.pal,
    auth.user.caloric_pace,
    auth.user.goal_weight,
    metrics,
    missingMetrics,
    auth.user,
  ])

  useEffect(() => {
    async function wrapper() {
      await getDietData({
        dayOffset,
        setMetrics,
        setLoadingMetrics,
        setServings,
        setLoadingServings,
        setStreak,
        setLoadingStreak,
        setMissingMetrics,
        setWeight,
        setLoadingWeight,
        setBodyFat,
        setLoadingBodyFat,
      })
    }

    wrapper()
  }, [dayOffset])

  useEffect(() => {
    async function wrapper() {
      await getServingSuggestions({ setSuggestions, setLoadingSuggestions })
    }

    wrapper()
  }, [])

  const refreshDietData = () => {
    getDietData({
      dayOffset,
      setMetrics,
      setLoadingMetrics,
      setServings,
      setLoadingServings,
      setStreak,
      setLoadingStreak,
      setMissingMetrics,
      setWeight,
      setLoadingWeight,
      setBodyFat,
      setLoadingBodyFat,
    })
  }

  const refreshBodyFat = () => getBodyFat({ setBodyFat, setLoadingBodyFat })
  const refreshWeight = () => getWeight({ setWeight, setLoadingWeight })
  const refreshMetrics = () =>
    getMetrics({
      dayOffset,
      setMetrics,
      setLoadingMetrics,
      setMissingMetrics,
    })

  if (userIsOnboarding) {
    return (
      <Onboarding
        missingMetrics={missingMetrics}
        setUserIsOnboarding={setUserIsOnboarding}
        bodyFat={bodyFat}
        refreshBodyFat={refreshBodyFat}
        loadingBodyFat={loadingBodyFat}
        weight={weight}
        refreshWeight={refreshWeight}
        loadingWeight={loadingWeight}
        refreshMetrics={refreshMetrics}
        loadingMetrics={loadingMetrics}
      />
    )
  }

  // Min height is set here so we always have room to scroll the search bar to the
  // top of the page when it's focused (specifically important for good mobile UX).
  return (
    <div style={{ minHeight: 1200, position: "relative" }}>
      <EventContext.Provider
        value={{
          ...lastEvent,
          setNone: () => setLastEvent({}),
          setDateChangeBack: () => setLastEvent({ type: EVENT_TYPES.DATE_CHANGE_BACK }),
          setDateChangeForward: () =>
            setLastEvent({ type: EVENT_TYPES.DATE_CHANGE_FORWARD }),
          setServingPosted: (servingIdx) =>
            setLastEvent({
              type: EVENT_TYPES.SERVING_POSTED,
              servingIdx,
            }),
        }}
      >
        <TrialExpirationWarning />
        <BiometricPoll
          missingMetrics={missingMetrics}
          bodyFat={bodyFat}
          refreshBodyFat={refreshBodyFat}
          loadingBodyFat={loadingBodyFat}
          weight={weight}
          refreshWeight={refreshWeight}
          loadingWeight={loadingWeight}
          refreshMetrics={refreshMetrics}
        />
        <Goals
          dayOffset={dayOffset}
          metrics={metrics}
          streak={streak}
          weight={weight}
          bodyFat={bodyFat}
          loadingMetrics={loadingMetrics}
          loadingStreak={loadingStreak}
          loadingWeight={loadingWeight}
          loadingBodyFat={loadingBodyFat}
        />
        <DateControl dayOffset={dayOffset} setDayOffset={setDayOffset} />
        <Animate
          wiggle={!servings.length && !servings.loading && dayOffset === 0}
          duration={500}
          delay={500}
          style={{ margin: "1rem 0" }}
        >
          <PageTitle>{getDateQuestion(Day.fromOffset(dayOffset))}</PageTitle>
        </Animate>
        <Search
          refreshDietData={refreshDietData}
          suggestions={suggestions}
          loadingSuggestions={loadingSuggestions}
          dayOffset={dayOffset}
        />
        <Servings
          dayOffset={dayOffset}
          loading={loadingServings}
          servings={servings}
          refreshDietData={refreshDietData}
        />
      </EventContext.Provider>
    </div>
  )
}
