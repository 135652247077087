import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faAddressCard,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAppleAlt,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faAtom,
  faBacon,
  faBeer,
  faBookOpen,
  faBox,
  faBreadSlice,
  faBullseye,
  faCalculator,
  faCalendar,
  faCaretDown,
  faCaretLeft,
  faCarrot,
  faChartLine,
  faCheckCircle,
  faChild,
  faClipboardList,
  faCog,
  faCookie,
  faCookieBite,
  faCopy,
  faDownload,
  faDrumstickBite,
  faEgg,
  faExclamationCircle,
  faExclamationTriangle,
  faFemale,
  faFistRaised,
  faFlagCheckered,
  faGlassWhiskey,
  faHamburger,
  faHotdog,
  faInfoCircle,
  faLeaf,
  faLemon,
  faLock,
  faLockOpen,
  faMale,
  faMobile,
  faPencilAlt,
  faPepperHot,
  faPizzaSlice,
  faPlus,
  faQuestionCircle,
  faServer,
  faSignOutAlt,
  faSort,
  faSpinner,
  faTasks,
  faTimes,
  faTrashAlt,
  faTrophy,
  faUtensilSpoon,
  faUtensils,
  faWeight,
  faWineBottle,
} from "@fortawesome/free-solid-svg-icons"

export default function buildIconLibrary() {
  library.add(
    faAddressCard,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAppleAlt,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faAtom,
    faBacon,
    faBeer,
    faBookOpen,
    faBox,
    faBreadSlice,
    faBullseye,
    faCalculator,
    faCalendar,
    faCaretDown,
    faCaretLeft,
    faCarrot,
    faChartLine,
    faCheckCircle,
    faChild,
    faClipboardList,
    faCog,
    faCookie,
    faCookieBite,
    faCopy,
    faDownload,
    faDrumstickBite,
    faEgg,
    faExclamationCircle,
    faExclamationTriangle,
    faFemale,
    faFistRaised,
    faFlagCheckered,
    faGlassWhiskey,
    faHamburger,
    faHotdog,
    faInfoCircle,
    faLeaf,
    faLemon,
    faLock,
    faLockOpen,
    faMale,
    faMobile,
    faPencilAlt,
    faPepperHot,
    faPizzaSlice,
    faPlus,
    faQuestionCircle,
    faServer,
    faSignOutAlt,
    faSort,
    faSpinner,
    faTasks,
    faTimes,
    faTrashAlt,
    faTrophy,
    faUtensilSpoon,
    faUtensils,
    faWeight,
    faWineBottle
  )
}
