export default function registerServiceWorker() {
  if ("serviceWorker" in navigator && process.env.NODE_ENV !== "development") {
    window.addEventListener("load", async () => {
      try {
        await navigator.serviceWorker.register("./service_worker.js")
      } catch (error) {
        // pass
      }
    })
  }
}
