import React, { useState } from "react"

import { capitalize } from "lodash"
import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import { Link } from "react-router-dom"
import * as yup from "yup"

import { api } from "api"
import Button from "components/button"
import Flex from "components/flex"
import Form from "components/form"
import Loading from "components/loading"
import { notify } from "notifications"

/*
 * afterSubmit: a function with signature "() => void" that handles stuff like reloading
 *              data after a food is created.
 * postServing: a function with signature "(food) => void" used for the "eat 1 serving"
 *              button shown to the user after creating a custom food. This varies based
 *              on the context since, e.g, in the diet page we want to be able to
 *              customize the eaten_on date and set animation events when we post a
 *              new serving.
 * postingServing: this is a loading state for the postServing function.
 * */
export default function CustomFoodModal({
  show,
  setShow,
  afterSubmit,
  postServing,
  postingServing,
}) {
  const [food, setFood] = useState({})
  const [loadingFood, setLoadingFood] = useState(false)

  function resetModalContents() {
    setFood({})
  }

  function closeModal() {
    setShow(false)
    resetModalContents()
  }

  async function getCreatedFood(idx) {
    if (!idx) {
      return
    }

    try {
      setLoadingFood(true)
      setFood((await api.get(`/api/v1/foods/${idx}`)).data)
    } catch (error) {
      notify.error(error)
    } finally {
      setLoadingFood(false)
    }
  }

  async function onSubmit(values) {
    let idx
    try {
      idx = (
        await api.post("/api/v1/foods", {
          name: values.name,
          ...(values.brand ? { brand: values.brand } : {}),
          amount: "1 dimensionless",
          nutrition: {
            calories: values.calories,
            protein: values.protein,
            carbs: values.carbs,
            sugar: values.sugar,
            fiber: values.fiber,
            fat: values.fat,
            saturated_fat: values.saturatedFat,
            trans_fat: values.transFat,
            sodium: values.sodium / 1000, // convert to grams
            cholesterol: values.cholesterol / 1000, // convert to grams
          },
        })
      ).data.idx
    } catch (error) {
      notify.error(error)
    } finally {
      await Promise.all([afterSubmit(), getCreatedFood(idx)])
    }
  }

  return (
    <Modal show={show} onHide={closeModal}>
      <Loading loading={loadingFood}>
        {[null, undefined].includes(food.idx) ? (
          <React.Fragment>
            <Modal.Header closeButton>
              <Modal.Title>Add a Custom Food</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FoodForm onSubmit={onSubmit} />
            </Modal.Body>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Modal.Header closeButton>
              <Modal.Title>&quot;{food.name || "Food"}&quot; Created</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PostSubmitOptions
                food={food}
                postServing={postServing}
                postingServing={postingServing}
                closeModal={closeModal}
                resetModalContents={resetModalContents}
              />
            </Modal.Body>
          </React.Fragment>
        )}
      </Loading>
    </Modal>
  )
}

function PostSubmitOptions({
  food,
  postServing: postServing_,
  postingServing,
  closeModal,
  resetModalContents,
}) {
  async function postServing() {
    await postServing_(food)
    resetModalContents()
  }

  return (
    <React.Fragment>
      <p>
        &quot;{capitalize(food.name || "This food")}&quot; will now show up in your
        search results so you can easily add it again whenever you need it.
      </p>

      <Flex column gap="0.5rem">
        <Button
          block
          onClick={postServing}
          loading={postingServing}
          icon="utensils"
          iconSide="left"
        >
          Eat 1 Serving
        </Button>
        <Button
          variant="outline-secondary"
          as={Link}
          to={`/foods/${food.idx}`}
          icon="arrow-right"
        >
          Go to food page
        </Button>
        <Button
          variant="outline-secondary"
          onClick={resetModalContents}
          iconSide="left"
          icon="arrow-left"
        >
          Add another food
        </Button>
        <Button
          icon="times"
          variant="outline-secondary"
          iconSide="left"
          block
          onClick={closeModal}
        >
          Close
        </Button>
      </Flex>
    </React.Fragment>
  )
}

function FoodForm({ onSubmit }) {
  return (
    <Form
      initialValues={{
        name: "",
        brand: "",
        calories: 0,
        protein: 0,
        carbs: 0,
        sugar: 0,
        fiber: 0,
        fat: 0,
        saturatedFat: 0,
        transFat: 0,
        sodium: 0,
        cholesterol: 0,
      }}
      validationSchema={{
        name: yup.string().required(),
        brand: yup.string(),
        calories: yup.number().required(),
        protein: yup.number().required(),
        carbs: yup.number().required(),
        sugar: yup.number().required(),
        fiber: yup.number().required(),
        fat: yup.number().required(),
        saturatedFat: yup.number().required(),
        transFat: yup.number().required(),
        sodium: yup.number().required(),
        cholesterol: yup.number().required(),
      }}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form.Body formik={formik}>
          <Row>
            <Form.Text
              id="custom-food-name"
              name="name"
              label="Name"
              placeholder='E.g. "Burrito"'
              formik={formik}
            />
          </Row>
          <Row>
            <Form.Text
              id="custom-food-brand"
              name="brand"
              label="Brand (optional)"
              placeholder='E.g. "RC Cola"'
              formik={formik}
            />
          </Row>
          <hr />
          <Row>
            <Form.Number
              id="custom-food-calories"
              name="calories"
              label="Calories"
              formik={formik}
              append="cal"
            />
          </Row>
          <Row>
            <Form.Number
              id="custom-food-fat"
              name="fat"
              label="Total fat"
              formik={formik}
              append="g"
            />
            <Form.Number
              id="custom-food-saturated-fat"
              name="saturatedFat"
              label="Saturated fat"
              formik={formik}
              append="g"
            />
            <Form.Number
              id="custom-food-trans-fat"
              name="transFat"
              label="Trans fat"
              formik={formik}
              append="g"
            />
          </Row>
          <Row>
            <Form.Number
              id="custom-food-cholesterol"
              name="cholesterol"
              label="Cholesterol"
              formik={formik}
              append="mg"
            />
            <Form.Number
              id="custom-food-sodium"
              name="sodium"
              label="Sodium"
              formik={formik}
              append="mg"
            />
          </Row>
          <Row>
            <Form.Number
              id="custom-food-carbs"
              name="carbs"
              label="Total carbs"
              formik={formik}
              append="g"
            />
            <Form.Number
              id="custom-food-sugar"
              name="sugar"
              label="Sugar"
              formik={formik}
              append="g"
            />
            <Form.Number
              id="custom-food-fiber"
              name="fiber"
              label="Fiber"
              formik={formik}
              append="g"
            />
          </Row>
          <Row>
            <Form.Number
              id="custom-food-protein"
              name="protein"
              label="Protein"
              formik={formik}
              append="g"
            />
          </Row>
          <Row>
            <Form.Button formik={formik} block>
              Submit
            </Form.Button>
          </Row>
        </Form.Body>
      )}
    </Form>
  )
}
