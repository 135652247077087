import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { join } from "lodash"
import Card from "react-bootstrap/Card"

export default function Seasonality({ food = {} }) {
  const { seasonality } = food
  if (!seasonality) {
    return null
  }

  const seasonalMonths = join(
    seasonality.map((month) => {
      const d = new Date()
      d.setMonth(month)
      return d.toLocaleString("default", { month: "long" })
    }),
    ", "
  )

  return (
    <Card>
      <Card.Body>
        <FontAwesomeIcon icon="leaf" /> This food is in season during the following
        months: {seasonalMonths}
      </Card.Body>
    </Card>
  )
}
