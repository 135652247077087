import React, { useState } from "react"

import { api } from "api"
import CalendarPopover from "components/calendar_popover"
import Day from "day"
import { notify } from "notifications"

import { useLastEvent } from "../../event_context"
import FooterButton from "./footer_button"

export default function CopyTo({ serving, refreshDietData }) {
  const [loading, setLoading] = useState(false)

  const lastEvent = useLastEvent()

  async function copyServing(newDate_) {
    const newDate = Day.fromDate(newDate_)
    let res
    try {
      setLoading(true)
      res = await api.post("/api/v1/servings", {
        serving_idx: serving.idx,
        eaten_on: newDate,
      })

      notify.success(`Copied serving to ${newDate.pretty()}`)
    } catch (error) {
      notify.error(error)
    } finally {
      if (res?.data?.idx) {
        lastEvent.setServingPosted(res.data.idx)
      } else {
        lastEvent.setNone()
      }
      setLoading(false)
    }

    await refreshDietData()
  }

  return (
    <CalendarPopover
      title="Copy to..."
      value={Day.fromISOString(serving.eaten_on).asDate()}
      onChange={copyServing}
      loading={loading}
    >
      <FooterButton icon="copy">Copy</FooterButton>
    </CalendarPopover>
  )
}
