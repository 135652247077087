import React, { useState } from "react"

import Row from "react-bootstrap/Row"
import * as yup from "yup"

import { api } from "api"
import Form from "components/form"
import Loading from "components/loading"
import { notify } from "notifications"

export default function CreateUserForm() {
  const [loading, setLoading] = useState(false)

  async function createUser({ email }) {
    setLoading(true)
    try {
      const user = (await api.post("/api/v1/users", { email })).data
      notify.success(`Created user ${user.idx}`)
    } catch (error) {
      notify.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Loading loading={loading}>
      <Form
        initialValues={{ email: "" }}
        validationSchema={{ email: yup.string().required("Email is required") }}
        onSubmit={createUser}
      >
        {(formik) => (
          <Form.Body formik={formik}>
            <Row>
              <Form.Email
                id="new-user-email"
                name="email"
                label="Email"
                formik={formik}
              />
            </Row>
            <Row>
              <Form.Button formik={formik}>Create user</Form.Button>
            </Row>
          </Form.Body>
        )}
      </Form>
    </Loading>
  )
}
