import React, { useState } from "react"

import Dropdown from "react-bootstrap/Dropdown"

import { api } from "api"
import Flex from "components/flex"
import Loading from "components/loading"
import Day from "day"
import { notify } from "notifications"
import ServingButton from "shared/serving_button"

import { useLastEvent } from "../event_context"
import { CustomFoodModalButton } from "./custom_food"

export default function DefaultMenu({
  closeMenu,
  suggestions,
  loadingSuggestions,
  dayOffset,
  refreshDietData,
}) {
  return (
    <React.Fragment>
      <Dropdown.Header>Custom Foods</Dropdown.Header>
      <Dropdown.Item as="span">
        <CustomFoodModalButton />
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>History</Dropdown.Header>
      <Loading loading={loadingSuggestions} debounce>
        {suggestions.length ? (
          suggestions.map((serving) => (
            <Dropdown.Item as="span" key={serving.idx}>
              <ServingSuggestion
                serving={serving}
                dayOffset={dayOffset}
                refreshDietData={refreshDietData}
                closeMenu={closeMenu}
              />
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item
            as="span"
            className="text-muted"
            style={{ textAlign: "center" }}
          >
            <i>
              You haven&apos;t logged any food yet. Foods you eat often will appear here
              later so you can log them quickly.
            </i>
          </Dropdown.Item>
        )}
      </Loading>
    </React.Fragment>
  )
}

function ServingSuggestion({ serving, dayOffset, refreshDietData, closeMenu }) {
  const [loading, setLoading] = useState(false)
  const lastEvent = useLastEvent()

  async function eatServing() {
    let res
    try {
      setLoading(true)
      res = await api.post("/api/v1/servings", {
        serving_idx: serving.idx,
        eaten_on: Day.fromOffset(dayOffset),
      })

      closeMenu()
    } catch (error) {
      notify.error(error)
    } finally {
      setLoading(false)
      if (res?.data?.idx) {
        lastEvent.setServingPosted(res.data.idx)
      } else {
        lastEvent.setNone()
      }
      await refreshDietData()
    }
  }

  return (
    <Flex align="baseline" justify="space-between">
      <span style={{ whiteSpace: "normal" }}>{serving.food}</span>
      <ServingButton loading={loading} onClick={eatServing} serving={serving} />
    </Flex>
  )
}
