import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Alert from "react-bootstrap/Alert"
import Collapse from "react-bootstrap/Collapse"
import Modal from "react-bootstrap/Modal"
import * as yup from "yup"

import { api } from "api"
import Button from "components/button"
import Flex from "components/flex"
import Form from "components/form"
import { notify } from "notifications"

function Weight({ formik }) {
  return (
    <Form.Number
      id="new-body-log-weight"
      append="lbs."
      formik={formik}
      name="weight"
      label="How much do you weigh"
    />
  )
}

function BodyFat({ formik, userEstimatesBodyFat }) {
  const [expand, setExpand] = useState(false)
  const bodyFatInput = (
    <Form.Number
      id="new-body-log-bf"
      append="%"
      name="bodyFat"
      formik={formik}
      label={!userEstimatesBodyFat ? "What is your body fat?" : undefined}
    />
  )

  if (userEstimatesBodyFat) {
    return (
      <React.Fragment>
        <Alert variant="warning" style={{ marginTop: "1rem" }}>
          <Flex justify="space-between" align="baseline">
            <Alert.Heading style={{ fontSize: "1rem" }}>
              <FontAwesomeIcon icon="info-circle" /> Body Fat...
            </Alert.Heading>
            <Button
              variant="outline-secondary"
              onClick={() => setExpand(!expand)}
              icon={expand ? "caret-down" : "caret-left"}
            />
          </Flex>
          <Collapse in={expand}>
            <div>
              <p>
                Your body fat is currently estimated from your height, weight, age, etc.
                Don&apos;t start tracking body fat directly unless you&apos;re able to
                measure your body fat consistently with calipers or a body fat scale.
                <b>
                  {" "}
                  If you&apos;re not sure you can do this, leave this field blank.{" "}
                </b>
                Otherwise, enter your body fat percentage below.
              </p>
              {bodyFatInput}
            </div>
          </Collapse>
        </Alert>
      </React.Fragment>
    )
  }

  return bodyFatInput
}

export default function Add({
  refreshData,
  latestBodyFat,
  latestWeight,
  userEstimatesBodyFat,
}) {
  const [showModal, setShowModal] = useState(false)

  async function onSubmit({ weight, bodyFat }, actions) {
    try {
      await api.post("/api/v1/body_logs", {
        weight,
        body_fat: bodyFat / 100,
      })

      actions.resetForm()
      setShowModal(false)
    } catch (error) {
      notify.error(error)
    } finally {
      await refreshData()
    }
  }

  function openModal(formik) {
    if (latestBodyFat) {
      formik.setFieldValue("bodyFat", latestBodyFat * 100)
    }
    if (latestWeight) {
      formik.setFieldValue("weight", latestWeight)
    }
    setShowModal(true)
  }

  return (
    <Form
      initialValues={{
        weight: "",
        bodyFat: "",
      }}
      validationSchema={{
        weight: yup.number().required(),
        bodyFat: yup.number(),
      }}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <React.Fragment>
          <Button onClick={() => openModal(formik)}>
            Add Log <FontAwesomeIcon icon="plus" />
          </Button>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Form.Body formik={formik}>
              <Modal.Header closeButton>
                <Modal.Title>Add body log</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Weight formik={formik} />
                <BodyFat formik={formik} userEstimatesBodyFat={userEstimatesBodyFat} />
              </Modal.Body>
              <Modal.Footer>
                <Form.Button formik={formik}>Submit</Form.Button>
              </Modal.Footer>
            </Form.Body>
          </Modal>
        </React.Fragment>
      )}
    </Form>
  )
}
