import React from "react"

import { join } from "lodash"

import Badge from "components/badge"
import Flex from "components/flex"
import Tooltip from "components/tooltip"

export default function FoodBadges({ food, compact }) {
  const idPrefix = `food-badges-${food.idx}`

  let atomicFoodBadge
  if (food.pipeline === "atomic_foods") {
    atomicFoodBadge = (
      <AtomicFoodBadge id={`${idPrefix}-atomic-badge`} food={food} compact={compact} />
    )
  }

  let recipeBadge
  if (![null, undefined].includes(food.recipe_idx)) {
    recipeBadge = (
      <RecipeBadge id={`${idPrefix}-recipe-badge`} food={food} compact={compact} />
    )
  }

  let seasonalityBadge
  if (food.seasonality) {
    seasonalityBadge = (
      <SeasonalityBadge
        id={`${idPrefix}-seasonality-badge`}
        food={food}
        compact={compact}
      />
    )
  }

  let customFoodBadge
  if (
    ![null, undefined].includes(food.owner_idx) &&
    [null, undefined].includes(food.recipe_idx)
  ) {
    customFoodBadge = (
      <CustomFoodBadge
        id={`${idPrefix}-custom-food-badge`}
        food={food}
        compact={compact}
      />
    )
  }

  if (
    [customFoodBadge, atomicFoodBadge, recipeBadge, seasonalityBadge].every(
      (badge) => !badge
    )
  ) {
    return null
  }

  return (
    <Flex gap="0.25rem">
      {atomicFoodBadge}
      {seasonalityBadge}
      {recipeBadge}
      {customFoodBadge}
    </Flex>
  )
}

function FoodBadge({ id, tooltip, text, icon, compact, ...rest }) {
  return (
    <Tooltip id={id} tooltip={tooltip} placement="auto">
      <Badge icon={icon} {...rest}>
        {compact ? null : text}
      </Badge>
    </Tooltip>
  )
}

function AtomicFoodBadge({ id, food, compact }) {
  if (food.pipeline !== "atomic_foods") {
    return null
  }

  return (
    <FoodBadge
      id={id}
      text="Atomic Food"
      tooltip={
        "This is an atomic food, meaning, it's nutritional value is the" +
        " same, regardless of who made it or what brand is on the package."
      }
      icon="atom"
      bg="info"
      compact={compact}
    />
  )
}

function RecipeBadge({ id, food, compact }) {
  if ([null, undefined].includes(food.recipe_idx)) {
    return null
  }

  return (
    <FoodBadge
      id={id}
      text="Recipe"
      tooltip="This is one of your recipes"
      icon="book-open"
      bg="secondary"
      compact={compact}
    />
  )
}

function CustomFoodBadge({ id, food, compact }) {
  if (
    [null, undefined].includes(food.owner_idx) ||
    ![null, undefined].includes(food.recipe_idx)
  ) {
    return null
  }

  return (
    <FoodBadge
      id={id}
      text="Custom Food"
      tooltip="This is a custom food you created"
      icon="apple-alt"
      bg="secondary"
      compact={compact}
    />
  )
}

function SeasonalityBadge({ id, food, compact }) {
  if (!food.seasonality) {
    return null
  }

  const seasonalMonths = join(
    food.seasonality.map((month) => {
      const d = new Date()
      d.setMonth(month)
      return d.toLocaleString("default", { month: "long" })
    }),
    ", "
  )

  const seasonalityNotice = `This food is in season ${seasonalMonths}`

  if ((food.seasonality || []).includes(new Date().getMonth())) {
    return (
      <FoodBadge
        id={id}
        text="In Season"
        tooltip={`${
          "This food is currently in season so it'll cost less at the grocery " +
          "store and taste better. "
        }${seasonalityNotice}`}
        icon="leaf"
        bg="success"
        compact={compact}
      />
    )
  }

  return (
    <FoodBadge
      id={id}
      text="Out of Season"
      tooltip={`${
        "This food is out of season right now so it'll cost more at the grocery " +
        "store and taste worse. "
      }${seasonalityNotice}`}
      icon="leaf"
      bg="warning"
      compact={compact}
    />
  )
}
