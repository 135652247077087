import { useState, useEffect } from "react"

import Cookies from "js-cookie"

import { notify } from "notifications"

import { serializeRequestBody, serializeQueryParams } from "./serializers"

export const MAX_PAGE_SIZE = 100

export class ApiError {
  constructor(response, error) {
    this.type = error.type
    this.code = error.code || response.status
    this.message = error.msg
    this.info = error.info
    this.response = response
  }

  toString() {
    const str = `${this.code} ${this.type}`
    return this.message ? str.concat(`: ${this.message}`) : str
  }
}

export function buildQueryString(query) {
  return serializeQueryParams(query)
}

export class Api {
  xsrfToken = null

  async fetch(method, path, { body, query, ...params }) {
    this.xsrfToken = !this.xsrfToken ? Cookies.get("_xsrf") : this.xsrfToken

    const response = await fetch(`${path}${buildQueryString(query)}`, {
      ...params,
      method,
      headers: this.xsrfToken ? { "X-XSRFToken": this.xsrfToken } : {},
      ...(body ? { body: serializeRequestBody(body) } : {}),
    })
    const responseBody = await response.json()

    if (responseBody.err) {
      throw new ApiError(response, responseBody.err)
    }

    return responseBody
  }

  get = (url, query, params) => this.fetch("GET", url, { query, ...params })

  post = (url, body, params) => this.fetch("POST", url, { body, ...params })

  put = (url, body, params) => this.fetch("PUT", url, { body, ...params })

  patch = (url, body, params) => this.fetch("PATCH", url, { body, ...params })

  delete = (url, params) => this.fetch("DELETE", url, { ...params })
}

export const api = new Api()

async function updateData(url, queryString, onError, setResult, setLoading) {
  setLoading(true)
  try {
    setResult(await api.get(`${url}${queryString}`))
  } catch (error) {
    onError(error)
  } finally {
    setLoading(false)
  }
}

function useData(url, query, onError = notify.error) {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState({})

  const queryString = buildQueryString(query)

  useEffect(() => {
    async function wrapper() {
      await updateData(url, queryString, onError, setResult, setLoading)
    }

    wrapper()
  }, [url, queryString, onError])

  return {
    data: result.data,
    cursor: result.cur || {},
    loading,
    refresh: () => updateData(url, queryString, onError, setResult, setLoading),
  }
}

export function useGet(url, query, onError = notify.error) {
  const { data, loading, refresh } = useData(url, query, onError)

  return { ...(data || {}), loading, refresh }
}

export function usePage(url, query, onError = notify.error) {
  const { data, cursor, loading, refresh } = useData(url, query, onError)

  return { page: data || [], loading, cursor, refresh }
}
