import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { capitalize } from "lodash"

import BaseGrid from "components/grid"
import Day from "day"
import format from "format"
import { lightColor } from "index.scss"

const VERTICAL_SEPARATOR_PADDING = "0.5rem"
const VERTICAL_SEPARATOR_STYLE = {
  borderLeft: `2px solid ${lightColor}`,
  paddingLeft: VERTICAL_SEPARATOR_PADDING,
  marginLeft: VERTICAL_SEPARATOR_PADDING,
}

function Grid({ children }) {
  return (
    <BaseGrid columns="[goals] min-content [label] min-content [progress] auto">
      {children}
    </BaseGrid>
  )
}

Grid.Header = ({ dayOffset }) => (
  <React.Fragment>
    <BaseGrid.Child column="goals / span 2">
      <div style={{ position: "absolute" }}>
        <FontAwesomeIcon icon="flag-checkered" />{" "}
        {capitalize(Day.fromOffset(dayOffset).pretty())}&apos;s Goals
      </div>
    </BaseGrid.Child>
    <BaseGrid.Child
      column="progress / span 1"
      className="text-muted"
      justify="right"
      style={{ paddingBottom: "0.75rem" }}
    >
      <FontAwesomeIcon icon="chart-line" /> Progress
    </BaseGrid.Child>
  </React.Fragment>
)

Grid.Goals = ({ min, max, isMacronutrient, label }) => {
  if ([null, undefined].includes(min) || [null, undefined].includes(max)) {
    return null
  }

  const target = `${format.number(min)}-${
    isMacronutrient ? format.macronutrientMass(max) : format.number(max)
  }`

  return (
    <React.Fragment>
      <BaseGrid.Child
        column="goals / span 1"
        justify="right"
        style={{
          whiteSpace: "nowrap",
          marginRight: "0.4rem",
        }}
      >
        <small className="text-muted">
          <b>{target}</b>
        </small>
      </BaseGrid.Child>
      <BaseGrid.Child column="label / span 1" style={{ whiteSpace: "nowrap" }}>
        <small className="text-muted">{label}</small>
      </BaseGrid.Child>
    </React.Fragment>
  )
}

Grid.Progress = ({ children }) => (
  <BaseGrid.Child
    column="progress / span 1"
    style={{ paddingTop: "5px", ...VERTICAL_SEPARATOR_STYLE }}
  >
    {children}
  </BaseGrid.Child>
)

Grid.CallToAction = ({ cta }) => {
  if (!cta) {
    return null
  }

  return (
    <BaseGrid.Child
      column="progress / span 1"
      className="text-danger"
      style={{ paddingBottom: "0.5rem", ...VERTICAL_SEPARATOR_STYLE }}
    >
      <small>
        <FontAwesomeIcon icon="exclamation-triangle" /> {cta}
      </small>
    </BaseGrid.Child>
  )
}

export default Grid
