import React from "react"

import Spinner from "react-bootstrap/Spinner"

import Flex from "components/flex"
import useDebounce from "hooks/debounce"

const DEBOUNCE_MS = 250

export default function Loading({
  children,
  loading: loading_,
  inline,
  variant,
  debounce,
  style,
}) {
  const loading = useDebounce(loading_, debounce ? DEBOUNCE_MS : 0)

  return (
    <div style={{ position: "relative", width: "100%", ...style }}>
      {loading && (
        <Flex
          justify="center"
          align="center"
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            ...(inline ? { textAlign: "center" } : {}),
            background: "radial-gradient(rgba(0, 0, 0, 0.05), transparent 220%)",
            zIndex: 5,
          }}
        >
          <Spinner animation="border" variant={variant} size={inline ? "sm" : "lg"} />
        </Flex>
      )}
      {children}
    </div>
  )
}
