import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BootstrapBadge from "react-bootstrap/Badge"

const Badge = React.forwardRef(({ id, children, icon, iconSide, ...rest }, ref) => {
  if (!["left", "right", undefined].includes(iconSide)) {
    // eslint-disable-next-line no-console
    console.warn(
      "Bad value for iconSide passed to button, expected left, right, or undefined"
    )
  }

  return (
    <BootstrapBadge ref={ref} {...rest}>
      {icon && iconSide === "left" && (
        <FontAwesomeIcon icon={icon} style={{ marginRight: children ? "0.5rem" : 0 }} />
      )}
      {children}
      {icon && (iconSide === "right" || iconSide === undefined) && (
        <FontAwesomeIcon icon={icon} style={{ marginLeft: children ? "0.5rem" : 0 }} />
      )}
    </BootstrapBadge>
  )
})

export default Badge
