import React from "react"

import Meter from "components/meter"
import { METRICS } from "constant"
import format from "format"
import useAuth from "hooks/auth"

import Grid from "./grid"

export default function ProteinMeter({ metrics, scale }) {
  const auth = useAuth()

  if (!auth.user.wants_big_muscles && !auth.user.wants_to_eat_clean) {
    return null
  }

  const value = metrics.protein || 0

  const minTarget = metrics.minimum_protein_target
  const maxTarget = metrics.maximum_protein_target

  let variant = "warning"
  if (metrics.protein_target_failed) {
    variant = "danger"
  } else if (metrics.protein_on_target) {
    variant = "success"
  }

  let cta
  if (
    metrics.diet_on_target ||
    !metrics.protein_target_actionable ||
    metrics.protein_target_failed
  ) {
    cta = undefined
  } else if (
    metrics.protein_target_almost_over &&
    metrics.protein_target_room_to_run > 0
  ) {
    cta = `Just ${format.macronutrientMass(
      metrics.protein_target_room_to_run
    )} away from going over on protein!`
  } else if (metrics.protein_target_lagging && metrics.protein_target_defecit > 0) {
    cta = `Behind on protein still ${format.macronutrientMass(
      metrics.protein_target_defecit
    )} to go`
  }

  return (
    <React.Fragment>
      <Grid.Goals min={minTarget} max={maxTarget} label="Protein" isMacronutrient />
      <Grid.Progress>
        <Meter
          text={`${format.macronutrientMass(value)} ${METRICS.protein.label}`}
          value={value}
          maxValue={scale}
          minTarget={minTarget}
          maxTarget={maxTarget}
          actionable={metrics.protein_target_actionable}
          variant={variant}
          failed={metrics.protein_target_failed}
          border={cta ? "danger" : undefined}
        />
      </Grid.Progress>
      <Grid.CallToAction cta={cta} />
    </React.Fragment>
  )
}
