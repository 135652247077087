import React, { useState } from "react"

import { useHistory } from "react-router-dom"

import { api } from "api"
import Button from "components/button"
import { notify } from "notifications"

export default function Delete({ food }) {
  const history = useHistory()

  const [deleting, setDeleting] = useState(false)

  async function deleteFood() {
    let deleted = false
    try {
      setDeleting(true)
      await api.delete(`/api/v1/foods/${food.idx}`)
      deleted = true
    } catch (error) {
      notify.error(error)
    } finally {
      setDeleting(false)
    }

    if (deleted) {
      notify.success(`Successfully deleted ${food.name || "food"}`)
      history.push("/foods")
    }
  }

  return (
    <Button block variant="danger" onClick={deleteFood} loading={deleting}>
      Delete {food.name || "this food"}
    </Button>
  )
}
