import React, { useEffect, useState } from "react"

import Dropdown from "react-bootstrap/Dropdown"

import { api } from "api"
import Flex from "components/flex"
import Loading from "components/loading"
import { notify } from "notifications"
import ServingButton from "shared/serving_button"

import { CustomIngredientModalButton } from "./custom_ingredient"

async function getSuggestions(recipeIdx, setIngredients, setLoading) {
  try {
    setLoading(true)
    setIngredients(
      (await api.get("/api/v1/ingredients", { exclude_recipe_idx: recipeIdx })).data
    )
  } catch (error) {
    notify.error(error)
  } finally {
    setLoading(false)
  }
}

function Suggestion({ ingredient, recipeIdx, refreshData, closeMenu }) {
  const [adding, setAdding] = useState(false)

  async function addIngredient() {
    try {
      setAdding(true)
      await api.post("/api/v1/ingredients", {
        ingredient_idx: ingredient.idx,
        recipe_idx: recipeIdx,
      })

      closeMenu()
    } catch (error) {
      notify.error(error)
    } finally {
      setAdding(false)
      await refreshData()
    }
  }

  return (
    <Dropdown.Item as="span">
      <Flex align="center" justify="space-between">
        <span style={{ whiteSpace: "normal" }}>{ingredient.name}</span>
        <ServingButton
          loading={adding}
          onClick={addIngredient}
          ingredient={ingredient}
        />
      </Flex>
    </Dropdown.Item>
  )
}

export default function DefaultMenu({ recipeIdx, refreshData, closeMenu }) {
  const [ingredients, setIngredients] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function wrapper() {
      await getSuggestions(recipeIdx, setIngredients, setLoading)
    }

    wrapper()
  }, [recipeIdx, setIngredients, setLoading])

  return (
    <React.Fragment>
      <Dropdown.Header>Custom Servings</Dropdown.Header>
      <Dropdown.Item as="span">
        <CustomIngredientModalButton recipeIdx={recipeIdx} refreshData={refreshData} />
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Header>Suggested Ingredients</Dropdown.Header>
      <Loading loading={loading} debounce>
        {ingredients.length ? (
          ingredients.map((ingredient) => (
            <Suggestion
              key={ingredient.idx}
              ingredient={ingredient}
              refreshData={refreshData}
              recipeIdx={recipeIdx}
              closeMenu={closeMenu}
            />
          ))
        ) : (
          <Dropdown.Item as="span" style={{ whiteSpace: "normal" }}>
            <i className="text-muted">
              You haven&apos;t added an ingredient to a recipe yet. Ingredients you use
              frequently will appear here so you can quickly add them to recipes in the
              future.
            </i>
          </Dropdown.Item>
        )}
      </Loading>
    </React.Fragment>
  )
}
