const MILLISECONDS_PER_MINUTE = 60000
const MILLISECONDS_PER_DAY = 86400000

function _getLocalTimezoneOffset() {
  return new Date().getTimezoneOffset() * MILLISECONDS_PER_MINUTE
}

export default class Day {
  constructor(year, month, day) {
    if (year === undefined && month === undefined && day === undefined) {
      const now = new Date()

      // these properties set the day month and year for the date in local time
      this.year = now.getFullYear()
      this.month = now.getMonth() + 1 // only month is zero indexed for some reason
      this.day = now.getDate()
    } else {
      this.year = year
      this.month = month
      this.day = day

      if (Number.isNaN(new Date(this.toISOString()).getTime())) {
        throw new Error(`Invalid date ${this.year}-${this.month}-${this.day}`)
      }
    }
  }

  static fromISOString(isoString) {
    if (Number.isNaN(new Date(isoString).getTime())) {
      throw new Error(`Invalid ISO string ${isoString}`)
    }

    return new Day(...isoString.split("-").map((num) => parseInt(num, 10)))
  }

  static fromDate(date) {
    return new Day(date.getFullYear(), date.getMonth() + 1, date.getDate())
  }

  static fromOffset(offset) {
    return new Day().offset(offset)
  }

  static yesterday() {
    return this.fromOffset(-1)
  }

  static today() {
    return new Day()
  }

  static tomorrow() {
    return this.fromOffset(1)
  }

  asDate() {
    // return a date object where, in the local time, the year month and day agree with
    // this.year, this.month, and this.day
    return new Date(
      new Date(this.year, this.month - 1, this.day).getTime() +
        _getLocalTimezoneOffset()
    )
  }

  toISOString() {
    return [
      String(this.year),
      String(this.month).padStart(2, "0"),
      String(this.day).padStart(2, "0"),
    ].join("-")
  }

  toOffset() {
    return (
      (this.asDate().getTime() - Day.today().asDate().getTime()) / MILLISECONDS_PER_DAY
    )
  }

  offset(days) {
    const offsetDate = new Date(this.asDate().getTime() + MILLISECONDS_PER_DAY * days)

    return new Day(
      offsetDate.getFullYear(),
      offsetDate.getMonth() + 1,
      offsetDate.getDate()
    )
  }

  next() {
    return this.offset(1)
  }

  previous() {
    return this.offset(-1)
  }

  isSameDay(day) {
    return this.year === day.year && this.month === day.month && this.day === day.day
  }

  pretty() {
    const today = new Day()
    if (this.isSameDay(today)) {
      return "today"
    }
    if (this.isSameDay(today.previous())) {
      return "yesterday"
    }
    if (this.isSameDay(today.next())) {
      return "tomorrow"
    }

    return this.asDate().toLocaleDateString(undefined, { dateStyle: "medium" })
  }
}
