import React, { useState } from "react"

import Cookies from "js-cookie"
import Alert from "react-bootstrap/Alert"

import Button from "components/button"
import Paywall from "components/paywall"
import Day from "day"
import useAuth from "hooks/auth"

const SNOOZE_COOKIE_NAME = "snooze-trial-expiration"
const SNOOZE_DURATION_DAYS = 1
const WARNING_PERIOD_DELTA_MS = 604800000 // 1 week

export default function TrialExpirationWarning() {
  const auth = useAuth()
  const [snoozed, setSnoozed] = useState(!!Cookies.get(SNOOZE_COOKIE_NAME))

  const now = new Date().getTime()
  const subscriptionExpiresAt = new Date(auth.user.subscription_expires_at).getTime()

  if (
    snoozed ||
    !auth.user ||
    !auth.user.idx ||
    subscriptionExpiresAt < now ||
    subscriptionExpiresAt - now >= WARNING_PERIOD_DELTA_MS
  ) {
    return null
  }

  function snooze() {
    Cookies.set(SNOOZE_COOKIE_NAME, 1, { expires: SNOOZE_DURATION_DAYS })
    setSnoozed(Cookies.get(SNOOZE_COOKIE_NAME))
  }

  return (
    <Alert dismissible onClose={snooze} variant="warning">
      <Alert.Heading>
        Your free trial expires{" "}
        {Day.fromDate(new Date(auth.user.subscription_expires_at)).pretty()}
      </Alert.Heading>

      <p>
        <b>Please consider subscribing before then.</b>
      </p>

      <Paywall.Options />

      <div style={{ marginTop: "1rem" }}>
        <Button size="sm" variant="outline-secondary" block onClick={snooze}>
          Remind me later...
        </Button>
      </div>

      <Paywall.Epitaph />
    </Alert>
  )
}
