import React from "react"

import Dropdown from "react-bootstrap/Dropdown"

import { CustomFoodModalButton } from "./custom_food"

export default function Footer() {
  return (
    <React.Fragment>
      <Dropdown.Header>Can&apos;t find what you&apos;re looking for?</Dropdown.Header>
      <Dropdown.Item as="span">
        <CustomFoodModalButton />
      </Dropdown.Item>
    </React.Fragment>
  )
}
