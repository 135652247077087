import React from "react"

function Grid({ children, style, columns }) {
  return (
    <div
      style={{
        gridTemplateColumns: columns,
        display: "grid",
        ...style,
      }}
    >
      {children}
    </div>
  )
}

Grid.Child = ({ children, style, className, column, justify, align }) => {
  return (
    <div
      className={className}
      style={{
        gridColumn: column,
        ...(justify ? { justifySelf: justify } : {}),
        ...(align ? { alignSelf: align } : {}),
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default Grid
