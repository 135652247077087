import React, { useContext } from "react"

export const EventContext = React.createContext()

export const EVENT_TYPES = {
  DATE_CHANGE_BACK: "DATE_CHANGE_BACK",
  DATE_CHANGE_FORWARD: "DATE_CHANGE_FORWARD",
  SERVING_POSTED: "SERVING_POSTED",
}

export function useLastEvent() {
  // A context for tracking the users actions on this page (e.g. changing the
  // date, moving a serving, etc.) so we can display helpful animations.
  return useContext(EventContext)
}
