import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Toast from "react-bootstrap/Toast"
import { toast } from "react-toastify"

function NotificationAlert({ icon, subject, variant, children }) {
  return (
    <Toast>
      <Toast.Header className={`text-${variant}`}>
        <FontAwesomeIcon icon={icon} style={{ marginRight: "0.5rem" }} />
        <strong className="me-auto">{subject}</strong>
      </Toast.Header>
      <Toast.Body>{children}</Toast.Body>
    </Toast>
  )
}

export class NotificationDispatcher {
  constructor() {
    toast.configure({
      position: "top-center",
      hideProgressBar: true,
      closeButton: false,
      autoClose: 6000,
    })
  }

  info(message) {  // eslint-disable-line
    toast(() => (
      <NotificationAlert subject="Info" icon="info-circle" variant="info">
        {message}
      </NotificationAlert>
    ))
  }

  success(message) { // eslint-disable-line
    toast(() => (
      <NotificationAlert subject="Success" icon="check-circle" variant="success">
        {message}
      </NotificationAlert>
    ))
  }

  error(error) { //eslint-disable-line
    toast(() => (
      <NotificationAlert
        subject={`${error.code} ${error.type}`}
        icon="exclamation-circle"
        variant="danger"
      >
        {error.message || error.type}
      </NotificationAlert>
    ))
  }
}

export const notify = new NotificationDispatcher()
