import React from "react"

import BootstrapDropdown from "react-bootstrap/Dropdown"

import Flex from "components/flex"
import Loading from "components/loading"

const Toggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    type="button"
    className="form-control"
    style={{ textAlign: "start" }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <Flex>
      {children}
      <span className="dropdown-custom-caret" />
      <span style={{ float: "right", width: "0.75rem" }} />
    </Flex>
  </button>
))

export default function Dropdown({ id, value, options, onSelect, loading }) {
  const selectedValue = options.find((opt) => opt.value === value)

  return (
    <BootstrapDropdown>
      <BootstrapDropdown.Toggle as={Toggle} id={id}>
        <Loading loading={loading} inline>
          {selectedValue && selectedValue.label}
        </Loading>
      </BootstrapDropdown.Toggle>

      <BootstrapDropdown.Menu role="menu" renderOnMount>
        {options.map((opt) => (
          <BootstrapDropdown.Item
            key={opt.value}
            onClick={opt.onSelect || (() => onSelect(opt.value))}
            onKeyPress={(e) => {
              if (e.key === " " || e.key === "Enter") {
                e.preventDefault()
                e.target.click()
              }
            }}
            tabIndex={0}
            role="option"
          >
            {opt.label}
          </BootstrapDropdown.Item>
        ))}
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  )
}
