import React from "react"

import Alert from "react-bootstrap/Alert"
import Card from "react-bootstrap/Card"
import Toast from "react-bootstrap/Toast"

import Button from "components/button"
import Flex from "components/flex"

const VARIANTS = [
  "primary",
  "secondary",
  "success",
  "info",
  "warning",
  "danger",
  "light",
  "dark",
]

export default function ThemePage() {
  return (
    <React.Fragment>
      <h1>This is a utility page for testing the app theme</h1>
      <Flex wrap>
        {VARIANTS.map((variant) => (
          <span key={variant} className={`text-${variant}`}>
            {variant}
          </span>
        ))}
        {VARIANTS.map((variant) => (
          <Button key={variant} variant={variant}>
            {variant}
          </Button>
        ))}
        {VARIANTS.map((variant) => (
          <Button key={variant} variant={`outline-${variant}`}>
            {variant}
          </Button>
        ))}
        {VARIANTS.map((variant) => (
          <Card key={variant} bg={variant}>
            <Card.Header>{variant}</Card.Header>
            <Card.Body>
              <Card.Title>{variant}</Card.Title>
              Lorem ipsum...
            </Card.Body>
            <Card.Footer>Lorem ipsum...</Card.Footer>
          </Card>
        ))}
        {VARIANTS.map((variant) => (
          <Alert key={variant} variant={variant} dismissible>
            <Alert.Heading>{variant}</Alert.Heading>
            <p>Lorem ipsum...</p>
          </Alert>
        ))}
        {VARIANTS.map((variant) => (
          <Toast key={variant}>
            <Toast.Header className={`text-${variant}`}>
              <strong className="mr-auto">{variant}</strong>
            </Toast.Header>
            <Toast.Body>Lorem ipsum</Toast.Body>
          </Toast>
        ))}
      </Flex>
    </React.Fragment>
  )
}
