import { isFiniteNumber } from "./utils"

export default function getStep(number) {
  // Make sure number is a reasonable number, not infinite or NaN.
  if (!number || !isFiniteNumber(number)) {
    return 1
  }

  const numberString = `${number}`
  // if number contains scientific notation it's too big or small for the step size
  // to matter.
  if (numberString.includes("e")) {
    return 1
  }

  const [preDecimal, postDecimal] = numberString.split(".", 2)

  let step
  if (postDecimal) {
    postDecimal.split("").forEach((digit, index) => {
      if (digit !== "0") {
        step = parseFloat(`.${"0".repeat(index)}1`)
      }
    })

    if (step) {
      return step
    }
  }

  step = 1
  preDecimal
    .split("")
    .reverse()
    .forEach((digit, index) => {
      if (digit === "0") {
        step = parseInt(`1${"0".repeat(index + 1)}`, 10)
      }
    })

  return step
}
