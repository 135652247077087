import React, { useState } from "react"

import { api } from "api"
import Dropdown from "components/dropdown"
import Editable from "components/editable"
import Tooltip from "components/tooltip"
import {
  PAL_OPTIONS,
  GENDER_OPTIONS,
  CALORIC_PACE_OPTIONS,
  NOT_SET_OPTION,
  YES_NO_OPTIONS,
} from "constant"
import { notify } from "notifications"
import AgeAlert from "shared/age_alert"

import { Setting } from "./base"

function getPatchingFunction({ field, auth, setLoading }) {
  return async function patchingFunction(value) {
    try {
      setLoading(true)
      await api.patch(`/api/v1/users/${auth.user.idx}`, { [field]: value })
    } catch (error) {
      notify.error(error)
    } finally {
      try {
        await auth.refreshUser()
      } catch (error) {
        notify.error(error)
      } finally {
        setLoading(false)
      }
    }
  }
}

export function PhysicalActivityLevel({ auth }) {
  const [loading, setLoading] = useState(false)
  return (
    <Setting>
      <Setting.Title>How active are you?</Setting.Title>
      <Dropdown
        value={auth.user.pal || ""}
        loading={loading}
        onSelect={(val) =>
          getPatchingFunction({
            field: "pal",
            auth,
            setLoading,
          })(val || null)
        }
        options={[NOT_SET_OPTION, ...PAL_OPTIONS]}
      />
    </Setting>
  )
}
export function Birthday({ auth }) {
  const [loading, setLoading] = useState(false)

  return (
    <Setting>
      <Setting.Title>When&apos;s your birthday?</Setting.Title>
      <AgeAlert user={auth.user} />
      <Editable.Date
        initialValue={auth.user.birthday}
        loading={loading}
        onCommit={(val) =>
          getPatchingFunction({
            field: "birthday",
            auth,
            setLoading,
          })(val || null)
        }
        style={{ width: "100%" }}
      />
    </Setting>
  )
}

export function Height({ auth }) {
  const [loading, setLoading] = useState(false)

  return (
    <Setting>
      <Setting.Title>How tall are you (in inches)?</Setting.Title>
      <Editable.Number
        initialValue={auth.user.height}
        loading={loading}
        onCommit={(val) =>
          getPatchingFunction({
            field: "height",
            auth,
            setLoading,
          })(val || null)
        }
        style={{ width: "100%" }}
        units="inches"
      />
    </Setting>
  )
}

export function Gender({ auth }) {
  const [loading, setLoading] = useState(false)

  return (
    <Setting>
      <Setting.Title>What&apos;s your gender?</Setting.Title>
      <Dropdown
        value={auth.user.gender || ""}
        loading={loading}
        onSelect={(val) =>
          getPatchingFunction({
            field: "gender",
            auth,
            setLoading,
          })(val || null)
        }
        options={[NOT_SET_OPTION, ...GENDER_OPTIONS]}
      />
    </Setting>
  )
}

export function GoalWeight({ auth }) {
  const [loading, setLoading] = useState(false)

  return (
    <Setting>
      <Setting.Title>How much do you want to weigh (in pounds)?</Setting.Title>
      <Editable.Number
        initialValue={auth.user.goal_weight}
        loading={loading}
        units="lbs"
        style={{ width: "100%" }}
        onCommit={(val) =>
          getPatchingFunction({
            field: "goal_weight",
            auth,
            setLoading,
          })(val || null)
        }
      />
    </Setting>
  )
}

export function CaloricPace({ auth }) {
  const [loading, setLoading] = useState(false)

  return (
    <Setting>
      <Setting.Title>How fast do you want to gain / lose weight?</Setting.Title>
      <Dropdown
        value={auth.user.caloric_pace || ""}
        loading={loading}
        onSelect={(val) =>
          getPatchingFunction({
            field: "caloric_pace",
            auth,
            setLoading,
          })(val || null)
        }
        options={[NOT_SET_OPTION, ...CALORIC_PACE_OPTIONS]}
      />
    </Setting>
  )
}

export function BuildMass({ auth }) {
  const [loading, setLoading] = useState(false)

  return (
    <Setting>
      <Setting.Title>Do you want to gain mass and / or strength?</Setting.Title>
      <Dropdown
        value={JSON.stringify(auth.user.wants_big_muscles)}
        loading={loading}
        onSelect={(value) =>
          getPatchingFunction({
            field: "wants_big_muscles",
            auth,
            setLoading,
          })(JSON.parse(value || null))
        }
        options={YES_NO_OPTIONS}
      />
    </Setting>
  )
}

export function InduceKetosis({ auth }) {
  const [loading, setLoading] = useState(false)

  return (
    <Setting>
      <Setting.Title>
        Do you want to do keto?
        <Tooltip.Info id="keto-setting-info-tooltip">
          Keto is an extremely low-carb diet many enthusiasts swear by. Don&apos;t
          choose &quot;yes&ldquo; here unless you&apos;re already familiar with it.
        </Tooltip.Info>
      </Setting.Title>
      <Dropdown
        loading={loading}
        value={JSON.stringify(auth.user.wants_to_induce_ketosis)}
        onSelect={(value) =>
          getPatchingFunction({
            field: "wants_to_induce_ketosis",
            auth,
            setLoading,
          })(JSON.parse(value || null))
        }
        options={YES_NO_OPTIONS}
      />
    </Setting>
  )
}

export function EatClean({ auth }) {
  const [loading, setLoading] = useState(false)

  return (
    <Setting>
      <Setting.Title>
        Do you want to eat clean?
        <Tooltip.Info id="eat-clean-setting-info-tooltip">
          By default, our recommended diet plans aim to minimize long term risk of
          disease and other negative health outcomes. While we don&apos;t recommend it,
          these constraints can be removed if you want to &quot;dirty bulk&ldquo; or
          otherwise maintain an unconventional diet for whatever reason.
        </Tooltip.Info>
      </Setting.Title>
      <Dropdown
        loading={loading}
        value={JSON.stringify(auth.user.wants_to_eat_clean)}
        onSelect={(value) =>
          getPatchingFunction({
            field: "wants_to_eat_clean",
            auth,
            setLoading,
          })(JSON.parse(value || null))
        }
        options={YES_NO_OPTIONS}
      />
    </Setting>
  )
}
