import React, { useState } from "react"

import { api } from "api"
import Button from "components/button"
import Dropdown from "components/dropdown"
import Editable from "components/editable"
import Field from "components/field"
import Flex from "components/flex"
import {
  PAL_OPTIONS,
  YES_NO_OPTIONS,
  NOT_SET_OPTION,
  GENDER_OPTIONS,
  CALORIC_PACE_OPTIONS,
} from "constant"
import Day from "day"
import { notify } from "notifications"
import AgeAlert from "shared/age_alert"

function getPatchingFunction({ field, auth, setLoading, refreshMetrics }) {
  return async function patchingFunction(value) {
    try {
      setLoading(true)
      await api.patch(`/api/v1/users/${auth.user.idx}`, { [field]: value })
    } catch (error) {
      notify.error(error)
    } finally {
      try {
        await auth.refreshUser()
      } catch (error) {
        notify.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (refreshMetrics !== undefined) {
      await refreshMetrics()
    }
  }
}

export function GoalWeight({ auth }) {
  const [loading, setLoading] = useState(false)

  return (
    <Field label="How much do you want to weigh?" complete={!!auth.user.goal_weight}>
      <Editable.Number
        loading={loading}
        initialValue={auth.user.goal_weight || ""}
        onCommit={getPatchingFunction({ field: "goal_weight", auth, setLoading })}
        style={{ width: "100%" }}
        units="lbs."
      />
    </Field>
  )
}

export function CaloricPace({ auth }) {
  const [loading, setLoading] = useState(false)

  async function patch(value) {
    return getPatchingFunction({ field: "caloric_pace", auth, setLoading })(
      value || null
    )
  }

  return (
    <Field
      label="How fast do you want to gain or lose weight?"
      complete={!!auth.user.caloric_pace}
      info={`If you want to maintain your current weight, choose "Slow and steady."`}
    >
      <Dropdown
        value={auth.user.caloric_pace || ""}
        loading={loading}
        onSelect={patch}
        options={[NOT_SET_OPTION, ...CALORIC_PACE_OPTIONS]}
      />
    </Field>
  )
}

export function BuildMass({ auth }) {
  const [loading, setLoading] = useState(false)

  async function patch(value_) {
    let value = value_
    if (value_ === "true") {
      value = true
    } else if (value === "false") {
      value = false
    }

    return getPatchingFunction({
      field: "wants_big_muscles",
      auth,
      setLoading,
    })(value)
  }

  return (
    <Field label="Do you want to build mass and / or strength?" complete>
      <Dropdown
        value={auth.user.wants_big_muscles.toString()}
        loading={loading}
        onSelect={patch}
        options={YES_NO_OPTIONS}
      />
    </Field>
  )
}

export function InduceKetosis({ auth }) {
  const [loading, setLoading] = useState(false)

  async function patch(value_) {
    let value = value_
    if (value_ === "true") {
      value = true
    } else if (value === "false") {
      value = false
    }

    return getPatchingFunction({
      field: "wants_to_induce_ketosis",
      auth,
      setLoading,
    })(value)
  }

  return (
    <Field
      label="Are you on a keto diet?"
      complete
      info={`If you don't know what keto is, answer "No."`}
    >
      <Dropdown
        value={auth.user.wants_to_induce_ketosis.toString()}
        loading={loading}
        onSelect={patch}
        options={YES_NO_OPTIONS}
      />
    </Field>
  )
}

export function Weight({ weight, loadingWeight, refreshWeight, refreshMetrics }) {
  const [updating, setUpdating] = useState(false)

  async function updateWeight(newWeight) {
    if (!newWeight) {
      return
    }

    const today = Day.today()

    try {
      setUpdating(true)
      const existingLogs = await api.get("/api/v1/body_logs", {
        start: today,
        page_size: 1,
      })
      if (existingLogs.data.length === 0) {
        await api.post("/api/v1/body_logs", { weight: newWeight })
      } else {
        await api.patch(`/api/v1/body_logs/${existingLogs.data[0].idx}`, {
          weight: newWeight,
        })
      }
    } catch (error) {
      notify.error(error)
    } finally {
      setUpdating(false)
      await Promise.all([refreshWeight(), refreshMetrics()])
    }
  }

  return (
    <Field
      label="How much do you weigh?"
      complete={![undefined, null].includes(weight)}
    >
      <Editable.Number
        loading={updating || loadingWeight}
        initialValue={weight ? weight.weight : ""}
        onCommit={updateWeight}
        style={{ width: "100%" }}
        units="lbs."
      />
    </Field>
  )
}

export function PhysicalActivityLevel({ auth }) {
  const [loading, setLoading] = useState(false)

  async function patch(value) {
    return getPatchingFunction({
      field: "pal",
      auth,
      setLoading,
    })(value || null)
  }

  return (
    <Field
      label="How much do you exercise?"
      complete={![null, undefined].includes(auth.user.pal)}
    >
      <Dropdown
        value={auth.user.pal || ""}
        loading={loading}
        onSelect={patch}
        options={[NOT_SET_OPTION].concat(PAL_OPTIONS)}
      />
    </Field>
  )
}

export function BodyFat({
  bodyFat,
  loadingBodyFat,
  refreshBodyFat,
  cannotMeasureBodyFat,
  setCannotMeasureBodyFat,
  refreshMetrics,
}) {
  const [updating, setUpdating] = useState(false)

  async function updateBodyFat(newBodyFat_) {
    if ([null, undefined, ""].includes(newBodyFat_)) {
      return
    }

    const newBodyFat = newBodyFat_ / 100

    const today = Day.today()

    try {
      setUpdating(true)
      const existingLogs = await api.get("/api/v1/body_logs", {
        start: today,
        page_size: 1,
      })
      if (existingLogs.data.length === 0) {
        await api.post("/api/v1/body_logs", { body_fat: newBodyFat })
      } else {
        await api.patch(`/api/v1/body_logs/${existingLogs.data[0].idx}`, {
          body_fat: newBodyFat,
        })
      }
    } catch (error) {
      notify.error(error)
    } finally {
      setUpdating(false)
      await Promise.all([refreshBodyFat(), refreshMetrics()])
    }
  }

  return (
    <Field label="What's your body fat percentage?" complete={!!bodyFat}>
      <Flex align="baseline" gap="1rem" wrap>
        <Editable.Number
          loading={updating || loadingBodyFat}
          initialValue={bodyFat ? bodyFat.body_fat * 100 : ""}
          editCta="E.g. 15%"
          onCommit={updateBodyFat}
          style={{ minWidth: "18rem" }}
          units="%"
        />
        <Button
          variant="warning"
          icon="info-circle"
          iconSide="left"
          onClick={() => setCannotMeasureBodyFat(!cannotMeasureBodyFat)}
          style={{ whiteSpace: "nowrap", marginTop: "1rem" }}
        >
          I don&apos;t regularly measure my body fat...
        </Button>
      </Flex>
    </Field>
  )
}

export function Height({ auth, refreshMetrics }) {
  const [loading, setLoading] = useState(false)

  return (
    <Field
      label="How tall are you?"
      complete={![null, undefined].includes(auth.user.height)}
    >
      <Editable.Number
        loading={loading}
        initialValue={auth.user.height}
        onCommit={getPatchingFunction({
          field: "height",
          auth,
          setLoading,
          refreshMetrics,
        })}
        style={{ width: "100%" }}
        units="inches"
      />
    </Field>
  )
}

export function Gender({ auth, refreshMetrics }) {
  const [loading, setLoading] = useState(false)

  async function patch(value) {
    return getPatchingFunction({
      field: "gender",
      auth,
      setLoading,
      refreshMetrics,
    })(value || null)
  }

  return (
    <Field label="What is your gender?" complete={!!auth.user.gender}>
      <Dropdown
        value={auth.user.gender || ""}
        loading={loading}
        onSelect={patch}
        options={[NOT_SET_OPTION, ...GENDER_OPTIONS]}
      />
    </Field>
  )
}

export function Birthday({ auth, refreshMetrics }) {
  const [loading, setLoading] = useState(false)

  return (
    <Field
      label="When's your birthday?"
      complete={!!auth.user.birthday}
      info={`This is used to calculate your age, it doesn't need to be exact.
        If you don't want to share your birthday, a random date from your
        birth-year will work just as well.`}
    >
      <AgeAlert user={auth.user} />
      <Editable.Date
        type="date"
        loading={loading}
        initialValue={auth.user.birthday}
        onCommit={getPatchingFunction({
          field: "birthday",
          auth,
          setLoading,
          refreshMetrics,
        })}
        style={{ width: "100%" }}
      />
    </Field>
  )
}
