import React from "react"

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

import { api } from "api"
import Button from "components/button"
import Loading from "components/loading"
import PageTitle from "components/page_title"
import useAuth from "hooks/auth"
import { notify } from "notifications"

import { Section } from "./base"
import DeleteAccount from "./delete_account"
import {
  PhysicalActivityLevel,
  Birthday,
  EatClean,
  Height,
  Gender,
  GoalWeight,
  CaloricPace,
  BuildMass,
  InduceKetosis,
} from "./fields"

export default function SettingsPage() {
  const auth = useAuth()

  async function requestPasswordReset() {
    try {
      await api.post(`/api/v1/auth/request`, { email: auth.user.email })
      notify.info("A password reset link has been emailed to you, check your inbox")
    } catch (error) {
      notify.error(error)
    }
  }

  return (
    <Loading loading={auth.user.loading} debounce>
      <PageTitle icon="cog">Settings</PageTitle>

      <Section>
        <Section.Title>Basic info</Section.Title>
        <Row>
          <Col lg={6} sm={12}>
            <PhysicalActivityLevel auth={auth} />
          </Col>
          <Col lg={6} sm={12}>
            <Birthday auth={auth} />
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <Height auth={auth} />
          </Col>
          <Col lg={6} sm={12}>
            <Gender auth={auth} />
          </Col>
        </Row>
      </Section>

      <Section>
        <Section.Title>Goals</Section.Title>
        <Row>
          <Col lg={6} sm={12}>
            <GoalWeight auth={auth} />
          </Col>
          <Col lg={6} sm={12}>
            <CaloricPace auth={auth} />
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <BuildMass auth={auth} />
          </Col>
          <Col lg={6} sm={12}>
            <InduceKetosis auth={auth} />
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <EatClean auth={auth} />
          </Col>
        </Row>
      </Section>

      <Section>
        <Section.Title>Security</Section.Title>
        <Button onClick={requestPasswordReset}>
          Click here to reset your password
        </Button>
      </Section>

      <Section>
        <Section.Title>Delete account</Section.Title>
        <DeleteAccount auth={auth} />
      </Section>
    </Loading>
  )
}
