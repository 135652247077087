function formatNumber(number, { precision, units } = {}) {
  if ([undefined, null].includes(number)) {
    return number
  }

  return `${Number.parseFloat(number.toFixed(precision || 0))}${units || ""}`
}

const format = {
  macronutrientMass: (grams, { precision } = {}) =>
    formatNumber(grams, { precision, units: "g" }),
  micronutrientMass: (grams, { precision } = {}) =>
    formatNumber(1000 * grams, { precision, units: "mg" }),
  calories: (calories, { precision } = {}) =>
    formatNumber(calories, { precision, units: "cal" }),
  weight: (pounds, { precision } = {}) =>
    formatNumber(pounds, { precision, units: "lbs." }),
  percent: (unitReal, { precision } = {}) =>
    formatNumber(100 * unitReal, { precision, units: "%" }),
  number: formatNumber,
  foodAmount: (amount, { units } = {}) =>
    !amount ? amount : amount.replace("dimensionless", units || "servings"),
}

export default format
