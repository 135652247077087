import React from "react"

import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"

import Feedback from "./feedback"

export default function Control({
  id,
  children,
  name,
  label,
  formik,
  prepend,
  append,
  ...rest
}) {
  return (
    <Form.Group as={Col} controlId={id}>
      {label && (
        <Form.Label style={{ marginBottom: 0 }}>
          <small>{label}</small>
        </Form.Label>
      )}
      <InputGroup>
        {prepend && <InputGroup.Text>{prepend}</InputGroup.Text>}
        <Form.Control
          name={name}
          value={formik.values[name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isInvalid={formik.touched[name] && formik.errors[name]}
          isValid={formik.touched[name] && !formik.errors[name]}
          {...rest}
        >
          {children}
        </Form.Control>
        {append && <InputGroup.Text>{append}</InputGroup.Text>}
      </InputGroup>
      <Feedback name={name} formik={formik} />
    </Form.Group>
  )
}
