import React from "react"

import FoodSearch from "shared/food_search"

import { CustomFoodModalProvider } from "./custom_food"
import DefaultMenu from "./default_menu"
import Footer from "./footer"
import Result from "./result"

export default function Search({
  suggestions,
  loadingSuggestions,
  refreshDietData,
  dayOffset,
}) {
  return (
    <CustomFoodModalProvider refreshDietData={refreshDietData} dayOffset={dayOffset}>
      <FoodSearch
        defaultMenu={({ closeMenu }) => (
          <DefaultMenu
            closeMenu={closeMenu}
            suggestions={suggestions}
            loadingSuggestions={loadingSuggestions}
            dayOffset={dayOffset}
            refreshDietData={refreshDietData}
          />
        )}
        searchResult={({ closeMenu, result }) => (
          <Result
            closeMenu={closeMenu}
            result={result}
            refreshDietData={refreshDietData}
            dayOffset={dayOffset}
          />
        )}
        searchFooter={() => <Footer />}
      />
    </CustomFoodModalProvider>
  )
}
