import React, { useEffect } from "react"

import Alert from "react-bootstrap/Alert"
import { useHistory, Link } from "react-router-dom"

const REDIRECT_SECONDS = 5

export default function RedirectPage() {
  const history = useHistory()

  const search = new URLSearchParams(history.location.search)
  const redirectUrl = search.get("to") || "/diet"
  const redirectEvent = search.get("event")

  useEffect(() => {
    setTimeout(() => {
      history.push(redirectUrl)
    }, REDIRECT_SECONDS * 1000)
  }, [history, redirectUrl])

  return (
    <div style={{ textAlign: "center" }}>
      {redirectEvent === "checkout_success" && (
        <Alert variant="success">
          <Alert.Heading>Checkout successful!</Alert.Heading>
        </Alert>
      )}
      {redirectEvent === "checkout_failed" && (
        <Alert variant="danger">
          <Alert.Heading>Checkout failed!</Alert.Heading>
        </Alert>
      )}
      <p>Redirecting you to G.U.T.T.S. in {REDIRECT_SECONDS} seconds...</p>
      <Link to={redirectUrl}>Click here if you haven&apos;t been redirected</Link>
    </div>
  )
}
