import React, { useState, useEffect } from "react"

import Cookies from "js-cookie"
import Container from "react-bootstrap/Container"
import { BrowserRouter, Switch } from "react-router-dom"

import { api } from "api"
import { AuthContext } from "context"
import { notify } from "notifications"
import AdminPage from "pages/admin"
import BillingPage from "pages/billing"
import BodyPage from "pages/body"
import ThemePage from "pages/color"
import DietPage from "pages/diet"
import DisclaimersPage from "pages/disclaimers"
import ExplorePage from "pages/explore"
import FoodPage from "pages/food"
import FoodsPage from "pages/foods"
import LandingPage from "pages/landing"
import LoginPage from "pages/login"
import MobileAppPage from "pages/mobile_app"
import PasswordRequestPage from "pages/password_request"
import PasswordResetPage from "pages/password_reset"
import PrivacyPage from "pages/privacy"
import RecipePage from "pages/recipe"
import RecipesPage from "pages/recipes"
import RedirectPage from "pages/redirect"
import SettingsPage from "pages/settings"
import SubscribePage from "pages/subscribe"
import TermsOfServicePage from "pages/terms_of_service"

import Footer from "./footer"
import buildIconLibrary from "./icons"
import Loader from "./loader"
import Navbar from "./navbar"
import Route from "./route"

const USER_COOKIE_NAME = "ntrtn-app-c00kie-05041980"

buildIconLibrary()

async function refreshUser(setUser, setUserLoading) {
  let user
  try {
    setUserLoading(true)
    await api.get("/api/v1/auth/login") // force setting of xsrf token
    user = (await api.get("/api/v1/auth/status")).data
    setUser(user)
  } catch (error) {
    if (error.type !== "Unauthenticated") {
      notify.error(error)
    }
    user = {}
    setUser(user)
  } finally {
    setUserLoading(false)
  }

  return user
}

function identifyToFullstory(user) {
  // This should always and *only* occur on page-load and login, which is the same as
  // "any time the user state goes from {} to some value fetched from the backend."
  if ((user || {}).idx !== undefined) {
    window.FS.identify(user.idx, { displayName: user.email, email: user.email })
  }
}

export default function App() {
  const [user, setUser] = useState(undefined)
  const [userLoading, setUserLoading] = useState(false)

  async function login(email, password) {
    try {
      await api.get("/api/v1/auth/login")
      await api.post("/api/v1/auth/login", { email, password })
      const user_ = await refreshUser(setUser, setUserLoading)
      identifyToFullstory(user_)
    } catch (error) {
      notify.error(error)
      setUser({})
    }
  }

  function logout() {
    Cookies.remove(USER_COOKIE_NAME)
    setUser({})
  }

  useEffect(() => {
    async function onPageLoad() {
      const user_ = await refreshUser(setUser, setUserLoading)
      identifyToFullstory(user_)
    }

    onPageLoad()
  }, [])

  const authContext = {
    user,
    login,
    logout,
    loading: userLoading,
    refreshUser: () => refreshUser(setUser, setUserLoading),
  }
  if (user === undefined) {
    authContext.loggedIn = undefined
  } else if (user?.idx === undefined) {
    authContext.loggedIn = false
  } else {
    authContext.loggedIn = true
  }

  // Container overflow is set to "hidden" here so that animated elements pushed outside
  // a container by CSS's "transform" do not change the screen width.
  return (
    <AuthContext.Provider value={authContext}>
      <BrowserRouter>
        <Navbar />
        <Container style={{ paddingTop: "1rem", minHeight: 600, overflow: "hidden" }}>
          <Loader loading={userLoading} />
          <Switch>
            <Route login path="/login" page={LoginPage} />
            <Route gated admin path="/admin" page={AdminPage} />
            <Route gated path="/billing" page={BillingPage} />
            <Route gated path="/foods/:id" page={FoodPage} />
            <Route gated path="/foods" page={FoodsPage} />
            <Route gated path="/recipes/:id" page={RecipePage} />
            <Route gated path="/recipes" page={RecipesPage} />
            <Route gated path="/explore" page={ExplorePage} />
            <Route gated path="/settings" page={SettingsPage} />
            <Route gated path="/redirect" page={RedirectPage} />
            <Route gated path="/diet" page={DietPage} />
            <Route gated path="/theme" page={ThemePage} />
            <Route gated path="/body" page={BodyPage} />
            <Route gated path="/mobile_app" page={MobileAppPage} />
            <Route gated path="/subscribe" page={SubscribePage} />
            <Route path="/request_password" page={PasswordRequestPage} />
            <Route path="/reset_password" page={PasswordResetPage} />
            <Route path="/legal/disclaimers" page={DisclaimersPage} />
            <Route path="/legal/tos" page={TermsOfServicePage} />
            <Route path="/legal/privacy" page={PrivacyPage} />
            <Route path="/" page={LandingPage} />
          </Switch>
        </Container>
        <Footer />
      </BrowserRouter>
    </AuthContext.Provider>
  )
}
