import React, { useEffect, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Card from "react-bootstrap/Card"
import Modal from "react-bootstrap/Modal"
import { useHistory, Link, useParams } from "react-router-dom"

import { api, useGet, usePage } from "api"
import Button from "components/button"
import Editable from "components/editable"
import Empty from "components/empty"
import Field from "components/field"
import Flex from "components/flex"
import PageTitle from "components/page_title"
import useBreakpoint from "hooks/breakpoint"
import { notify } from "notifications"

import IngredientCard from "./ingredient_card"
import IngredientSearch from "./ingredient_search"
import Nutrition from "./nutrition"

async function getFood(foodIdx, setFood, setLoading) {
  if (foodIdx !== null && foodIdx !== undefined) {
    setLoading(true)
    try {
      setFood((await api.get(`/api/v1/foods/${foodIdx}`, { amount: "1" })).data)
    } catch (error) {
      notify.error(error)
    } finally {
      setLoading(false)
    }
  }
}

export default function RecipePage() {
  const { id } = useParams()
  const [food, setFood] = useState({})
  const [loadingFood, setLoadingFood] = useState(false)
  const [patchingField, setPatchingField] = useState(null)
  const recipe = useGet(`/api/v1/recipes/${id}`)
  const ingredients = usePage(`/api/v1/ingredients`, {
    recipe_idx: id,
    sort: "created_at.desc,idx",
  })

  const foodIdx = recipe.food_idx

  useEffect(() => {
    async function wrapper() {
      await getFood(foodIdx, setFood, setLoadingFood)
    }

    wrapper()
  }, [foodIdx])

  const smallScreen = !useBreakpoint("md")

  async function refreshData() {
    await recipe.refresh()
    await ingredients.refresh()
    await getFood(foodIdx, setFood, setLoadingFood)
  }

  async function patchRecipe(field, value) {
    setPatchingField(field)
    try {
      await api.patch(`/api/v1/recipes/${id}`, { [field]: value })
    } catch (error) {
      notify.error(error)
    } finally {
      setPatchingField(null)
      await refreshData()
    }
  }

  return (
    <React.Fragment>
      <Flex justify="space-between" align="baseline" wrap>
        <PageTitle icon="book-open">
          {recipe.name ? `Edit Recipe: "${recipe.name}"` : "Edit Untitled Recipe"}
        </PageTitle>
        {foodIdx && (
          <Link to={`/foods/${foodIdx}`} style={{ whiteSpace: "nowrap" }}>
            Go to food page <FontAwesomeIcon icon="arrow-right" />
          </Link>
        )}
      </Flex>

      <Field label="Recipe name">
        <Editable.Text
          id="recipe-name"
          initialValue={recipe.name}
          editCta="What are you making?"
          loading={patchingField === "name"}
          onCommit={(value) => patchRecipe("name", value)}
          style={{ width: "100%" }}
        />
      </Field>

      <Card style={{ margin: "1rem 0" }}>
        <Card.Body>
          <Card.Title>Ingredients</Card.Title>
          <Flex justify="space-between" gap="1rem">
            <Flex.Child grow={1}>
              <IngredientSearch refreshData={refreshData} recipeIdx={id} />
              {smallScreen && (
                <Nutrition
                  compact
                  recipe={recipe}
                  food={food}
                  ingredients={ingredients.page}
                  loadingFood={loadingFood}
                  refreshData={refreshData}
                />
              )}
              <Empty show={!ingredients.page.length}>
                Add ingredients above, then search this recipe on the main page to log
                it any time.
              </Empty>
              {ingredients.page.map((ingredient) => (
                <div key={ingredient.idx} style={{ marginTop: "1rem" }}>
                  <IngredientCard ingredient={ingredient} refreshData={refreshData} />
                </div>
              ))}
            </Flex.Child>
            <Flex.Child>
              {!smallScreen && (
                <Nutrition
                  recipe={recipe}
                  food={food}
                  ingredients={ingredients.page}
                  loadingFood={loadingFood}
                  refreshData={refreshData}
                />
              )}
            </Flex.Child>
          </Flex>
        </Card.Body>
      </Card>

      <Card style={{ marginBottom: "1rem" }}>
        <Card.Body>
          <Card.Title>Instructions</Card.Title>
          <Editable.Text
            id="recipe-instructions"
            textArea
            initialValue={recipe.instructions}
            loading={patchingField === "instructions"}
            editCta="(Optional) describe how this recipe is made"
            onCommit={(value) => patchRecipe("instructions", value)}
          />
        </Card.Body>
      </Card>

      <DeleteRecipeButton recipe={recipe} />
    </React.Fragment>
  )
}

function DeleteRecipeButton({ recipe }) {
  const history = useHistory()
  const [deleting, setDeleting] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const recipeName = recipe.name ? `"${recipe.name}"` : "untitled"
  const hideModal = () => setShowModal(false)

  async function deleteRecipe() {
    setDeleting(true)
    try {
      await api.delete(`/api/v1/recipes/${recipe.idx}`)
      notify.info(`${recipeName} deleted`)
    } catch (error) {
      notify.error(error)
    } finally {
      setDeleting(false)
      history.push("/recipes")
    }
  }

  return (
    <React.Fragment>
      <Button
        variant="outline-danger"
        icon="trash-alt"
        onClick={() => setShowModal(true)}
        loading={deleting}
      >
        Delete Recipe
      </Button>

      <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>Delete this recipe?</Modal.Header>
        <Modal.Body>Are you sure you want to delete {recipeName}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteRecipe} loading={deleting}>
            Delete {recipeName}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}
