import React from "react"

import Card from "react-bootstrap/Card"

import Flex from "components/flex"

function Setting({ children }) {
  return <div style={{ marginBottom: "1rem" }}>{children}</div>
}

Setting.Title = ({ children }) => (
  <Flex justify="space-between" align="baseline" style={{ marginBottom: "0.25rem" }}>
    {children}
  </Flex>
)

function Section({ children }) {
  return (
    <Card style={{ marginBottom: "1rem" }}>
      <Card.Body>{children}</Card.Body>
    </Card>
  )
}
Section.Title = ({ children }) => <Card.Title>{children}</Card.Title>

export { Setting, Section }
