import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Flex from "components/flex"

export default function Empty({ children, icon, show = false }) {
  return show ? (
    <Flex
      className="rounded bg-light text-muted shadow-sm"
      column
      justify="center"
      align="center"
      style={{ minHeight: 400 }}
      margin="1rem 0"
    >
      <FontAwesomeIcon icon={icon || "clipboard-list"} size="4x" />
      <div style={{ padding: "2rem", textAlign: "center", fontSize: "1.25rem" }}>
        <b>{children}</b>
      </div>
    </Flex>
  ) : null
}
