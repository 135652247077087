import React, { useEffect, useState } from "react"

import { api, usePage } from "api"
import Flex from "components/flex"
import Loading from "components/loading"
import PageTitle from "components/page_title"
import Pagination from "components/pagination"
import { notify } from "notifications"

import Add from "./add"
import Summary from "./summary"
import Table from "./table"

async function getLatestBiometrics({
  setLoadingLatestBiometrics,
  setLatestBodyFat,
  setLatestWeight,
}) {
  try {
    setLoadingLatestBiometrics(true)
    let res = await api.get("/api/v1/body_logs", {
      require: "body_fat",
      sort: "measured_on.desc",
      page_size: 1,
    })
    if (res.data.length) {
      setLatestBodyFat(res.data[0].body_fat)
    }

    res = await api.get("/api/v1/body_logs", {
      require: "weight",
      sort: "measured_on.desc",
      page_size: 1,
    })
    if (res.data.length) {
      setLatestWeight(res.data[0].weight)
    }
  } catch (error) {
    notify.error(error)
  } finally {
    setLoadingLatestBiometrics(false)
  }
}

export default function BodyPage() {
  const [page, setPage] = useState(1)
  const bodyLogs = usePage("/api/v1/body_logs", {
    page,
    sort: "measured_on.desc,created_at.desc",
  })

  const [loadingLatestBiometrics, setLoadingLatestBiometrics] = useState(false)
  const [latestBodyFat, setLatestBodyFat] = useState(null)
  const [latestWeight, setLatestWeight] = useState(null)

  useEffect(() => {
    async function wrapper() {
      await getLatestBiometrics({
        setLoadingLatestBiometrics,
        setLatestBodyFat,
        setLatestWeight,
      })
    }

    wrapper()
  }, [])

  async function refreshData() {
    await Promise.all([
      getLatestBiometrics({
        setLoadingLatestBiometrics,
        setLatestBodyFat,
        setLatestWeight,
      }),
      bodyLogs.refresh(),
    ])
  }

  const userEstimatesBodyFat = [null, undefined].includes(latestBodyFat)

  return (
    <React.Fragment>
      <PageTitle icon="child">Body</PageTitle>
      <Summary
        weight={latestWeight}
        bodyFat={latestBodyFat}
        loading={loadingLatestBiometrics}
        userEstimatesBodyFat={userEstimatesBodyFat}
      />
      <h2>
        <Flex justify="space-between" align="baseline">
          History
          <Add
            userEstimatesBodyFat={userEstimatesBodyFat}
            refreshData={refreshData}
            latestBodyFat={latestBodyFat}
            latestWeight={latestWeight}
          />
        </Flex>
      </h2>
      <Loading loading={bodyLogs.loading}>
        <Table>
          <Table.Header userEstimatesBodyFat={userEstimatesBodyFat} />
          <Table.Body
            bodyLogs={bodyLogs}
            refreshData={refreshData}
            userEstimatesBodyFat={userEstimatesBodyFat}
          />
        </Table>
        <Pagination
          cursor={bodyLogs.cursor}
          setPage={setPage}
          loading={bodyLogs.loading}
        />
      </Loading>
    </React.Fragment>
  )
}
