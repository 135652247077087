import React, { useState } from "react"

import { api } from "api"
import { notify } from "notifications"

import { useLastEvent } from "../../event_context"
import FooterButton from "./footer_button"

export default function Delete({ serving, refreshDietData }) {
  const lastEvent = useLastEvent()
  const [deleting, setDeleting] = useState(false)

  async function deleteServing() {
    try {
      setDeleting(true)
      await api.delete(`/api/v1/servings/${serving.idx}`)
    } catch (error) {
      notify.error(error)
    } finally {
      setDeleting(false)
      lastEvent.setNone()
      await refreshDietData()
    }
  }

  return (
    <FooterButton icon="trash-alt" loading={deleting} onClick={deleteServing}>
      Delete
    </FooterButton>
  )
}
