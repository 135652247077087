import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Animate from "components/animate"
import Flex from "components/flex"
import useBreakpoint from "hooks/breakpoint"

function DemoSlide({ children, enterLeft, enterRight }) {
  return (
    <div style={{ marginBottom: "1rem" }}>
      <Flex justify="center">
        <Animate
          enterLeft={enterLeft}
          enterRight={enterRight}
          onceInView={{ triggerOnce: true, threshold: 0.5 }}
          duration={300}
        >
          {children}
        </Animate>
      </Flex>
      <Animate
        fadeIn
        onceInView={{ triggerOnce: true, threshold: 0 }}
        duration={300}
        style={{ margin: "0.5rem 0" }}
      >
        <FontAwesomeIcon className="text-muted" icon="arrow-down" size="2x" />
      </Animate>
    </div>
  )
}

DemoSlide.Title = ({ children }) => {
  return <h2 style={{ marginBottom: "1rem" }}>{children}</h2>
}

function DemoVideo({ src, alt }) {
  const smallScreen = !useBreakpoint("md")

  return (
    <video
      src={src}
      alt={alt}
      style={{
        width: smallScreen ? "400px" : "650px",
        opacity: 0.95,
        borderRadius: "3px",
        boxShadow: "0px 0px 10px 10px rgba(0, 0, 0, 0.3)",
      }}
      loop
      muted
      autoPlay
    />
  )
}

export default function Demo() {
  return (
    <React.Fragment>
      <DemoSlide enterLeft>
        <DemoSlide.Title>Tell us your goals</DemoSlide.Title>
        <DemoVideo src="entering_goals.webm" alt="User entering goals in GUTTS" />
      </DemoSlide>
      <DemoSlide enterRight>
        <DemoSlide.Title>Get a plan and stick to it</DemoSlide.Title>
        <DemoVideo
          src="initial_meter_progress.webm"
          alt="Adding data to GUTTS and making progress on a diet"
        />
      </DemoSlide>
      <DemoSlide enterLeft>
        <DemoSlide.Title>Stay on track &apos;til you make it</DemoSlide.Title>
        <DemoVideo src="reaching_goals.webm" alt="User reaching their goals in GUTTS" />
      </DemoSlide>
    </React.Fragment>
  )
}
