import React from "react"

import { Link } from "react-router-dom"

import Flex from "components/flex"
import FoodBadges from "shared/food_badges"

export default function FoodLink({ food }) {
  return (
    <Flex align="baseline" gap="0.5rem">
      <FoodBadges food={food} compact />
      <Link to={`/foods/${food.idx}`} style={{ whiteSpace: "normal" }}>
        {food.brand && <span>{`${food.brand} `}</span>}
        <b>{food.name}</b>
      </Link>
    </Flex>
  )
}
