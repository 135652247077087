import React from "react"

import Card from "react-bootstrap/Card"

import Flex from "components/flex"

export default function Epitaph({ children }) {
  return (
    <Flex justify="center">
      <Card
        style={{
          maxWidth: "650px",
          marginBottom: "1rem",
          background: "rgba(0, 0, 0, 0.6)",
          textAlign: "justify",
        }}
      >
        <Card.Body>{children}</Card.Body>
      </Card>
    </Flex>
  )
}
