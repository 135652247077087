import React from "react"

import Card from "react-bootstrap/Card"

import Loading from "components/loading"
import useDebounce from "hooks/debounce"

export default function Loader({ loading }) {
  // Debounce the appearance of the loader, but not the disappearance
  const loadingDebounced = useDebounce(loading, 500)
  if (!loadingDebounced || !loading) {
    return null
  }

  return (
    <Card>
      <Loading loading>
        <Card.Body style={{ minHeight: "10rem" }}>
          <Card.Title style={{ textAlign: "center" }}>One moment please...</Card.Title>
        </Card.Body>
      </Loading>
    </Card>
  )
}
