import React from "react"

import Calendar from "react-calendar"

import Loading from "components/loading"

export default function DatePicker({ value, onChange, loading }) {
  return (
    <Loading loading={loading}>
      <Calendar value={value} onChange={onChange} />
    </Loading>
  )
}
