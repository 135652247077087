import React from "react"

export default function Menu({ children, show }) {
  return (
    <div
      className={show ? "dropdown-menu show" : "dropdown-menu"}
      role="presentation"
      style={{
        position: "absolute",
        width: "100%",
        maxHeight: "60vh",
        overflowY: "scroll",
      }}
    >
      {children}
    </div>
  )
}
