import React from "react"

import Meter from "components/meter"
import format from "format"

import Grid from "./grid"

export default function CalorieMeter({ metrics }) {
  const value = metrics.calories || 0
  const minTarget = metrics.minimum_calorie_target
  const maxTarget = metrics.maximum_calorie_target

  let variant = "warning"
  if (metrics.calorie_target_failed) {
    variant = "danger"
  } else if (metrics.calories_on_target) {
    variant = "success"
  }

  let cta
  if (
    metrics.calorie_target_actionable &&
    !metrics.diet_on_target &&
    metrics.calories_on_target &&
    metrics.calorie_target_almost_over &&
    metrics.calorie_target_room_to_run !== undefined
  ) {
    cta = `Just ${format.calories(
      metrics.calorie_target_room_to_run
    )} from going over on calories`
  }

  return (
    <React.Fragment>
      <Grid.Goals min={minTarget} max={maxTarget} label="Calories" />
      <Grid.Progress>
        <Meter
          value={value}
          maxValue={(maxTarget || 0) + 100}
          minTarget={minTarget}
          maxTarget={maxTarget}
          text={`${format.calories(value)}`}
          actionable={metrics.calorie_target_actionable}
          variant={variant}
          failed={metrics.calorie_target_failed}
          border={cta ? "danger" : undefined}
        />
      </Grid.Progress>
      <Grid.CallToAction cta={cta} />
    </React.Fragment>
  )
}
