import React from "react"

function Flex({
  children,
  className,
  column,
  reverse,
  justify,
  align,
  wrap,
  margin,
  gap,
  style,
}) {
  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: (column ? "column" : "row").concat(reverse ? "-reverse" : ""),
        ...(justify ? { justifyContent: justify } : {}),
        ...(align ? { alignItems: align } : {}),
        ...(margin ? { margin } : {}),
        ...(wrap ? { flexWrap: "wrap" } : {}),
        ...(gap ? { gap } : {}),
        ...style,
      }}
    >
      {children}
    </div>
  )
}

Flex.Child = function FlexChild({
  children,
  justify,
  align,
  margin,
  basis,
  grow,
  style,
}) {
  const style_ = {
    ...(justify ? { justifySelf: justify } : {}),
    ...(align ? { alignSelf: align } : {}),
    ...(margin ? { margin } : {}),
    ...(basis ? { flexBasis: basis } : {}),
    ...(grow ? { flexGrow: grow } : {}),
  }

  return <div style={{ ...style_, ...style }}>{children}</div>
}

export default Flex
