import format from "format"

const UNITS_OF_MASS = [
  "g",
  "grams",
  "gram",
  "oz",
  "ounce",
  "ounces",
  "lb",
  "lbs.",
  "pound",
  "pounds",
]
const UNITS_OF_VOLUME = [
  "cup",
  "fluid_ounce",
  "fluid ounces",
  "fluid ounce",
  "fl_oz",
  "fl. oz.",
  "milliliter",
  "milliliters",
  "mL",
]
const SPOON_MEASURES = ["tablespoon", "teaspoon", "tbsp", "tsp", "scoop", "scoops"]

const APPROXIMATIONS_OF_SIZE = [
  "small",
  "big",
  "huge",
  "medium",
  "large",
  "extra_large",
  "extra large",
  "jumbo",
]

function normalize(string) {
  return (string || "").toLowerCase()
}

function isNumberOfWholes({ thing, name, units }) {
  return (
    units.includes(thing) ||
    (APPROXIMATIONS_OF_SIZE.includes(units) && name.includes(thing))
  )
}

function isPieceOfWhole({ thing, piece, name, units }) {
  return units.includes(piece) && name.includes(thing)
}

function computeServingIcon({ name: name_, units: units_ }) {
  const name = normalize(name_)
  const units = normalize(units_)

  // Check standard units of mass and volume
  if (UNITS_OF_MASS.includes(units)) {
    return "weight"
  }
  if (SPOON_MEASURES.includes(units)) {
    return "utensil-spoon"
  }
  if (units === "pint") {
    return "beer"
  }
  if (UNITS_OF_VOLUME.includes(units)) {
    return "glass-whiskey"
  }

  // Check things where the thing is the unit, e.g. '3 apples,' '2 big potatoes.'
  if (isNumberOfWholes({ name, units, thing: "apple" })) {
    return "apple-alt"
  }
  if (isNumberOfWholes({ name, units, thing: "egg" })) {
    return "egg"
  }
  if (isNumberOfWholes({ name, units, thing: "cookie" })) {
    return "cookie"
  }
  if (isNumberOfWholes({ name, units, thing: "pepper" })) {
    return "pepper-hot"
  }
  if (isNumberOfWholes({ name, units, thing: "carrot" })) {
    return "carrot"
  }
  if (isNumberOfWholes({ name, units, thing: "lemon" })) {
    return "lemon"
  }
  if (isNumberOfWholes({ name, units, thing: "burger" })) {
    return "hamburger"
  }
  if (isNumberOfWholes({ name, units, thing: "hot dog" })) {
    return "hotdog"
  }

  // Check for pieces of a whole
  if (isPieceOfWhole({ name, units, thing: "pizza", piece: "slice" })) {
    return "pizza-slice"
  }
  if (isPieceOfWhole({ name, units, thing: "bread", piece: "slice" })) {
    return "bread-slice"
  }
  if (isPieceOfWhole({ name, units, thing: "bacon", piece: "strip" })) {
    return "bacon"
  }

  // Check for generic 'container' units
  if (["bottle", "whole bottle"].includes(units)) {
    return "wine-bottle"
  }
  if (["container", "whole container"].includes(units)) {
    return "box"
  }
  if (["dimensionless", "serving"].includes(units)) {
    return "utensils"
  }

  return null
}

function getMeasureName(measures, measureIdx) {
  return (measures.find(({ idx }) => idx === measureIdx) || {}).name
}

export default function getProps({
  food,
  measures,
  searchResult,
  postOption,
  ingredient,
  serving,
}) {
  let name
  let units
  let magnitude

  if (serving) {
    units = serving.units
    magnitude = serving.magnitude
    name = serving.food
  } else if (ingredient) {
    units = ingredient.units
    magnitude = ingredient.magnitude
    name = ingredient.name
  } else if (food && measures) {
    magnitude = food.magnitude
    name = food.name
    units = ![null, undefined].includes(food.measure_idx)
      ? getMeasureName(measures, food.measure_idx)
      : food.units
  } else if (searchResult && postOption) {
    // eslint-disable-next-line prefer-destructuring
    magnitude = postOption.magnitude
    name = searchResult.name
    units = ![null, undefined].includes(postOption.measure_idx)
      ? getMeasureName(searchResult.measures, postOption.measure_idx)
      : postOption.units
  }

  if (units === "dimensionless") {
    units = "serving"
  }

  return {
    icon: computeServingIcon({ name, units }),
    label: `${format.number(parseFloat(magnitude), { precision: 5 })} ${units}`,
  }
}
