import React from "react"

import Alert from "react-bootstrap/Alert"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import { useLocation, useHistory } from "react-router-dom"
import * as yup from "yup"

import { api } from "api"
import Form from "components/form"
import useAuth from "hooks/auth"
import { notify } from "notifications"

export default function PasswordResetPage() {
  const auth = useAuth()
  const history = useHistory()
  const queryParams = new URLSearchParams(useLocation().search)

  const email = queryParams.get("email")
  const resetToken = queryParams.get("secret")

  if (!email || !resetToken) {
    return (
      <Alert variant="danger">
        <Alert.Heading>Bad password reset link</Alert.Heading>
      </Alert>
    )
  }

  async function requestNewPassword({ newPassword }) {
    try {
      await api.get("/api/v1/auth/login")
      await api.post("/api/v1/auth/reset", {
        email,
        password_reset_token: resetToken,
        password: newPassword,
      })
      notify.success("Successfully reset password")

      await auth.login(email, newPassword)
      history.push("/")
    } catch (error) {
      notify.error(error)
    }
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Reset Password</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form
          initialValues={{
            newPassword: "",
            newPasswordConfirm: "",
          }}
          validationSchema={{
            newPassword: yup
              .string()
              .required("Password is required")
              .matches(
                /^.*(?=.{8,})((?=.*[0-9]){1})(?=.*\d)((?=.*[a-zA-Z]){1}).*$/,
                "Password must be at least 8 characters long and contain a letter " +
                  "and a number"
              ),
            newPasswordConfirm: yup
              .string()
              .required("Confirm your new password")
              .oneOf([yup.ref("newPassword")], "Passwords must match"),
          }}
          onSubmit={requestNewPassword}
        >
          {(formik) => (
            <Form.Body formik={formik}>
              <Row>
                <Form.Password
                  id="password-reset-new-password"
                  name="newPassword"
                  label="New password"
                  formik={formik}
                />
              </Row>
              <Row>
                <Form.Password
                  id="password-reset-new-password-confirm"
                  name="newPasswordConfirm"
                  label="Confirm new password"
                  formik={formik}
                />
              </Row>
              <Row>
                <Form.Button formik={formik}>Reset password</Form.Button>
              </Row>
            </Form.Body>
          )}
        </Form>
      </Card.Body>
    </Card>
  )
}
