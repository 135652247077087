import React, { useState, useRef, useEffect } from "react"

import BootstrapForm from "react-bootstrap/Form"

import Loading from "components/loading"
import format from "format"

import { OverlayContainer, NumberHint, UnitOverlay } from "../overlays"
import getStep from "./step"
import { isFiniteNumber } from "./utils"

export default function EditableNumber({
  initialValue,
  onCommit: onCommit_,
  loading,
  style = {},
  editCta = "Click to add",
  precision = 0,
  units,
}) {
  const input = useRef()
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if (input.current && editing) {
      input.current.focus()
    }
  }, [editing, input])

  function onEdit() {
    setEditing(true)
  }

  function onCommit(value) {
    setEditing(false)
    if (value !== initialValue) {
      onCommit_(value)
    }
  }

  if (editing) {
    return (
      <EditNumber
        ref={input}
        initialValue={initialValue}
        onCommit={onCommit}
        units={units}
        style={style}
      />
    )
  }

  const showEditCta = [undefined, null, ""].includes(initialValue)
  let formattedNumber
  if (!showEditCta) {
    formattedNumber = format.number(initialValue, { precision })
  }

  return (
    <OverlayContainer>
      <NumberHint />
      {!loading && !showEditCta && (
        <UnitOverlay text={formattedNumber} units={units} style={style} />
      )}
      <span
        className="form-control editable-inactive editable"
        style={{
          ...style,
          ...(loading ? { textAlign: "center" } : {}),
        }}
        onClick={onEdit}
        onFocus={onEdit}
        onKeyDown={(e) => e.key === "Enter" && onEdit()}
        tabIndex={0}
        role="textbox"
      >
        <Loading loading={loading} inline>
          <span style={{ visibility: loading ? "hidden" : "inherit" }}>
            {showEditCta ? editCta : formattedNumber}
          </span>
        </Loading>
      </span>
    </OverlayContainer>
  )
}

export const EditNumber = React.forwardRef(
  ({ initialValue, onCommit, units, style }, ref) => {
    const [value, setValue] = useState(initialValue)

    const step = getStep(initialValue)

    return (
      <OverlayContainer>
        <NumberHint active />
        <UnitOverlay text={value} units={units} style={style} />
        <BootstrapForm.Control
          className="text-primary editable"
          ref={ref}
          style={style}
          type="text"
          pattern="[0-9]*"
          inputMode="numeric"
          onChange={(e) => {
            if (
              !e.target.value ||
              [".", "-", "-."].includes(e.target.value) ||
              isFiniteNumber(parseFloat(e.target.value))
            ) {
              setValue(e.target.value)
            } else {
              setValue(value)
            }
          }}
          onFocus={(e) => e.target.select()}
          onBlur={() => onCommit(value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onCommit(value)
            } else if (["ArrowUp", "ArrowDown"].includes(e.key)) {
              e.preventDefault()

              let stepAdjustedValue = parseFloat(value) - step
              if (e.key === "ArrowUp") {
                stepAdjustedValue = parseFloat(value) + step
              }

              if (!isFiniteNumber(stepAdjustedValue)) {
                return
              }

              setValue(stepAdjustedValue)
            }
          }}
          onKeyPress={(e) => {
            if (!"-.0123456789".includes(e.key)) {
              e.preventDefault()
            }
          }}
          value={value}
        />
      </OverlayContainer>
    )
  }
)
