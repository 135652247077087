import React from "react"

import Alert from "react-bootstrap/Alert"

const MS_IN_A_YEAR = 31536000000

export default function AgeAlert({ user }) {
  if (!user || !user.birthday) {
    return null
  }

  const age = (new Date().getTime() - new Date(user.birthday).getTime()) / MS_IN_A_YEAR

  if (age > 18) {
    return null
  }

  return (
    <Alert variant="warning">
      It looks like you&apos;re under 18. Unfortunately, GUTTS&apos; diet
      recommendations are only calibrated for adults. We recommend you do not use GUTTS
      at this time.
    </Alert>
  )
}
