import React, { useState } from "react"

import { api } from "api"
import Button from "components/button"
import Flex from "components/flex"
import Tooltip from "components/tooltip"
import { notify } from "notifications"

export default function ElasticSync() {
  const [syncing, setSyncing] = useState(false)
  const [syncingSafe, setSyncingSafe] = useState(false)

  async function runElasticSync({ safe }) {
    try {
      if (safe) {
        setSyncingSafe(true)
      } else {
        setSyncing(true)
      }

      notify.info("Running elastic sync, this may take a while...")

      if (safe) {
        await api.post("/api/v1/rpcs/elastic_sync?safe=1")
      } else {
        await api.post("/api/v1/rpcs/elastic_sync")
      }
      notify.success("Sync complete")
    } catch (error) {
      notify.error(error)
    } finally {
      setSyncing(false)
      setSyncingSafe(false)
    }
  }

  return (
    <Flex gap="1rem">
      <Button onClick={runElasticSync} loading={syncing}>
        Run sync
      </Button>

      <Tooltip
        id="safe-elastic-sync-button"
        tooltip="Safe sync is more stable but takes longer"
      >
        <Button
          icon="exclamation-circle"
          variant="warning"
          onClick={() => runElasticSync({ safe: true })}
          loading={syncingSafe}
        >
          Run safe sync
        </Button>
      </Tooltip>
    </Flex>
  )
}
