import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CloseButton from "react-bootstrap/CloseButton"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"

import { api } from "api"
import Button from "components/button"
import Flex from "components/flex"
import Loading from "components/loading"
import { notify } from "notifications"

import Add from "./add"
import Multiply from "./multiply"
import QuickAdjust from "./quick_adjust"
import Scale from "./scale"

export default function Calculator({ serving, refreshDietData }) {
  const [show, setShow] = useState(false)
  const [patching, setPatching] = useState(false)

  function closeModal() {
    setShow(false)
  }

  async function adjust(operation, factor_) {
    setPatching(true)
    const factor = parseFloat(factor_)
    try {
      if (operation === "multiply") {
        await api.patch(`/api/v1/servings/${serving.idx}`, {
          magnitude: serving.magnitude * factor,
        })
      } else if (operation === "add") {
        await api.patch(`/api/v1/servings/${serving.idx}`, {
          magnitude: serving.magnitude + factor,
        })
      }
    } catch (error) {
      notify.error(error)
    } finally {
      closeModal()
      setPatching(false)
      await refreshDietData()
    }
  }

  return (
    <OverlayTrigger
      placement="auto"
      popperConfig={{
        modifiers: [
          { name: "flip", options: { allowedAutoPlacements: ["top", "bottom"] } },
        ],
      }}
      trigger="click"
      rootClose
      show={show}
      onClick={() => setShow(!show)}
      onToggle={setShow}
      overlay={
        <Popover style={{ minWidth: "22rem" }}>
          <Popover.Header>
            <Flex justify="space-between" align="center">
              <span>
                <FontAwesomeIcon icon="calculator" /> Adjust
              </span>
              <CloseButton onClick={closeModal} />
            </Flex>
          </Popover.Header>
          <Popover.Body>
            <Loading loading={patching}>
              <QuickAdjust serving={serving} adjust={adjust} />
              <hr />
              <Add serving={serving} adjust={adjust} />
              <hr />
              <Multiply adjust={adjust} />
              <hr />
              <Scale serving={serving} adjust={adjust} />
            </Loading>
          </Popover.Body>
        </Popover>
      }
    >
      <Button icon="calculator" variant="light" />
    </OverlayTrigger>
  )
}
