import React, { useState } from "react"

import ListGroup from "react-bootstrap/ListGroup"
import { Link, useHistory } from "react-router-dom"

import { api, usePage } from "api"
import Animate from "components/animate"
import Button from "components/button"
import Empty from "components/empty"
import Loading from "components/loading"
import PageTitle from "components/page_title"
import Pagination from "components/pagination"
import Paywall from "components/paywall"
import useAuth from "hooks/auth"
import { notify } from "notifications"

export default function RecipesPage() {
  const [page, setPage] = useState(1)

  const recipes = usePage("/api/v1/recipes", { page })

  const auth = useAuth()
  const [posting, setPosting] = useState(false)
  const history = useHistory()

  async function createRecipe() {
    setPosting(true)
    try {
      const recipe = (await api.post("/api/v1/recipes", { name: "" })).data
      setPosting(false)
      history.push(`/recipes/${recipe.idx}`)
    } catch (error) {
      setPosting(false)
      notify.error(error)
    }
  }

  const noRecipes = !recipes.page.length && !recipes.loading

  const paywalled = !auth.user.loading && !auth.user.is_subscribed

  return (
    <div style={{ position: "relative" }}>
      <PageTitle icon="book-open">Your Recipes</PageTitle>
      <div style={{ position: "absolute", right: 0, top: 0 }}>
        <Animate wiggle={noRecipes}>
          <Button
            onClick={createRecipe}
            icon="plus"
            loading={posting}
            disabled={!auth.user.is_subscribed}
          >
            Add
          </Button>
        </Animate>
      </div>

      {paywalled && <Paywall subscribeTo="add new recipes" />}

      {!paywalled && (
        <p className="text-muted">
          Recipes make it easy to log food you make from scratch
        </p>
      )}

      <Empty show={noRecipes}>No recipes yet, click above to create one.</Empty>
      <Loading loading={recipes.loading} debounce>
        <ListGroup variant="flush">
          {recipes.page.map((recipe) => (
            <ListGroup.Item key={recipe.idx}>
              <Link to={`/recipes/${recipe.idx}`} style={{ whiteSpace: "normal" }}>
                <b>{recipe.name || "Untitled"}</b>
              </Link>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Loading>
      <Pagination cursor={recipes.cursor} setPage={setPage} loading={recipes.loading} />
    </div>
  )
}
