import React, { useState, useRef, useEffect } from "react"

import BootstrapForm from "react-bootstrap/Form"

import Loading from "components/loading"

import { OverlayContainer, TextHint } from "./overlays"

const TEXT_AREA_DEFAULT_STYLES = {
  width: "100%",
  minHeight: "6rem",
  whiteSpace: "normal",
  overflow: "inherit",
}

function computeStyle(styleOverrides, { textArea, loading }) {
  return {
    ...(textArea ? TEXT_AREA_DEFAULT_STYLES : {}),
    ...(loading ? { textAlign: "center" } : {}),
    ...(styleOverrides || {}),
  }
}

export default function EditableText({
  initialValue,
  onCommit: onCommit_,
  loading,
  style: styleOverrides,
  textArea = false,
  editCta = "Click to add",
}) {
  const style = computeStyle(styleOverrides || {}, { textArea, loading })

  const input = useRef()
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if (input.current && editing) {
      input.current.focus()
    }
  }, [editing, input])

  function onEdit() {
    setEditing(true)
  }

  function onCommit(value) {
    setEditing(false)
    if (value !== initialValue) {
      onCommit_(value)
    }
  }

  if (editing) {
    return (
      <EditText
        ref={input}
        initialValue={initialValue}
        onCommit={onCommit}
        textArea={textArea}
        style={style}
      />
    )
  }

  return (
    <OverlayContainer>
      <TextHint />
      <span
        className="form-control editable-inactive editable"
        style={style}
        onClick={onEdit}
        onFocus={onEdit}
        onKeyDown={(e) => e.key === "Enter" && onEdit()}
        tabIndex={0}
        role="textbox"
      >
        <Loading loading={loading} inline>
          <span style={{ visibility: loading ? "hidden" : "inherit" }}>
            {!initialValue ? editCta : initialValue}
          </span>
        </Loading>
      </span>
    </OverlayContainer>
  )
}

export const EditText = React.forwardRef(
  ({ initialValue, onCommit, textArea, style }, ref) => {
    const [value, setValue] = useState(initialValue)

    return (
      <OverlayContainer>
        <TextHint active />
        <BootstrapForm.Control
          className="text-primary editable"
          ref={ref}
          style={style}
          {...(textArea
            ? { as: "textarea", wrap: "hard" }
            : { type: "text", onKeyDown: (e) => e.key === "Enter" && onCommit(value) })}
          onChange={(e) => setValue(e.target.value)}
          onFocus={(e) => e.target.select()}
          onBlur={() => onCommit(value)}
          value={value}
        />
      </OverlayContainer>
    )
  }
)
