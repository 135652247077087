import _ from "lodash"

import Day from "day"

export function serializeRequestBody(body) {
  const serializedBody = {}

  Object.entries(body).forEach(([key, value]) => {
    if (value instanceof Day) {
      serializedBody[key] = value.toISOString()
    } else if (value instanceof Date) {
      serializedBody[key] = value.getTime()
    } else {
      serializedBody[key] = value
    }
  })

  return JSON.stringify(serializedBody)
}

export function serializeQueryParams(query) {
  if (!query) {
    return ""
  }

  const queryString = new URLSearchParams()

  Object.entries(query).forEach(([key, value]) => {
    if (_.isArray(value)) {
      value.forEach((item) => {
        queryString.append(key, item)
      })
    } else if (value instanceof Day) {
      queryString.append(key, value.toISOString())
    } else if (value instanceof Date) {
      queryString.append(key, value.getTime())
    } else {
      queryString.append(key, value)
    }
  })

  return `?${queryString.toString()}`
}
