import React, { useState } from "react"

import Form from "react-bootstrap/Form"

import Flex from "components/flex"

import CalculatorButton from "./button"

export default function Scale({ serving, adjust }) {
  const calories = Math.round(serving.nutrition.calories || 100)
  const [value, setValue] = useState(calories)

  const scalingFactor = value / calories

  return (
    <Flex justify="space-between">
      <Flex align="baseline" gap="0.5rem">
        <span style={{ whiteSpace: "nowrap" }}>
          <b>Scale</b> to
        </span>
        <Form.Control
          type="number"
          size="sm"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ width: "5rem" }}
        />
        calories
      </Flex>

      <CalculatorButton onClick={() => adjust("multiply", scalingFactor)}>
        Scale
      </CalculatorButton>
    </Flex>
  )
}
