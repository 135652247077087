import React from "react"

import Alert from "react-bootstrap/Alert"

import Delete from "./delete"

export default function CustomControls({ food }) {
  if (
    ![null, undefined].includes(food.recipe_idx) ||
    [null, undefined].includes(food.owner_idx)
  ) {
    return null
  }

  return (
    <Alert>
      <Alert.Heading bg="info">Coming Soon</Alert.Heading>
      <p>
        We&apos;re working on making custom foods editable. For now, you&apos;ll have to
        delete and recreate custom foods to change them, but in the very near future
        you&apos;ll be able to edit {food.name ? `"${food.name}"` : "this food"} right
        here on this page.
      </p>
      <Delete food={food} />
    </Alert>
  )
}
