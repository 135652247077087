import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import { Link, useLocation } from "react-router-dom"

import { DEVICE_TYPES } from "devices"

function NavLink({ label, to }) {
  const { pathname } = useLocation()
  return (
    <Nav.Link as={Link} to={to} disabled={pathname === to}>
      {label}
    </Nav.Link>
  )
}

export default function Authenticated({ auth, deviceType }) {
  return (
    <Navbar.Collapse className="justify-content-end" id="navbar-collapse">
      <Nav>
        <NavLink label="Diet" to="/diet" />
        <NavLink label="Recipes" to="/recipes" />
        <NavLink label="Foods" to="/foods" />
        <NavLink label="Body" to="/body" />
        <NavDropdown title={auth.user.email} id="basic-nav-dropdown">
          <NavDropdown.Item as={Link} to="/settings">
            <FontAwesomeIcon icon="cog" /> Settings
          </NavDropdown.Item>
          <a
            href="mailto:john@gutts.io?subject=Feedback"
            className="dropdown-item"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b className="text-primary">Send Feedback</b>
          </a>
          <NavDropdown.Item as={Link} to="/explore">
            Analysis
          </NavDropdown.Item>
          {auth.user.stripe_customer_id !== null ? (
            <NavDropdown.Item as={Link} to="/billing">
              Billing
            </NavDropdown.Item>
          ) : (
            <NavDropdown.Item as={Link} to="/subscribe">
              Subscribe
            </NavDropdown.Item>
          )}
          {deviceType === DEVICE_TYPES.DESKTOP && (
            <NavDropdown.Item as={Link} to="/mobile_app">
              <FontAwesomeIcon icon="mobile" /> Mobile App
            </NavDropdown.Item>
          )}
          {auth.user.is_admin && (
            <NavDropdown.Item as={Link} to="/admin">
              <b className="text-danger">Admin</b>
            </NavDropdown.Item>
          )}
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={auth.logout}>
            <FontAwesomeIcon icon="sign-out-alt" /> Logout
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </Navbar.Collapse>
  )
}
