import React from "react"

import _ from "lodash"
import Card from "react-bootstrap/Card"
import { useLocation } from "react-router-dom"

import Flex from "components/flex"
import Graph from "components/graph"
import PageTitle from "components/page_title"
import Paywall from "components/paywall"
import QueryControl, { useDateRange } from "components/query_control"
import { METRICS } from "constant"
import useAuth from "hooks/auth"

const METRIC_DEFAULT = [METRICS.calories.key]

export default function ExplorePage() {
  const auth = useAuth()

  const { search: search_ } = useLocation()
  const dateRange = useDateRange("dateRange")
  const search = new URLSearchParams(search_)
  const metrics = search.get("metric") ? search.getAll("metric") : METRIC_DEFAULT

  const paywalled = !auth.user.is_subscribed

  return (
    <React.Fragment>
      <PageTitle icon="trophy">Explore outcomes</PageTitle>
      {paywalled ? (
        <Paywall subscribeTo="see your metrics" />
      ) : (
        <React.Fragment>
          <Card style={{ marginBottom: "1rem" }}>
            <Card.Body>
              <Flex justify="space-between" align="baseline" gap="2rem">
                <QueryControl.Chips
                  id="metric-chips"
                  disabled={paywalled}
                  field="metric"
                  default_={METRIC_DEFAULT}
                  colors={_.fromPairs(
                    Object.keys(METRICS).map((key) => [key, METRICS[key].color])
                  )}
                  options={Object.entries(METRICS).map(([key, blob]) => ({
                    value: key,
                    label: blob.label,
                  }))}
                />
                <QueryControl.DateRange
                  id="analytics-page-daterange"
                  field="dateRange"
                />
              </Flex>
            </Card.Body>
          </Card>
          <Graph dateRange={dateRange} metrics={metrics} />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
