import React from "react"

import { Formik } from "formik"
import Col from "react-bootstrap/Col"
import BootstrapForm from "react-bootstrap/Form"
import * as yup from "yup"

import Button from "components/button"

import Control from "./control"

function Form({ children, validationSchema, initialValues, onSubmit }) {
  return (
    <Formik
      validationSchema={yup.object(validationSchema)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {children}
    </Formik>
  )
}

Form.Body = ({ children, formik, debug, ...rest }) => (
  <BootstrapForm noValidate onSubmit={formik.handleSubmit} {...rest}>
    {children}
    {debug && (
      <pre
        style={{
          background: "#f6f8fa",
          fontSize: ".65rem",
          padding: ".5rem",
        }}
      >
        <strong>props</strong> = {JSON.stringify(formik, null, 2)}
      </pre>
    )}
  </BootstrapForm>
)

Form.Button = ({ formik, ...rest }) => (
  <BootstrapForm.Group as={Col}>
    <Button
      type="submit"
      loading={formik.isSubmitting}
      disabled={!formik.dirty || !formik.isValid}
      {...rest}
    />
  </BootstrapForm.Group>
)

Form.Text = ({ id, name, label, formik, ...rest }) => (
  <Control id={id} name={name} label={label} formik={formik} {...rest} />
)

Form.TextArea = ({ id, name, label, formik, ...rest }) => (
  <Control id={id} name={name} label={label} formik={formik} as="textarea" {...rest} />
)

Form.Dropdown = ({ id, name, label, options, formik, ...rest }) => (
  <Control
    as="select"
    id={id}
    name={name}
    label={label}
    formik={formik}
    onBlur={() => formik.setFieldTouched(name, true)}
    {...rest}
  >
    {options.map((opt) => (
      <option key={opt.value} value={opt.value}>
        {opt.label}
      </option>
    ))}
  </Control>
)

Form.Email = ({ id, name, label, formik, ...rest }) => (
  <Control
    id={id}
    name={name}
    label={label}
    formik={formik}
    type="email"
    autoCorrect="off"
    autoCapitalize="none"
    autoComplete="off"
    {...rest}
  />
)

Form.Password = ({ id, name, label, formik, ...rest }) => (
  <Control
    type="password"
    id={id}
    name={name}
    label={label}
    formik={formik}
    {...rest}
  />
)

Form.Number = ({ id, name, label, formik, ...rest }) => (
  <Control id={id} name={name} label={label} formik={formik} type="number" {...rest} />
)

export default Form
