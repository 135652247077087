import React from "react"

import _ from "lodash"
import BootstrapPagination from "react-bootstrap/Pagination"

import Flex from "components/flex"

export default function Paginator({ cursor, setPage }) {
  if (!cursor || Object.keys(cursor).length === 0) {
    return null
  }

  return (
    <Flex justify="center" margin="1rem 0 0 0">
      <BootstrapPagination>
        {_.range(1, cursor.last + 1 || 1).map((n) => (
          <BootstrapPagination.Item
            key={n}
            active={n === cursor.page}
            onClick={n === cursor.page ? () => null : () => setPage(n)}
          >
            {n}
          </BootstrapPagination.Item>
        ))}
      </BootstrapPagination>
    </Flex>
  )
}
