import React from "react"

import Card from "react-bootstrap/Card"

import CreateUserForm from "./create_user_form"
import ElasticSync from "./elastic_sync"

export default function AdminPage() {
  return (
    <React.Fragment>
      <h1>Admin</h1>
      <Card style={{ marginBottom: "1rem" }}>
        <Card.Body>
          <Card.Title>Invite user</Card.Title>
          <CreateUserForm />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Elastic search sync</Card.Title>
          <ElasticSync />
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}
