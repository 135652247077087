import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ProgressBar from "react-bootstrap/ProgressBar"

import Animate from "components/animate"
import { dangerColor } from "index.scss"

const CHECKER_SIZE = "5px"
const CHECKERED_BACKGROUND = {
  backgroundImage:
    "linear-gradient(45deg, black 25%, transparent 25%)," +
    "linear-gradient(135deg, black 25%, transparent 25%)," +
    "linear-gradient(45deg, transparent 75%, black 75%)," +
    "linear-gradient(135deg, transparent 75%, black 75%)",
  backgroundSize: `calc(2 * ${CHECKER_SIZE}) calc(2 * ${CHECKER_SIZE})`,
  backgroundPosition:
    "0 0, " +
    `${CHECKER_SIZE} 0, ` +
    `${CHECKER_SIZE} calc(-1 * ${CHECKER_SIZE}), ` +
    `0px ${CHECKER_SIZE} `,
  opacity: 0.1,
}

export default function Meter({
  value = 0,
  minTarget,
  maxTarget,
  maxValue = 100,
  text,
  variant,
  border,
  actionable,
  failed,
}) {
  const targetsValid =
    ![null, undefined].includes(minTarget) && ![null, undefined].includes(maxTarget)

  const showLabelOutsideMeter = value / maxValue <= 0.08

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "relative" }}>
        <ProgressBar
          style={{
            border: border === "danger" && `1px solid ${dangerColor}`,
            opacity: actionable ? 1 : 0.25,
          }}
        >
          <ProgressBar
            label={showLabelOutsideMeter ? undefined : text}
            now={100 * (value / maxValue)}
            variant={variant}
          />
        </ProgressBar>

        {showLabelOutsideMeter && (
          <div
            className="text-muted"
            style={{
              position: "absolute",
              paddingLeft: "0.25rem",
              width: "100%",
              top: "-2px",
              fontSize: "0.75rem",
            }}
          >
            <div
              style={{
                position: "relative",
                transform: `translateX(${(100 * value) / maxValue}%)`,
                transition: "transform 0.6s",
              }}
            >
              {text}
            </div>
          </div>
        )}

        {targetsValid && (
          <div
            style={{
              ...CHECKERED_BACKGROUND,
              position: "absolute",
              width: `${(100 * (maxTarget - minTarget)) / maxValue}%`,
              left: `${(100 * minTarget) / maxValue}%`,
              top: 0,
              height: "100%",
            }}
          />
        )}
      </div>

      {failed && (
        <div
          style={{
            position: "absolute",
            left: `calc(${100 * Math.min(value / maxValue, 1)}% - 1.5em)`,
            top: "-1.5em",
            color: dangerColor,
            zIndex: 1,
            textAlign: "center",
          }}
        >
          <Animate splat>
            <FontAwesomeIcon icon="times" size="4x" />
          </Animate>
        </div>
      )}
    </div>
  )
}
