import React from "react"

import Button from "components/button"
import useBreakpoint from "hooks/breakpoint"

export default React.forwardRef((props, ref) => {
  const smallScreen = !useBreakpoint("md")

  const { icon } = props

  return (
    <Button
      ref={ref}
      size="sm"
      variant="outline-secondary"
      iconSide="left"
      {...{ ...props, ...(smallScreen && icon ? { children: null } : {}) }}
    />
  )
})
