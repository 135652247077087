import React, { useState } from "react"

import Modal from "react-bootstrap/Modal"
import Row from "react-bootstrap/Row"
import { useHistory } from "react-router-dom"
import * as yup from "yup"

import { api } from "api"
import Button from "components/button"
import Form from "components/form"
import { notify } from "notifications"

export default function DeleteAccount({ auth }) {
  const [show, setShow] = useState(false)

  const history = useHistory()
  async function deleteAccount() {
    try {
      await api.delete(`/api/v1/users/${auth.user.idx}`)
      auth.logout()
      history.push("/?account_deleted=1")
    } catch (error) {
      notify.error(error)
    } finally {
      await auth.refreshUser()
    }
  }

  return (
    <React.Fragment>
      <Button variant="danger" onClick={() => setShow(true)}>
        Delete account
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Form
          initialValues={{ email: "" }}
          validationSchema={{
            email: yup
              .string()
              .test(
                "check-email-match",
                "Confirm your email to delete your account",
                (value) => value === auth.user.email
              ),
          }}
          onSubmit={deleteAccount}
        >
          {(formik) => (
            <Form.Body formik={formik}>
              <Modal.Header closeButton>
                <Modal.Title>Delete account?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sure you want to delete your account?</p>
                <Row>
                  <Form.Text
                    id="confirm-account-deletion-email"
                    name="email"
                    label="Type your email below to confirm"
                    formik={formik}
                  />
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  Cancel
                </Button>
                <Form.Button variant="danger" formik={formik}>
                  Delete my account
                </Form.Button>
              </Modal.Footer>
            </Form.Body>
          )}
        </Form>
      </Modal>
    </React.Fragment>
  )
}
