import React, { useState } from "react"

import Modal from "react-bootstrap/Modal"

import { api } from "api"
import Button from "components/button"
import Editable from "components/editable"
import Flex from "components/flex"
import BaseNutritionFacts from "components/nutrition_facts"
import NutritionHint from "components/nutrition_hint"
import { notify } from "notifications"

function NutritionFacts({ recipe, food, loadingFood, refreshData }) {
  const [patching, setPatching] = useState(false)

  async function patchServings(value) {
    setPatching(true)
    try {
      await api.patch(`/api/v1/recipes/${recipe.idx}`, { servings: value })
    } catch (error) {
      notify.error(error)
    } finally {
      setPatching(false)
      await refreshData()
    }
  }

  return (
    <BaseNutritionFacts nutrition={food.nutrition || {}} loading={loadingFood}>
      <Flex
        align="baseline"
        justify="space-between"
        gap="2rem"
        style={{ padding: "0.5rem 0" }}
      >
        Per 1 serving
        <Editable.Number
          id="recipe-servings"
          initialValue={recipe.servings}
          loading={patching}
          onCommit={patchServings}
          units={recipe.servings === 1 ? "serving total" : "servings total"}
          style={{ minWidth: "11rem" }}
        />
      </Flex>
    </BaseNutritionFacts>
  )
}

export default function Nutrition({
  compact,
  recipe,
  food,
  loadingFood,
  ingredients,
  refreshData,
}) {
  const [showModal, setShowModal] = useState(false)

  if (!ingredients.length) {
    return null
  }

  const nutritionFactsComponent = (
    <NutritionFacts
      recipe={recipe}
      food={food}
      loadingFood={loadingFood}
      refreshData={refreshData}
    />
  )

  if (!compact) {
    return nutritionFactsComponent
  }

  return (
    <React.Fragment>
      <Button
        variant="light"
        className="text-muted"
        size="sm"
        block
        onClick={() => setShowModal(!showModal)}
      >
        <Flex justify="space-between">
          <div>
            <NutritionHint nutrition={food.nutrition || {}} />
          </div>
          <div>(per serving)</div>
        </Flex>
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          &quot;{recipe.name}&quot; Nutrition Facts
        </Modal.Header>
        <Modal.Body>{nutritionFactsComponent}</Modal.Body>
      </Modal>
    </React.Fragment>
  )
}
