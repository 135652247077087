import React from "react"

import _ from "lodash"
import Dropdown from "react-bootstrap/Dropdown"
import { Redirect, Link, useLocation } from "react-router-dom"

import { buildQueryString } from "api"
import Button from "components/button"
import Flex from "components/flex"

import searchToDict from "./utils"

export default function Chips({ id, field, options: rawOptions, default_, colors }) {
  const { search, pathname, hash } = useLocation()

  function getUrlOnPop(poppedValue) {
    const output = searchToDict(search)
    output[field] = output[field].filter((val) => val !== poppedValue)
    return { search: buildQueryString(output), hash }
  }

  function getUrlOnAppend(value) {
    const output = searchToDict(search)
    output[field] = output[field].concat([value])
    return { search: buildQueryString(output), hash }
  }

  if (!new URLSearchParams(search).get(field) && default_ !== undefined) {
    const newSearchParams = new URLSearchParams(search)
    newSearchParams.set(field, default_)
    return <Redirect to={{ pathname, search: newSearchParams.toString() }} />
  }

  const searchParams = new URLSearchParams(search)
  const options = _.groupBy(rawOptions, ({ value }) =>
    !searchParams.getAll(field).includes(value) ? "unselected" : "selected"
  )

  return (
    <Flex wrap="wrap" gap="0.5rem">
      {options.selected.map(({ value, label }) => (
        <Button
          key={value}
          as={Link}
          style={{
            background: (colors || {})[value],
            borderColor: (colors || {})[value],
            marginRight: "0.5rem",
          }}
          size="sm"
          className="badge-pill"
          icon="times"
          to={getUrlOnPop(value)}
        >
          {label}
        </Button>
      ))}
      <AddChip id={id} getUrlOnAppend={getUrlOnAppend} options={options.unselected}>
        Add...
      </AddChip>
    </Flex>
  )
}

const AddToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
    variant="outline-primary"
    icon="plus"
    size="sm"
    className="badge-pill"
  >
    {children}
  </Button>
))

function AddChip({ children, id, getUrlOnAppend, options }) {
  return (
    <Dropdown>
      <Dropdown.Toggle as={AddToggle} id={id}>
        {children}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item key={option.value} as={Link} to={getUrlOnAppend(option.value)}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
