import React from "react"

import { Link } from "react-router-dom"

import Flex from "components/flex"

export default function Footer() {
  return (
    <footer style={{ margin: "2rem 0 1rem" }}>
      <Flex justify="center">
        <Link to="/legal/disclaimers">Disclaimers</Link>
        <span className="text-muted" style={{ margin: "0 1rem" }}>
          |
        </span>
        <Link to="/legal/tos">Terms of Service</Link>
        <span className="text-muted" style={{ margin: "0 1rem" }}>
          |
        </span>
        <Link to="/legal/privacy">Privacy</Link>
      </Flex>
    </footer>
  )
}
