import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Card from "react-bootstrap/Card"

function Section({ children }) {
  return (
    <Card style={{ marginBottom: "1rem" }}>
      <Card.Body>{children}</Card.Body>
    </Card>
  )
}

Section.Title = ({ icon, children }) => {
  return (
    <Card.Title>
      {icon ? (
        <React.Fragment>
          <FontAwesomeIcon icon={icon} style={{ marginRight: "0.75rem" }} />
          {children}
        </React.Fragment>
      ) : (
        children
      )}
    </Card.Title>
  )
}

Section.Elaboration = ({ children }) => {
  return (
    <div className="text-muted" style={{ marginBottom: "1rem" }}>
      {children}
    </div>
  )
}

export default Section
