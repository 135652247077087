import React, { useEffect, useState } from "react"

import Container from "react-bootstrap/Container"
import BootstrapNavbar from "react-bootstrap/Navbar"
import { Link, useLocation } from "react-router-dom"

import Animate from "components/animate"
import Button from "components/button"
import { getDeviceType, DEVICE_TYPES } from "devices"
import useAuth from "hooks/auth"

import Authenticated from "./authenticated"
import Unauthenticated from "./unauthenticated"

export default function Navbar() {
  const [expanded, setExpanded] = useState(false)
  const [installPromptEvent, setInstallPromptEvent] = useState(undefined)
  const { pathname } = useLocation()
  const auth = useAuth()

  useEffect(() => {
    setExpanded(false)
  }, [pathname])

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault()
      setInstallPromptEvent(e)
    })
  }, [])

  const deviceType = getDeviceType()
  return (
    <BootstrapNavbar
      sticky="top"
      variant="dark"
      bg="primary"
      expand="md"
      expanded={expanded}
    >
      <Container>
        <BootstrapNavbar.Brand as={Link} to="/">
          <b>GUTTS</b>
        </BootstrapNavbar.Brand>
        {deviceType === DEVICE_TYPES.MOBILE && installPromptEvent && (
          <Animate wiggle delay={2000}>
            <Button
              icon="download"
              iconSide="left"
              onClick={async () => {
                installPromptEvent.prompt()
                await installPromptEvent.userChoice
              }}
            >
              Get the app!
            </Button>
          </Animate>
        )}
        {auth.loggedIn === true && (
          <React.Fragment>
            <BootstrapNavbar.Toggle
              aria-controls="navbar-collapse"
              onClick={() => setExpanded(!expanded)}
              style={{ border: "none" }}
            />
            <Authenticated auth={auth} deviceType={deviceType} />
          </React.Fragment>
        )}
        {auth.loggedIn === false && <Unauthenticated />}
      </Container>
    </BootstrapNavbar>
  )
}
