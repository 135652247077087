import React, { useState } from "react"

import { useHistory, useParams, useLocation, Redirect } from "react-router-dom"

import { api, usePage } from "api"
import Dropdown from "components/dropdown"
import Editable from "components/editable"
import Flex from "components/flex"
import NutritionFacts from "components/nutrition_facts"
import Day from "day"
import format from "format"
import { notify } from "notifications"
import ServingButton from "shared/serving_button"

export default function Nutrition({ food }) {
  const { id } = useParams()
  const measures = usePage(`/api/v1/foods/${id}/measures`)

  const currentLocation = useLocation()
  const history = useHistory()

  const [posting, setPosting] = useState(false)

  const measureSelected = ![null, undefined].includes(food.measure_idx)

  async function eatFood() {
    const today = Day.today()
    let postedServing = false
    try {
      setPosting(true)
      if (measureSelected) {
        await api.post("/api/v1/servings", {
          food_idx: id,
          amount: food.magnitude,
          measure_idx: food.measure_idx,
          eaten_on: today,
        })
      } else {
        await api.post("/api/v1/servings", {
          food_idx: id,
          amount: food.amount,
          eaten_on: today,
        })
      }
      postedServing = true
    } catch (error) {
      notify.error(error)
    } finally {
      setPosting(false)
    }

    if (postedServing) {
      notify.success(`Recorded ${food.amount} of ${food.name} eaten today`)
      history.push("/")
    }
  }

  function setQueryParams({ amount, measureIdx }) {
    const newSearchParams = new URLSearchParams(currentLocation.search)
    if (![null, undefined].includes(amount)) {
      newSearchParams.set("amount", amount)
    }
    if (![null, undefined].includes(measureIdx)) {
      newSearchParams.set("measure_idx", measureIdx)
    }

    history.push({
      ...currentLocation,
      search: newSearchParams.toString(),
    })
  }

  const unitOptions = (food.available_units || [])
    .map((value) => ({
      value,
      label: format.foodAmount(value),
      onSelect: () => setQueryParams({ amount: `${food.magnitude} ${value}` }),
    }))
    .concat(
      measures.page.map((m) => ({
        value: m.idx,
        label: `${m.name} (${format.foodAmount(
          food.measure_idx === m.idx ? food.amount : m.amount
        )})`,
        onSelect: () => setQueryParams({ amount: 1, measureIdx: m.idx }),
      }))
    )

  if ([null, undefined].includes(food.idx)) {
    return <div style={{ minHeight: "10rem" }} />
  }

  if (!currentLocation.search) {
    const defaultSearchParams = new URLSearchParams()
    defaultSearchParams.set("amount", food.amount)

    if (measureSelected && ![null, undefined].includes(food.measure_idx)) {
      defaultSearchParams.set("measureIdx", food.measure_idx)
    }

    return (
      <Redirect to={{ ...currentLocation, search: defaultSearchParams.toString() }} />
    )
  }

  return (
    <NutritionFacts nutrition={food.nutrition || {}} amount={food.amount}>
      {unitOptions.length && (
        <Flex justify="space-between" style={{ margin: "0.5rem 0" }}>
          <Flex gap="0.5rem" align="baseline">
            Amount per
            <Editable.Number
              style={{ width: "4rem" }}
              initialValue={food.magnitude}
              onCommit={(magnitude) =>
                setQueryParams(
                  measureSelected
                    ? { amount: magnitude, measure_idx: food.measure_idx }
                    : { amount: `${magnitude} ${food.units}` }
                )
              }
            />
            <Dropdown
              id="unitsDropdown"
              value={measureSelected ? food.measure_idx : food.units}
              options={unitOptions}
            />
          </Flex>
          <ServingButton
            onClick={eatFood}
            food={food}
            measures={measures.page}
            loading={posting}
          />
        </Flex>
      )}
    </NutritionFacts>
  )
}
