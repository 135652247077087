import React from "react"

import Animate from "components/animate"
import Empty from "components/empty"
import Loading from "components/loading"
import Day from "day"

import { useLastEvent, EVENT_TYPES } from "../event_context"
import ServingCard from "./serving_card"

export default function Servings({ loading, servings, dayOffset, refreshDietData }) {
  const lastEvent = useLastEvent()

  return (
    <Loading loading={loading} debounce>
      <Animate
        duration={100}
        key={dayOffset}
        enterLeft={!loading && lastEvent.type === EVENT_TYPES.DATE_CHANGE_BACK}
        enterRight={!loading && lastEvent.type === EVENT_TYPES.DATE_CHANGE_FORWARD}
      >
        <Empty show={!loading && servings.length === 0}>
          Nothing logged for {Day.fromOffset(dayOffset).pretty()}. Log what you eat
          above.
        </Empty>
      </Animate>
      {servings.map((serving) => (
        <Animate
          duration={100}
          enterLeft={!loading && lastEvent.type === EVENT_TYPES.DATE_CHANGE_BACK}
          enterRight={!loading && lastEvent.type === EVENT_TYPES.DATE_CHANGE_FORWARD}
          enterTop={
            lastEvent.type === EVENT_TYPES.SERVING_POSTED &&
            lastEvent.servingIdx === serving.idx
          }
          key={serving.idx}
        >
          <ServingCard serving={serving} refreshDietData={refreshDietData} />
        </Animate>
      ))}
    </Loading>
  )
}
