import { useMediaQuery } from "react-responsive"

import {
  xsBreakpoint,
  smBreakpoint,
  mdBreakpoint,
  lgBreakpoint,
  xlBreakpoint,
} from "index.scss"

export default function useBreakpoint(breakpoint) {
  let minWidth
  if (breakpoint === "xs") {
    minWidth = xsBreakpoint
  } else if (breakpoint === "sm") {
    minWidth = smBreakpoint
  } else if (breakpoint === "md") {
    minWidth = mdBreakpoint
  } else if (breakpoint === "lg") {
    minWidth = lgBreakpoint
  } else if (breakpoint === "xl") {
    minWidth = xlBreakpoint
  } else {
    throw new Error(`Got bad breakpoint ${breakpoint}`)
  }

  return useMediaQuery({ query: `(min-width: ${minWidth})` })
}
