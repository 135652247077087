import React from "react"

import { IconTooltip } from "components/tooltip"

export default function Error({ error }) {
  let tooltipMessage = "Search is online"
  let variant = "success"

  if (error) {
    variant = "danger"
    tooltipMessage = error.message || error.type
  }

  return (
    <IconTooltip
      id="error-status"
      placement="top"
      icon="server"
      variant={variant}
      size="lg"
    >
      {tooltipMessage}
    </IconTooltip>
  )
}
