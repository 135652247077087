import React, { useState } from "react"

import { api } from "api"
import Expand from "components/expand"
import Flex from "components/flex"
import useBreakpoint from "hooks/breakpoint"
import { notify } from "notifications"
import FoodLink from "shared/food_link"
import ServingButton from "shared/serving_button"

export default function Result({ food, recipeIdx, refreshData, closeMenu }) {
  const maxOptionsToDisplay = 1 + !!useBreakpoint("lg") + !!useBreakpoint("xl")

  const mainOption = food.post_options[0]
  const topLevelOptions = food.post_options.slice(1, maxOptionsToDisplay)
  const expandOptions = food.post_options.slice(maxOptionsToDisplay)

  return (
    <Expand
      id={`ingredient-search-result-expand-${food.idx}`}
      hasExtraContent={!!expandOptions.length}
    >
      <Expand.Header>
        <Flex align="baseline" justify="space-between" gap="0.5rem">
          <FoodLink food={food} />
          <Flex align="baseline" gap="0.5rem">
            {topLevelOptions.map((postOption) => (
              <PostOptionButton
                variant="outline-secondary"
                key={JSON.stringify(postOption)}
                food={food}
                postOption={postOption}
                recipeIdx={recipeIdx}
                refreshData={refreshData}
                closeMenu={closeMenu}
              />
            ))}
            <PostOptionButton
              food={food}
              postOption={mainOption}
              recipeIdx={recipeIdx}
              refreshData={refreshData}
              closeMenu={closeMenu}
            />
          </Flex>
        </Flex>
      </Expand.Header>

      <Expand.Body>
        <Flex gap="0.5rem" wrap justify="end">
          {expandOptions.map((postOption) => (
            <PostOptionButton
              variant="outline-secondary"
              key={JSON.stringify(postOption)}
              food={food}
              postOption={postOption}
              recipeIdx={recipeIdx}
              refreshData={refreshData}
              closeMenu={closeMenu}
            />
          ))}
        </Flex>
      </Expand.Body>
    </Expand>
  )
}

const PostOptionButton = React.forwardRef(
  ({ food, postOption, recipeIdx, refreshData, closeMenu, ...rest }, ref) => {
    const [posting, setPosting] = useState(false)

    async function addIngredient() {
      try {
        setPosting(true)
        await api.post(`/api/v1/ingredients`, {
          amount: postOption.amount,
          ...(postOption.measure_idx !== undefined
            ? { measure_idx: postOption.measure_idx }
            : {}),
          food_idx: food.idx,
          recipe_idx: recipeIdx,
        })

        closeMenu()
      } catch (error) {
        notify.error(error)
      } finally {
        setPosting(false)
        await refreshData()
      }
    }

    return (
      <ServingButton
        ref={ref}
        {...rest}
        searchResult={food}
        postOption={postOption}
        onClick={addIngredient}
        loading={posting}
      />
    )
  }
)
